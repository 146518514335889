import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import Tabs from '@appComponents/Tabs';
import {getClientTabsState} from '@modules/client/utils/clientAnalyticsSetupTabsLayoutHelper';

class ClientAnalyticsSetupTabsLayout extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.setState(() => getClientTabsState(this.props));
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState(() => getClientTabsState(this.props));
        }
    }

    render() {
        return (
            <div className='clientAnalyticsSetupTabs'>
                <Tabs active={this.state?.active} items={this.state?.tabs} level={2}/>
            </div>
        );
    }
}

export default withRouter(ClientAnalyticsSetupTabsLayout);
