import moment from 'moment';
import React from 'react';
import {filter as _filter, isUndefined as _isUndefined, map as _map, isNil as _isNil} from 'lodash';
import {Icon, Label, List, Popup} from 'semantic-ui-react';

import {convertToInt} from '@utils/helpers';
import {IconAdd, IconCheckmark, IconEdit, IconLinkify} from '@appComponents/IconCollection';
import Authorization from '@appComponents/Authorization';
import Link, {getLink} from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {renderEventStatusName} from '@utils/status';
import {EventContentVariantPopup} from '@utils/eventContentVariant/EventContentVariantPopup';

import {CompetitorsBlacklistedStatus} from '../views/common/EventScheduleTableColumns';

const eventAddAuthorizationObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
};

const eventReadAuthorizationObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
};

const rowContainsColumn = (column, row) => {
    return !_isUndefined(row[column]) && null !== row[column];
};

const renderEventIdColumn = (column, row) => {
    return (
        <div>
            {(_isUndefined(row[column]) || null === row[column])
                ? ''
                : convertToInt(row[column])
            }
        </div>
    );
};

const renderMatchIdColumn = (column, row) => {
    const matchIdColumnContent = (row.is_sr_event)
        ? <IconLinkify color='red'/>
        : '';

    return (
        <div>
            {(_isUndefined(row[column]) || null === row[column])
                ? matchIdColumnContent
                : convertToInt(row[column])
            }
        </div>
    );
};

const renderStartDatetimeColumn = (column, row) => {
    return (
        <div>
            <div>
                {(_isUndefined(row[column]) || null === row[column])
                    ? ''
                    : moment(row[column]).format('YYYY-MM-DD HH:mm')
                }
            </div>
            <div>
                {(_isUndefined(row.end_datetime) || null === row.end_datetime)
                    ? ''
                    : moment(row.end_datetime).format('YYYY-MM-DD HH:mm')
                }
            </div>
        </div>
    );
};

export const renderCompetitorsColumn = (column, row) => {
    const competitorsWarningsExist = row.competitors_warnings && 0 !== row.competitors_warnings.length,
        tvStreamingInfoExist = row.is_tv_streaming && null !== row.tv_streaming_encoder_id,
        marginClass = (row.description && (competitorsWarningsExist || tvStreamingInfoExist))
            ? 'marginBetweenDescriptionAndWarnings'
            : '';

    return (
        <div>
            <div>
                {(_isUndefined(row[column]) || null === row[column])
                    ? ''
                    : row[column]
                }
            </div>
            <div>
                {(_isUndefined(row.description) || null === row.description)
                    ? ''
                    : <Label style={{backgroundColor: `#${row.color}`}} size='mini'>{row.description}</Label>
                }
            </div>
            <div className={marginClass}>
                {tvStreamingInfoExist && <Label color='grey' size='mini'>TV Streaming</Label>}
                {competitorsWarningsExist && _map(row.competitors_warnings, (warning, index) => (
                    <Label key={index} color='red' size='mini'>{warning}</Label>
                ))}
                <CompetitorsBlacklistedStatus
                    ac_blacklisted={row.is_ac_blacklisted}
                    hc_blacklisted={row.is_hc_blacklisted}/>
            </div>
        </div>
    );
};

export const renderStatusColumn = (column, row) => {
    const renderedStatus = rowContainsColumn(column, row)
        ? renderEventStatusName(row.status_id, row.status)
        : '';

    return <div>{renderedStatus}</div>;
};

const renderEventContentWarning = (content) => (
    <Popup
        trigger={<Icon className='warningSign' name='warning sign' color='red'/>}
        content={content.eventContentWarning.content}
        size='small'
        hoverable
        key={`content.popup.${content.event_content_id}`}
    />
);

const renderContentColumns = (column, row) => {
    const renderedIds = new Set();

    if (!row[column] || 0 === row[column].length) {
        return '';
    }

    return (
        <List>
            {_map(row[column], (item, index) => {
                if (!renderedIds.has(item.event_content_id)) {
                    renderedIds.add(item.event_content_id);
                    const itemCount = _filter(row[column], {event_content_id: item.event_content_id}).length;

                    return (
                        <List.Item key={`${column}.list.item.${item.event_content_id}_${index}`}
                            className='eventContentDetails' style={{'--item-count': itemCount}}>
                            <List.Content>
                                <p>
                                    {item.name || '\u00A0'}
                                    {item.eventContentWarning && renderEventContentWarning(item)}
                                    {item.eventContentVariant &&
                                        <EventContentVariantPopup eventContentVariant={item.eventContentVariant}/>}
                                </p>
                            </List.Content>
                        </List.Item>
                    );
                } else {
                    return null;
                }
            })}
        </List>
    );
};

const renderProductsAndDistribution = (column, row, fieldName = 'name') => {
    const lastElementIndices = new Map();
    row[column].forEach((item, index) => {
        lastElementIndices.set(item.event_content_id, index);
    });

    return (
        (!row[column] || 0 === row[column].length) ? '' :
            <List>
                {_map(row[column], (item, index) => {
                    const product = row.products ? row.products[index] : null,
                        isLastInGroup = lastElementIndices.get(item.event_content_id) === index;

                    return (
                        <List.Item key={`${column}.list.item.${item.event_content_id}_${index}`}
                            className={isLastInGroup ? 'lastInGroup' : ''}>
                            <List.Content key={`${column}.list.content.${item.event_content_id}`}>
                                <p key={`${column}.p.${item.product_id}`}>
                                    {item[fieldName] || (product ? product.short_name : '\u00A0')}
                                </p>
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

const renderColumnWithList = (column, row, defaultValue = 0, fieldName = false) => {
    let field = column;

    if (fieldName) {
        field = fieldName;
    }

    const lastElementIndices = new Map();
    row[column].forEach((item, index) => {
        lastElementIndices.set(item.event_content_id, index);
    });

    return (
        (!row[column] || 0 === row[column].length) ? '' :
            <List>
                {_map(row[column], (item, index) => {
                    const isLastInGroup = lastElementIndices.get(item.event_content_id) === index;
                    const columnValues = _filter(row[column], {
                        event_content_id: row.products[index].event_content_id,
                        product_id: row.products[index].product_id,
                    });

                    return (
                        <List.Item
                            key={`${field}.list.item.${item.event_content_id}_${index}`}
                            className={isLastInGroup ? 'lastInGroup' : ''}
                        >
                            <List.Content key={`${field}.list.content.${item.event_content_id}`}>
                                {0 === columnValues.length ? <p key={`product.p.${item.product_id}`}>0</p> :
                                    <p key={`product.p.${item.product_id}`}>
                                        {columnValues[0][field] || defaultValue}
                                    </p>
                                }
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
    );
};

const renderCategoryOrTournament = (column, row) => {
    return Array.isArray(row[column]) && 0 < row[column].length
        ? <List>
            {_map(row[column], (data, index) => {
                return (
                    <List.Item key={`tournament.list.item.${index}`}>
                        <List.Content key={`tournament.list.content.${index}`}>
                            <p key={`tournament.p.${index}`}>
                                {data}
                            </p>
                        </List.Content>
                    </List.Item>
                );
            })}
        </List>
        : row[column] || null;
};

const renderVenueOrCourtAndTvChannelColumn = (column, row) => {
    return (
        <div>
            <div>
                {_isNil(row[column])
                    ? ''
                    : row[column]
                }
            </div>
            <div>
                {_isNil(row.tv_channel)
                    ? ''
                    : row.tv_channel
                }
            </div>
        </div>
    );
};

export const renderCoverageValue = (column, row) => {
    return (_isUndefined(row[column]) || null === row[column]) ? '' : row[column];
};

const renderAction = (row) => {
    if (null !== row.event_id) {
        return (
            <Authorization authorization={eventReadAuthorizationObject}>
                <IconEdit
                    size='large'
                    link={getLink('events.configuration.index', {id: row.event_id})}
                    resource={RESOURCES.EVENT}
                    editIconName='setting'
                />
            </Authorization>
        );
    }

    return (
        <Authorization authorization={eventAddAuthorizationObject}>
            <Link name='events.add.sportradarExisting' params={{matchId: row.match_id}}>
                <IconAdd size='large'/>
            </Link>
        </Authorization>
    );
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'event_id':
            return renderEventIdColumn(column, row);
        case 'match_id':
            return renderMatchIdColumn(column, row);
        case 'start_datetime':
            return renderStartDatetimeColumn(column, row);
        case 'category_or_content_category_level_2':
        case 'tournament_or_content_category_level_3':
            return renderCategoryOrTournament(column, row);
        case 'sport_or_content_category_level_1':
            return (
                <div>
                    {_isNil(row[column])
                        ? ''
                        : row[column]
                    }
                </div>
            );
        case 'venue_or_court':
            return renderVenueOrCourtAndTvChannelColumn(column, row);
        case 'notes':
            return (
                <div>
                    {(_isUndefined(row[column]) || null === row[column])
                        ? ''
                        : row[column]
                    }
                </div>
            );
        case 'competitors':
            return renderCompetitorsColumn(column, row);
        case 'status':
            return renderStatusColumn(column, row);
        case 'coverage':
            return (
                row.clip_provider
                    ? (<Popup
                        trigger={<div>{renderCoverageValue(column, row)}</div>}
                        flowing
                        content={'Clip provider: ' + row.clip_provider}
                        hoverable
                    />)
                    : renderCoverageValue(column, row)
            );
        case 'contents':
            return renderContentColumns(column, row);
        case 'contribution_types':
            return renderContentColumns(column, row);
        case 'encoding_datacenters':
            return renderContentColumns(column, row);
        case 'products':
            return renderProductsAndDistribution(column, row);
        case 'distribution_types':
            return renderProductsAndDistribution(column, row);
        case 'product_statuses':
            return renderColumnWithList(column, row, '', 'name');
        case 'targets':
            return renderColumnWithList(column, row);
        case 'bookings':
            return renderColumnWithList(column, row);
        case 'has_event_report':
            return row[column] ? <IconCheckmark/> : null;
        case 'actions' :
            return (
                <div className='actions-list-icon'>{renderAction(row)}</div>
            );
        default:
            return null;
    }
};
