import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {getContent} from '@utils/export/excel';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'udp_origin_entry_points',
    parseData: udpOriginEntryPoint => [
        udpOriginEntryPoint.id,
        udpOriginEntryPoint.name,
        udpOriginEntryPoint.description || '',
        _get(udpOriginEntryPoint, 'encoding_datacenter.name', ''),
        udpOriginEntryPoint.url,
        _get(udpOriginEntryPoint, 'failover_udp_origin_entry_point.name', ''),
        getContent(udpOriginEntryPoint),
    ],
    path: 'udpOriginEntryPoint.index.path',
    titles: [
        'ID',
        'Name',
        'Description',
        'Encoding datacenter',
        'URL',
        'Failover',
        'Content',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
