import React from 'react';
import {isEmpty as _isEmpty} from 'lodash';

import {SRT_ORIGIN_ENTRY_POINT} from '@constants/resources';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {authorizationObject} from '@modules/srtOriginEntryPoint/components/Table/authorizationObject';
import {Authorization} from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import OriginEntryPointContentsRow from '@modules/udpOriginEntryPoint/components/OriginEntryPointContentsRow';

export const RowRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: 'srtOriginEntryPoints.edit',
                        params: {id: row.id},
                    }}
                >
                    {row.id}
                </LinkWithAuthorization>
            );
        case 'name':
            return (
                <>
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'srtOriginEntryPoints.edit',
                            params: {id: row.id},
                        }}
                    >
                        {row.name}
                    </LinkWithAuthorization>
                    {_isEmpty(row.description) ? null : <p className='muted'>{row.description}</p>}
                </>
            );
        case 'url':
            return row.url ?? null;
        case 'content':
            return OriginEntryPointContentsRow(row, 'SrtOriginEntryPoint');
        case 'actions':
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit
                            size='large'
                            resource={SRT_ORIGIN_ENTRY_POINT}
                            link={getLink('srtOriginEntryPoints.edit', {id: row.id})}
                        />
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};
