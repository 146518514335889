import {
    forEach as _forEach,
    get as _get,
    uniq as _uniq,
    reduce as _reduce,
    isEqual as _isEqual,
} from 'lodash';

import {getCompetitors, getContentTierName, getInvoiceStatus} from '@modules/client/utils/clientBookingTable';
import {dropdownParser} from '@utils/filters';
import {mapPropertyLicencesForDropdown} from '@utils/propertyLicence';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';

export const createBookingForTable = (booking, geoRestrictions = [], hasMultipleGeoRestrictions = false) => ({
    id: booking.booking_id,
    booking_id: booking.booking_id,
    event_id: booking.event_id,
    event_description: booking.event_description,
    event_color: booking.event_color,
    match_id: booking.match_id,
    event_content_id: booking.event_content_id,
    distribution_id: booking.distribution_id,
    start_datetime: booking.start_datetime,
    end_datetime: booking.end_datetime,
    match_sport_name: booking.match_sport_name,
    match_tournament_category_name: booking.match_tournament_category_name,
    match_tournament_name: booking.match_tournament_name,
    sr_event_sport_name: booking.sr_event_sport_name,
    sr_event_tournament_category_name: booking.sr_event_tournament_category_name,
    sr_event_tournament_name: booking.sr_event_tournament_name,
    content_category_level_1_name: booking.content_category_level_1_name,
    content_category_level_2_name: booking.content_category_level_2_name,
    content_category_level_3_name: booking.content_category_level_3_name,
    event_type: booking.event_type,
    content_tier_name: getContentTierName(booking),
    competitors: getCompetitors(booking),
    event_content_name: booking.event_content_name,
    event_content_type_name: booking.event_content_type_name,
    event_content_variant_id: booking.event_content_variant_id,
    event_content_variant_name: booking.event_content_variant_name,
    event_content_variant_short_name: booking.event_content_variant_short_name,
    client_package_id: booking.client_package_id,
    is_hq: booking.is_hq,
    client_package_name: booking.client_package_name,
    product_short_name: booking.product_short_name,
    product_is_invoiceable: booking.product_is_invoiceable,
    booking_type_name: booking.booking_type_name,
    distribution_type_name: booking.distribution_type_name,
    price: booking.price,
    booking_creation_datetime: booking.booking_creation_datetime,
    event_invoice_status_name: booking.event_invoice_status_name,
    booking_invoice_status_name: booking.booking_invoice_status_name,
    booking_geo_restrictions: geoRestrictions,
    is_cancelled: booking.is_cancelled,
    has_multiple_geo_restrictions: hasMultipleGeoRestrictions,
    booked_by: booking.booked_by,
    invoice_status: getInvoiceStatus(booking),
    cancellation_user_name: booking.cancellation_user_name,
});

const updateDropdownState = (nextProps, currentState, key, parser = dropdownParser) => {
    const dropdownData = nextProps.dataDropDown[key] || [];

    if (!_isEqual(currentState[key], dropdownData)) {
        return {[key]: parser(dropdownData)};
    }

    return {};
};

const updateCountriesState = (nextProps, currentState) => {
    const countries = nextProps.dataDropDown.countries || [];

    if (!_isEqual(currentState.countries, countries)) {
        return {countries: countries.mapDataForDropdown()};
    }

    return {};
};

const updatePropertyLicencesState = (nextProps, currentState) => {
    const propertyLicences = nextProps.dataDropDown.propertyLicences || [];

    if (!_isEqual(currentState.propertyLicencesOriginal, propertyLicences)) {
        return {
            propertyLicencesOriginal: propertyLicences,
            propertyLicences: mapPropertyLicencesForDropdown(propertyLicences),
        };
    }

    return {};
};

const updateClientBookingsState = (nextProps) => {
    const clientBookings = _get(nextProps, 'DataClientBookings.clientBookings');

    if (clientBookings) {
        return {clientBookings: prepareGeoRestrictions(clientBookings)};
    }

    return {};
};

const dropdownKeys = [
    'contentTiers',
    'bookingTypes',
    'clientPackages',
    'contentCategories',
    'deviceCategories',
    'distributionTypes',
    'eventTypes',
    'invoiceStatuses',
    'products',
    'sports',
    'eventContentTypes',
    'eventContentVariants',
    'contentCategoryLevel1',
];

export const updateStateFromProps = (nextProps, currentState) => {
    if (!nextProps?.dataDropDown) {
        return currentState;
    }

    let newState = _reduce(dropdownKeys, (acc, key) => {
        return {
            ...acc,
            ...updateDropdownState(nextProps, currentState, key),
        };
    }, {});

    newState = {
        ...newState,
        ...updateCountriesState(nextProps, currentState),
        ...updatePropertyLicencesState(nextProps, currentState),
        ...updateDropdownState(nextProps, currentState, 'sports', (data) =>
            sortDropdownOptionsAlphanumerically(dropdownParser(data))),
        ...updateDropdownState(nextProps, currentState, 'contentCategoryLevel1', (data) =>
            sortDropdownOptionsAlphanumerically(dropdownParser(data))),
        ...updateClientBookingsState(nextProps),
    };

    return newState;
};

export const prepareGeoRestrictions = (clientBookings) => {
    let resultBookings = [];

    _forEach(clientBookings, (booking) => {
        if (1 === booking.booking_geo_restrictions.length
            && null !== booking.booking_geo_restrictions[0].country_iso_alpha2_codes
        ) {
            resultBookings.push(createBookingForTable(
                booking,
                [booking.booking_geo_restrictions[0]]
            ));
        } else if (0 === booking.booking_geo_restrictions.length
            || null === booking.booking_geo_restrictions[0].country_iso_alpha2_codes
        ) {
            resultBookings.push(createBookingForTable(booking));
        } else {
            const allCountries = booking.booking_geo_restrictions
                    .map(el => el.country_iso_alpha2_codes)
                    .reduce((prev, curr) => (prev.concat(curr))).sort(),
                allBlackoutZones = booking.booking_geo_restrictions
                    .flatMap(el => el.blackout_zones)
                    .map(bz => bz.id),
                uniqueCountries = _uniq(allCountries),
                uniqueBlackoutZones = _uniq(allBlackoutZones),
                deviceCategoriesCount = booking.booking_geo_restrictions.length;

            if (
                uniqueCountries.length === (allCountries.length / deviceCategoriesCount)
                && uniqueBlackoutZones.length === (allBlackoutZones.length / deviceCategoriesCount)
            ) {
                const deviceCategoryName = booking.booking_geo_restrictions.map(
                    (geoRestriction) => _get(geoRestriction, 'device_category.name')
                ).join(', ');

                let geoRestriction = {
                    ...booking.booking_geo_restrictions[0],
                    device_category: {name: deviceCategoryName},
                };

                if (null !== booking.booking_geo_restrictions[0].country_iso_alpha2_codes) {
                    resultBookings.push(createBookingForTable(
                        booking,
                        [geoRestriction]
                    ));
                } else {
                    resultBookings.push(createBookingForTable(booking));
                }
            } else {
                let geoRestrictions = [];

                _forEach(booking.booking_geo_restrictions, (geoRestriction) => {
                    geoRestrictions.push(geoRestriction);
                });

                resultBookings.push(createBookingForTable(
                    booking,
                    geoRestrictions,
                    true
                ));
            }
        }
    });

    return resultBookings;
};
