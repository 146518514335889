import {isEmpty as _isEmpty, isUndefined as _isUndefined} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Segment} from 'semantic-ui-react';

import {withApollo} from 'react-apollo';
import {getLink} from '@appComponents/Link';
import Tabs from '@appComponents/Tabs';
import {GetThirdPartyClientDataForStore} from '@graphql/thirdPartyClients/query';
import mapModulesToProps from '@utils/mapModulesToProps';
import {convertToInt} from '@utils/helpers';
import {showErrorModal} from '@utils/modal';

import {productThirdPartyTabsState} from '../utils/clientProductThirdPartyLayoutHelper';

class ClientProductThirdPartyLayout extends React.Component {
    static propTypes = {
        client: PropTypes.object.isRequired,
        clientProductThirdParty: PropTypes.shape({
            product: PropTypes.shape({
                has_geolocation_support: PropTypes.bool,
            }),
            third_party_client_product: PropTypes.shape({
                id: PropTypes.string,
            }),
        }).isRequired,
        Entity: PropTypes.object,
        hasCdns: PropTypes.bool,
        hasPullOrigins: PropTypes.bool,
        hasVodStorages: PropTypes.bool,
        match: PropTypes.object.isRequired,
        Modal: PropTypes.object,
        shouldOpenModal: PropTypes.bool,
    };

    static defaultProps = {
        shouldOpenModal: false,
    };

    constructor() {
        super();

        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.setState(() => this.getProductThirdPartyTabsState());
        this.getThirdPartyClientCdnsAndPullOriginsFromGraphQL();
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState(() => this.getProductThirdPartyTabsState(this.props));
        }
    }

    prepareThirdPartyClientCdnsForStore = (thirdPartyClientCdnsForStore) => {
        this.props.Entity.setEntity({
            data: {
                cdns: thirdPartyClientCdnsForStore,
            },
            name: 'thirdPartyClientCdns',
        });
    };

    prepareThirdPartyClientPullOriginsForStore = (thirdPartyClientPullOriginsForStore) => {
        this.props.Entity.setEntity({
            data: {
                pullOrigins: thirdPartyClientPullOriginsForStore,
            },
            name: 'thirdPartyClientPullOrigins',
        });
    };

    prepareThirdPartyClientVodStoragesForStore = (thirdPartyClientVodStoragesForStore) => {
        this.props.Entity.setEntity({
            data: {
                vodStorages: thirdPartyClientVodStoragesForStore,
            },
            name: 'thirdPartyClientVodStorages',
        });
    };

    getThirdPartyClientCdnsAndPullOriginsFromGraphQL = () => {
        if (_isUndefined(this.props.clientProductThirdParty.third_party_client_product)) {
            return;
        }

        this.setState(() => {
            return {
                loading: true,
            };
        });

        this.props.client.query({
            fetchPolicy: 'cache-first',
            query: GetThirdPartyClientDataForStore,
            variables: {
                clientProductThirdParty: convertToInt(this.props.clientProductThirdParty.third_party_client_product.id),
            },
        }).then((data) => {
            if (data) {
                this.prepareThirdPartyClientCdnsForStore(data.data.thirdPartyClientCdns);
                this.prepareThirdPartyClientPullOriginsForStore(data.data.thirdPartyClientPullOrigins);
                this.prepareThirdPartyClientVodStoragesForStore(data.data.thirdPartyClientVodStorages);
            }

            this.setState(() => {
                return {
                    loading: false,
                };
            });
        }).catch(() => {
            this.setState(() => ({loading: false}));

            showErrorModal({
                header: 'Third party was not found',
                text: 'Third party was not found',
                errorButtonText: 'Back to clients',
                hideButtons: false,
                redirectLink: getLink('clients.index'),
                errorButtonIcon: 'arrow circle left',
            });
        });
    };

    getProductThirdPartyTabsState = (props) => {
        return productThirdPartyTabsState(
            props || this.props,
            this.prepareThirdPartyClientCdnsForStore,
            this.prepareThirdPartyClientPullOriginsForStore,
            this.prepareThirdPartyClientVodStoragesForStore
        );
    };

    render() {
        return (
            (this.state.loading)
                ? <Segment basic loading={true} padded='very'/>
                : <Tabs active={this.state.active} items={this.state.tabs} level={2}
                    segmentClassName='--marginTopNegative-15 --paddingTop-0'/>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Entity', 'MessageBox', 'Modal']);

const mapStateToProps = (state) => {
    let hasCdns,
        hasPullOrigins,
        hasVodStorages;

    const cdnsInStore = state.app.entities.thirdPartyClientCdns,
        pullOriginsInStore = state.app.entities.thirdPartyClientPullOrigins,
        vodStoragesInStore = state.app.entities.thirdPartyClientVodStorages;

    if (!_isUndefined(cdnsInStore)) {
        hasCdns = !_isEmpty(cdnsInStore.cdns);
    }

    if (!_isUndefined(pullOriginsInStore)) {
        hasPullOrigins = !_isEmpty(pullOriginsInStore.pullOrigins);
    }

    if (!_isUndefined(vodStoragesInStore)) {
        hasVodStorages = !_isEmpty(vodStoragesInStore.vodStorages);
    }

    return {
        hasCdns: hasCdns,
        hasPullOrigins: hasPullOrigins,
        hasVodStorages: hasVodStorages,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withApollo(ClientProductThirdPartyLayout)));
