import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../app/components/Table';
import {rowRenderer} from './ContentCategoryTableRowRenderer';
import {columns} from './ContentCategoryTableColumns';

export class ContentCategoryTable extends React.Component {
    renderContent() {
        if (false === this.props.loading && (!this.props.data || 0 === this.props.data.length)) {
            return 'No categories found.';
        }

        let rowClass = (3 !== this.props.level) ? 'clickable' : '';

        return (
            <Table
                selected={this.props.selected}
                className={`content-categories no-padding ${rowClass}`}
                name={`contentCategoriesLevel${this.props.level}List`}
                columns={columns}
                data={this.props.data}
                pagination={false}
                loading={this.props.loading}
                rowRenderer={rowRenderer}
                rowRendererProps={this.props.rowRendererProps}
                rowOnClick={this.props.rowOnClick}
                rowClassname={tableRowData => this.props.selected[0] === tableRowData.id ? '--is-selected' : ''}
            />);
    }

    render() {
        return (
            <div>
                {this.renderContent()}
            </div>
        );
    }
}

ContentCategoryTable.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    rowRendererProps: PropTypes.object,
    rowOnClick: PropTypes.func,
    level: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    selected: PropTypes.array,
};

export default ContentCategoryTable;
