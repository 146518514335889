import {
    get as _get,
    has as _has,
    sortBy as _sortBy,
    map as _map,
    orderBy as _orderBy,
    isEmpty as _isEmpty,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Header} from 'semantic-ui-react';

import {gql, graphql, withApollo} from 'react-apollo';
import {getDataForMultipleEventCreation} from '@graphql/propertyLicences';
import {showModal, showErrorModal, showLoadingModal} from '@utils/modal';
import {mapPropertyLicencesForDropdown} from '@utils/propertyLicence';
import {
    ORIGIN_STREAM_TYPE_SRT,
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_RTMP,
    ORIGIN_STREAM_TYPE_SDI,
    ORIGIN_STREAM_TYPE_UDP,
    ORIGIN_STREAM_TYPE_MEDIACONNECT,
    COMPETITOR_NOT_DEFINED_BOTH,
    COMPETITOR_NOT_DEFINED,
} from '@constants/variables';
import {convertToInt, digitsConnectedByPlus} from '@utils/helpers';
import {getSearchFilters, dropdownParser} from '@utils/filters';
import {routes} from '@constants/routes';
import {NOT_CREATED} from '@constants/messages';
import {createForm} from '@utils/forms';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getLink} from '@appComponents/Link';
import * as eventGraphQl from '@graphql/events/events.js';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import EventGroupNotificationModel from '@modules/notifications/forms/EventGroupNotificationModel';
import EventGroupNotificationForm from '@modules/notifications/forms/EventGroupNotificationForm';
import {showConstraintsModal, skipSearch} from '@modules/events/utils/constraintsTables';

import EventScheduleFilters from '../components/EventScheduleFilters';
import EventScheduleTable from '../components/EventScheduleTable';
import EventsIndexButtons from '../components/EventsIndexButtons';
import MultipleEventCreationModalContent from './ChildEvents/MultipleEventCreationModalContent';
import NonSportradarEventModel from '../forms/NonSportradarEventModel';
import NonSportradarEventForm from '../forms/NonSportradarEventForm';
import SportradarExistingEventByMatchModel from '../forms/SportradarExistingEventByMatchModel';
import SportradarNonExistingEventModel from '../forms/SportradarNonExistingEventModel';
import SportradarNonExistingEventForm from '../forms/SportradarNonExistingEventForm';
import SportradarExistingForm from '../forms/SportradarExistingForm';
import {matchProperties} from '../constants/matchProperties';
import {exportExcel as eventsIndexExportExcel} from '../utils/export/eventsIndexExcel';
import {
    showErrorNoEventsForPresetSelected, showErrorNoEventsForSetCoverage,
    showErrorNoMatchesSelected, showErrorOnSetCoverage,
    showErrorWrongMatchesSelected,
} from '../utils/multipleEventActions';
import {
    getIdsFromEventsSelectionForMultiplePresetApply,
    getSrEventsWithSamePropertyLicence,
    getCommonProductsWithBookingSupport,
    getRightScopesWithMediaRights,
} from './common/EventsMultipleActions';
import MultipleEventPresetApplyModalContent from '../components/MultipleEventPresetApplyModalContent';
import ReportMultipleAsWorkingAndInvoiceableModalContent
    from '../components/ReportMultipleAsWorkingAndInvoiceableModalContent';
import {
    allEventsHasLcoDistribution,
    checkIfIsEvent,
    checkIfIsTvStreaming,
    isAwayCompetitorBlackListed,
    isHomeCompetitorBlacklisted,
    getCompetitorWarnings,
    getTvStreamingEncoderId,
} from '../utils/eventSchedule';
import SetCoverageModalContent from '../components/SetCoverageModalContent';

export class EventsIndex extends React.Component{
    static propTypes = {
        client: PropTypes.object.isRequired,
        data: PropTypes.object,
        DataEventSchedule: PropTypes.object.isRequired,
        DataEventScheduleFilters: PropTypes.object.isRequired,
        filters: PropTypes.shape({
            category: PropTypes.arrayOf(PropTypes.number),
            clipProvider: PropTypes.arrayOf(PropTypes.number),
            coverage: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel1: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel2: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel3: PropTypes.arrayOf(PropTypes.number),
            contributionType: PropTypes.arrayOf(PropTypes.number),
            eventContentTypes: PropTypes.arrayOf(PropTypes.number),
            eventContentVariants: PropTypes.arrayOf(PropTypes.number),
            encodingDatacenter: PropTypes.arrayOf(PropTypes.number),
            eventStatus: PropTypes.arrayOf(PropTypes.number),
            eventType: PropTypes.number,
            from: PropTypes.string,
            hasBlacklistedCompetitor: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
            hasEventReport: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
            hasMatchId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
            matchProperties: PropTypes.arrayOf(PropTypes.string),
            product: PropTypes.arrayOf(PropTypes.number),
            productStatus: PropTypes.arrayOf(PropTypes.number),
            propertyLicence: PropTypes.arrayOf(PropTypes.number),
            scheduled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
            search: PropTypes.string,
            sport: PropTypes.arrayOf(PropTypes.number),
            stadium: PropTypes.arrayOf(PropTypes.number),
            tvChannels: PropTypes.arrayOf(PropTypes.number),
            to: PropTypes.string,
            tournament: PropTypes.arrayOf(PropTypes.number),
            venue: PropTypes.arrayOf(PropTypes.number),
        }),
        history: PropTypes.object,
        loadingRefetch: PropTypes.bool.isRequired,
        match: PropTypes.object,
        Menu: PropTypes.object,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        modal: PropTypes.object,
        Filters: PropTypes.object,
    };

    static defaultProps = {
        client: {},
        data: {},
        filters: {
            category: [],
            clipProvider: [],
            contentCategoryLevel1: [],
            contentCategoryLevel2: [],
            contentCategoryLevel3: [],
            contributionType: [],
            coverage: [],
            encodingDatacenter: [],
            eventContentTypes: [],
            eventContentVariants: [],
            eventPreset: [],
            eventStatus: [],
            eventType: null,
            from: moment().format('YYYY-MM-DD 00:00'),
            hasBlacklistedCompetitor: null,
            hasEventReport: null,
            hasMatchId: null,
            matchProperties: [],
            product: [],
            productStatus: [],
            propertyLicence: [],
            scheduled: null,
            search: null,
            sport: [],
            stadium: [],
            to: moment().add(1, 'd').format('YYYY-MM-DD 00:00'),
            tournament: [],
            venue: [],
            tvChannels: [],
        },
    };

    constructor(props) {
        super(props);

        this.props.Menu.storeMenu(navigation.events_scheduling.key);

        this.state = {
            clipProviders: [],
            contributionTypes: [],
            encodingDatacenters: [],
            eventSchedule: [],
            eventScheduleTableData: [],
            invoiceableProductsForExcelExport: {},
            eventStatuses: [],
            eventTypes: [],
            eventContentTypes: [],
            eventContentVariants: [],
            hasEventReport: [
                {
                    key: 1,
                    text: 'Has report',
                    value: 1,
                },
                {
                    key: 0,
                    text: 'Has no report',
                    value: 0,
                },
            ],
            hasMatchId: [
                {
                    key: 1,
                    text: 'Has match ID',
                    value: 1,
                },
                {
                    key: 0,
                    text: 'Has no match ID',
                    value: 0,
                },
            ],
            products: [],
            coverage: [],
            productStatuses: [],
            propertyLicences: [],
            selectedElements: [],
            scheduledOrNot: [
                {
                    key: 1,
                    text: 'Scheduled',
                    value: 1,
                },
                {
                    key: 0,
                    text: 'Not scheduled',
                    value: 0,
                },
            ],
            venues: [],
        };
    }

    componentDidMount() {
        this.loadAddModal(this.props.match, _get(this.props, 'match.params.matchId', false));

        if (skipSearch(this.props)) {
            showConstraintsModal(this.props.filters);
        }

        if (!digitsConnectedByPlus(this.props.match.params.eventsIds)
            && this.props.match.path === routes.events.add.presetMultiple.path
            || this.props.match.path === routes.events.reportMultipleAsWorkingAndInvoiceable.path) {
            if (this.props.match.path === routes.events.add.setCoverage.path) {
                showErrorNoEventsForSetCoverage();
            } else {
                showErrorNoEventsForPresetSelected();
            }
        }

        if (!digitsConnectedByPlus(this.props.match.params.matchIds)
            && this.props.match.path === routes.events.add.multiple.path) {
            showErrorWrongMatchesSelected();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const
            urlChanged = this.props.match.path !== prevProps.match.path,
            urlIsNotIndex = this.props.match.path !== routes.events.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex;

        shouldOpenModal && this.loadAddModal(this.props.match, _get(this.props, 'match.params.matchId', false));

        const
            modalChanged = prevProps.modal.isVisible !== this.props.modal.isVisible,
            modalIsNotVisible = !this.props.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        modalWasClosed && this.props.history.push(routes.events.index.path);

        const prevEventScheduleData = JSON.stringify(prevState.eventSchedule);
        const currentEventScheduleData = JSON.stringify(this.state.eventSchedule);

        if (prevEventScheduleData !== currentEventScheduleData) {
            this.getSelectedElements([]);
            let eventScheduleTableData = this.prepareEventScheduleTableData(this.state.eventSchedule);
            eventScheduleTableData = _orderBy(eventScheduleTableData, ['start_datetime'], ['asc']);
            this.setState({eventScheduleTableData});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const eventSchedule = _get(nextProps, 'DataEventSchedule.eventSchedule', {
            events: [],
            matches: [],
        });

        const {
            DataEventScheduleFilters: {
                clipProviders,
                contributionTypes,
                encodingDatacenters,
                eventStatuses,
                eventContentTypes,
                eventContentVariants,
                eventTypes,
                products,
                productStatuses,
                propertyLicences,
                venues,
            },
        } = nextProps;

        let nextState = {};

        if (clipProviders && prevState.clipProviders !== clipProviders) {
            nextState.clipProviders = dropdownParser(clipProviders);
        }

        if (contributionTypes && prevState.contributionTypes !== []) {
            nextState.contributionTypes = dropdownParser(contributionTypes);
        }

        if (encodingDatacenters && [] !== prevState.encodingDatacenters) {
            nextState.encodingDatacenters = dropdownParser(encodingDatacenters);
        }

        if (eventStatuses && prevState.eventStatuses !== []) {
            nextState.eventStatuses = dropdownParser(eventStatuses);
        }

        if (eventContentTypes && prevState.eventContentTypes !== []) {
            nextState.eventContentTypes = dropdownParser(eventContentTypes);
        }

        if (eventContentVariants && prevState.eventContentVariants !== []) {
            nextState.eventContentVariants = dropdownParser(eventContentVariants);
        }

        if (eventTypes && prevState.eventTypes !== []) {
            nextState.eventTypes = dropdownParser(eventTypes);
        }

        if (eventSchedule && prevState.eventSchedule !== eventSchedule) {
            nextState.eventSchedule = eventSchedule;
        }

        if (products && prevState.products !== []) {
            nextState.products = dropdownParser(products);
            nextState.coverage = dropdownParser(
                products.filter((product) => product.has_event_booking_support)
            );
        }

        if (productStatuses && prevState.productStatuses !== []) {
            nextState.productStatuses = dropdownParser(productStatuses)
                .sort((a, b) => (a.value > b.value));
        }

        if (propertyLicences && prevState.propertyLicences !== []) {
            nextState.propertyLicences = mapPropertyLicencesForDropdown(propertyLicences);
        }

        if (venues && [] !== prevState.venues) {
            nextState.venues = venues.map((venue) => ({
                key: venue.value,
                text: venue.text,
                value: convertToInt(venue.value),
            }));
        }

        return nextState;
    }

    createEventScheduleTableRowData = (data) => {
        const isSrEvent = !!(checkIfIsEvent(data) && null !== data.sr_event);

        return Object.assign({
            id: (checkIfIsEvent(data) ? 'event_' : 'match_')
                + convertToInt(data.id) + '_'
                + Number(isSrEvent),
            event_id: checkIfIsEvent(data) ? convertToInt(data.id) : null,
            is_sr_event: isSrEvent,
            match_id: this.getMatchId(data),
            start_datetime: data.start_datetime,
            end_datetime: this.getEndDatetime(data),
            sport_or_content_category_level_1: this.getCategoryLevel(data, 1),
            category_or_content_category_level_2: this.getCategoryLevel(data, 2),
            tournament_or_content_category_level_3: this.getCategoryLevel(data, 3),
            venue_or_court: this.getVenueOrCourt(data),
            tv_channel: this.getTvChannel(data),
            competitors: this.getCompetitors(data),
            competitors_warnings: getCompetitorWarnings(data),
            description: checkIfIsEvent(data) ? data.description : null,
            color: checkIfIsEvent(data) ? data.color : null,
            status: checkIfIsEvent(data) && _has(data, 'event_status.id') ? data.event_status.name : 'Available',
            status_id: _get(data, 'event_status.id', null),
            notes: checkIfIsEvent(data) ? data.notes : null,
            origin_stream_status: checkIfIsEvent(data) ? data.origin_stream_status : null,
            comment: checkIfIsEvent(data) ? data.comment : null,
            has_event_report: (checkIfIsEvent(data) ? data.has_event_report : null),
            event_issue_types: checkIfIsEvent(data) ? data.event_issue_types : null,
            is_tv_streaming: checkIfIsTvStreaming(data),
            tv_streaming_encoder_id: getTvStreamingEncoderId(data),
            is_hc_blacklisted: isHomeCompetitorBlacklisted(data),
            is_ac_blacklisted: isAwayCompetitorBlackListed(data),
            coverage: data.products?.map((product) => product.short_name).sort().join(', ') ?? null,
            clip_provider: data.clip_provider?.name ?? null,
        }, this.getRelationTableData(data));
    };

    getMatchId = (data) => {
        if (_has(data, 'sr_event.match.id')) {
            return convertToInt(data.sr_event.match.id);
        } else if (!checkIfIsEvent(data)) {
            return convertToInt(data.id);
        }

        return null;
    };

    getEndDatetime = (data) => {
        return data.end_datetime || null;
    };

    getCategoryLevel = (data, level) => {
        if (checkIfIsEvent(data) && _get(data, 'sr_event.match.tournament', false)) {
            switch (level) {
                case 1:
                    return _get(data, 'sr_event.match.tournament.tournament_category.sport.name', null);
                case 2:
                    return _get(data, 'sr_event.match.tournament.tournament_category.name', null);
                case 3:
                    return _get(data, 'sr_event.match.tournament.name', null);
                default:
                    return null;
            }
        } else if (checkIfIsEvent(data) && _get(data, 'sr_event.tournament', false)) {
            switch (level) {
                case 1:
                    return _get(data, 'sr_event.tournament.tournament_category.sport.name', null);
                case 2:
                    return _get(data, 'sr_event.tournament.tournament_category.name', null);
                case 3:
                    return _get(data, 'sr_event.tournament.name', null);
                default:
                    return null;
            }
        }  else if (checkIfIsEvent(data) && _get(data, `av_event.content_category_level${level}.id`, false)) {
            return data.av_event[`content_category_level${level}`].name;
        } else if (!checkIfIsEvent(data) && _get(data, 'tournament', false)) {
            switch (level) {
                case 1:
                    return _get(data, 'tournament.tournament_category.sport.name', null);
                case 2:
                    return _get(data, 'tournament.tournament_category.name', null);
                case 3:
                    return _get(data, 'tournament.name', null);
                default:
                    return null;
            }
        }

        return null;
    };

    getVenueOrCourt = (data) => {
        if (checkIfIsEvent(data)) {
            if (_has(data, 'sr_event.match.id')) {
                return this.getVenueOrCourtByMatch(data.sr_event.match);
            } else if (_has(data, 'sr_event.id')) {
                if (_has(data, 'sr_event.stadium.id')) {
                    return data.sr_event.stadium.name;
                }
            } else if (_has(data, 'av_event.venue.id')) {
                return data.av_event.venue.name;
            }
        } else if (!checkIfIsEvent(data)) {
            return this.getVenueOrCourtByMatch(data);
        }

        return null;
    };

    getVenueOrCourtByMatch = (data) => (_get(data, 'stadium.name', null));

    getTvChannel = (matchOrEvent) => {
        if (_has(matchOrEvent, 'sr_event.match.tv_channels')) {
            return this.getTvChannelsByMatch(matchOrEvent.sr_event.match.tv_channels);
        }

        // av_event never have "tv_channels" in the main structure
        return this.getTvChannelsByMatch(_get(matchOrEvent, 'tv_channels', []));
    };

    getTvChannelsByMatch = (tv_channels) => (_map(tv_channels, (tvChannel) => tvChannel.name).join(', '));

    clearUndefinedCompetitors = (competitors) => {
        if (competitors[0] === COMPETITOR_NOT_DEFINED && competitors[1] === COMPETITOR_NOT_DEFINED) {
            return [];
        }

        return competitors;
    };

    getCompetitors = (data) => {
        let competitors = [],
            defaultCompetitors = COMPETITOR_NOT_DEFINED_BOTH;

        if (checkIfIsEvent(data) && _has(data, 'sr_event.match.id')) {
            competitors = this.getCompetitorsByMatch(data.sr_event.match);
            competitors = this.clearUndefinedCompetitors(competitors);
        } else if (
            checkIfIsEvent(data) &&
            _has(data, 'sr_event.id') &&
            (_has(data, 'sr_event.home_competitor.id') || _has(data, 'sr_event.away_competitor.id'))
        ) {
            competitors.push(this.getCompetitorsByField(data.sr_event, 'home_competitor'));
            competitors.push(this.getCompetitorsByField(data.sr_event, 'away_competitor'));
        } else if (
            checkIfIsEvent(data) &&
            _has(data, 'av_event.id') &&
            (_has(data, 'av_event.home_av_competitor.id') || _has(data, 'av_event.away_av_competitor.id'))
        ) {
            competitors.push(this.getCompetitorsByField(data.av_event, 'home_av_competitor'));
            competitors.push(this.getCompetitorsByField(data.av_event, 'away_av_competitor'));
        } else if (checkIfIsEvent(data)) {
            defaultCompetitors = '';
        } else if (!checkIfIsEvent(data)) {
            competitors = this.getCompetitorsByMatch(data);
            competitors = this.clearUndefinedCompetitors(competitors);
        }

        return 0 < competitors.length ? competitors.join(' : ') : defaultCompetitors;
    };

    getCompetitorsByMatch = (data) => {
        let competitors = [];

        competitors.push(this.getCompetitorsByField(data, 'home_competitor'));
        competitors.push(this.getCompetitorsByField(data, 'away_competitor'));

        return competitors;
    };

    getCompetitorsByField = (data, competitorField) => {
        if (competitorField && _has(data, `${competitorField}.id`)) {
            return data[competitorField].name;
        } else {
            return COMPETITOR_NOT_DEFINED;
        }
    };

    getRelationTableData = (data) => {
        let relationTableData = {
            contents: [],
            contribution_types: [],
            encoding_datacenters: [],
            products: [],
            distribution_types: [],
            product_statuses: [],
            targets: [],
            bookings: [],
        };

        const setEventContentWarning = (eventContent) => {
            let warning = null;

            if(eventContent.has_input) {
                const originStreamType = parseInt(_get(eventContent,'event_content_input.origin_stream_type.id', null), 10);

                if(originStreamType === ORIGIN_STREAM_TYPE_RTMP
                    && null === _get(eventContent, 'event_content_input.rtmp_origin_stream_name.id', null)
                ) {
                    warning = {
                        content: 'No RTMP origin stream name defined.',
                    };
                }

                if(originStreamType === ORIGIN_STREAM_TYPE_UDP
                    && null === _get(eventContent, 'event_content_input.udp_origin_entry_point.id', null)
                ) {
                    warning = {
                        content: 'No UDP origin entry point defined.',
                    };
                }

                if(originStreamType === ORIGIN_STREAM_TYPE_SDI
                    && null === _get(eventContent, 'event_content_input.sdi_origin_entry_point.id', null)
                ) {
                    warning = {
                        content: 'No SDI origin entry point defined.',
                    };
                }

                if(
                    ORIGIN_STREAM_TYPE_HTTP === originStreamType
                    && null === eventContent.event_content_input.http_origin_entry_point_url
                ) {
                    warning = {content: 'No HTTP origin entry point URL defined.'};
                }

                if(
                    ORIGIN_STREAM_TYPE_SRT === originStreamType
                    && null === eventContent.event_content_input.srt_origin_entry_point_url
                ) {
                    warning = {content: 'No SRT origin entry point URL defined.'};
                }

                if (ORIGIN_STREAM_TYPE_MEDIACONNECT === originStreamType
                    && null === _get(eventContent, 'event_content_input.mediaconnect_origin_entry_point.id', null)
                ) {
                    warning = {content: 'No MediaConnect origin entry point defined'};
                }
            }

            return warning;
        };

        if (checkIfIsEvent(data)) {
            const contents = Object.assign([], data.event_contents);

            contents.sort((a, b) => (
                a.event_content_type.name > b.event_content_type.name ? 1 : -1
            )).map((event_content) => {
                event_content.products.map((product) => {
                    relationTableData.contents.push({
                        event_content_id: event_content.id,
                        name: _has(event_content, 'event_content_type.id') ? event_content.event_content_type.name : null,
                        eventContentWarning: setEventContentWarning(event_content),
                        eventContentVariant: event_content.event_content_variant,
                    });

                    relationTableData.contribution_types.push({
                        event_content_id: event_content.id,
                        name: _has(event_content, 'contribution_type.id')
                            ? event_content.contribution_type.name
                            : 'No input',
                    });

                    relationTableData.encoding_datacenters.push({
                        event_content_id: event_content.id,
                        name: _get(event_content, 'encoding_datacenter.name', ''),
                    });

                    if (product.is_invoiceable) {

                        this.setState((currentState) => ({
                            invoiceableProductsForExcelExport: {...currentState.invoiceableProductsForExcelExport,
                                ...{[convertToInt(product.product_id)]: product.short_name},
                            },
                        }));
                    }

                    relationTableData.products.push({
                        product_id: product.product_id,
                        event_content_id: event_content.id,
                        short_name: product.short_name,
                        is_invoiceable: product.is_invoiceable,
                        invoice_status_name: product.invoice_status_name,
                    });

                    relationTableData.distribution_types.push({
                        product_id: product.product_id,
                        event_content_id: event_content.id,
                        name: product.distribution_types,
                    });

                    relationTableData.product_statuses.push({
                        product_id: product.product_id,
                        event_content_id: event_content.id,
                        name: product.product_status,
                    });

                    relationTableData.targets.push({
                        product_id: product.product_id,
                        event_content_id: event_content.id,
                        targets: product.targets,
                    });

                    relationTableData.bookings.push({
                        product_id: product.product_id,
                        event_content_id: event_content.id,
                        bookings: product.bookings,
                    });
                });
            });
        }

        return relationTableData;
    };

    loadAddModal(match, id) {
        switch (match.path) {
            case routes.events.add.nonSportradar.path:
                return this.loadModalForm(createForm(
                    NonSportradarEventModel,
                    NonSportradarEventForm
                ));

            case routes.events.add.sportradarNonExisting.path:
                return this.loadModalForm(createForm(
                    SportradarNonExistingEventModel,
                    SportradarNonExistingEventForm
                ));

            case routes.events.add.sportradarExisting.path:
                if (!id) {
                    return null;
                }

                return this.loadModalForm(createForm(
                    SportradarExistingEventByMatchModel,
                    SportradarExistingForm,
                    {
                        id: id,
                        optionsVariables: {
                            matchId: parseInt(id, 10),
                        },
                    }
                ));
            case routes.events.add.multiple.path:
                return this.showMultipleEventCreation(
                    match.params.matchIds.split('+').map((matchId) => convertToInt(matchId))
                );
            case routes.events.add.presetMultiple.path:
                return this.showMultipleEventPresetApply(
                    match.params.eventsIds.split('+').map((eventId) => convertToInt(eventId))
                );
            case routes.events.add.setCoverage.path:
                return this.showSetCoverage(
                    match.params.eventsIds.split('+').map((eventId) => convertToInt(eventId))
                );
            case routes.events.reportMultipleAsWorkingAndInvoiceable.path:
                return this.showReportMultipleAsWorkingAndIncoiceable(
                    match.params.eventIds.split('+').map((eventId) => convertToInt(eventId))
                );
            default:
                return null;
        }
    }

    notCreatedCallback = (response) => {
        this.props.MessageBox.addMessage('eventsIndex', NOT_CREATED('Event'), response.message, 'error');
    };

    loadModalForm(Form) {
        this.props.Modal.setModal({
            header: false,
            content: <Form onNotCreatedCallback={this.notCreatedCallback}/>,
            isVisible: true,
            size: 'small',
        });
    }

    showMultipleEventPresetApply = (eventsIds = []) => {
        showLoadingModal();
        getRightScopesWithMediaRights(this.props.client, eventsIds, (data) => {
            const products = getCommonProductsWithBookingSupport(data.rightsScopesForEvents);

            getSrEventsWithSamePropertyLicence(eventsIds, (events) => {
                this.props.Modal.setModal({
                    content: <MultipleEventPresetApplyModalContent
                        data={events}
                        products={products}
                        queryForRefetch={'EventScheduleQuery'}
                    />,
                    className: 'multipleEventCreation',
                    size: 'large',
                });
            });
        });
    };

    showSetCoverage = (eventsIds = []) => {
        showLoadingModal();
        getRightScopesWithMediaRights(this.props.client, eventsIds, (data) => {
            const products = getCommonProductsWithBookingSupport(data.rightsScopesForEvents);

            if (0 !== products.length) {
                getSrEventsWithSamePropertyLicence(eventsIds, (events) => {
                    this.props.Modal.setModal({
                        content: <SetCoverageModalContent
                            data={events}
                            products={products}
                            rightScopes={data.rightsScopesForEvents}
                            queryForRefetch={'EventScheduleQuery'}
                        />,
                        className: 'multipleEventCreation',
                        size: 'large',
                    });
                }, true);
            } else {
                showErrorOnSetCoverage();
            }
        });
    };

    showReportMultipleAsWorkingAndIncoiceable = (eventIds = []) => {
        showModal({
            content: <ReportMultipleAsWorkingAndInvoiceableModalContent eventIds={eventIds} />,
            size: 'fullscreen',
            isVisible: true,
        });
    };

    showMultipleEventCreation = (matchesIds = []) => {
        showLoadingModal();

        this.props.client.query({
            query: gql(getDataForMultipleEventCreation),
            fetchPolicy: 'network-only',
            variables: {
                matches: matchesIds,
            },
        }).then(({data}) => {
            if (0 === data.propertyLicencesForMultipleMatchesDropdown.length) {
                showErrorWrongMatchesSelected();
            } else {
                const sortedMatches = _sortBy(data.matches, ['start_datetime']);

                this.props.Modal.setModal({
                    content: <MultipleEventCreationModalContent
                        data={{...data, matches: sortedMatches}}
                        matchesIds={matchesIds}
                        queryForRefetch={'EventScheduleQuery'}
                    />,
                    isVisible: true,
                    size: 'large',
                    className: 'multipleEventCreation',
                });
            }
        }).catch(() => {
            showErrorWrongMatchesSelected();
        });
    };

    addEventHandler = (e) => {
        if ('applyPresetToEvents' === e.currentTarget.dataset.idkey) {
            const eventsIds = getIdsFromEventsSelectionForMultiplePresetApply(this.state.selectedElements);

            eventsIds && this.props.history.push(getLink('events.add.presetMultiple', {
                eventsIds: eventsIds.join('+'),
            }));

            return;
        }

        if ('setCoverageToEvents' === e.currentTarget.dataset.idkey) {
            const eventsIds = getIdsFromEventsSelectionForMultiplePresetApply(this.state.selectedElements, false, true);

            eventsIds && this.props.history.push(getLink('events.add.setCoverage', {
                eventsIds: eventsIds.join('+'),
            }));

            return;
        }

        if ('createSportradarEvents' === e.currentTarget.dataset.idkey) {
            const matchesIds = [];
            const isMatchesListCorrect = !this.state.selectedElements.some((eventString) => {
                let matchSplitted = eventString.split('_');

                if ('match' !== matchSplitted[0]) {
                    return true;
                }

                matchesIds.push(convertToInt(matchSplitted[1]));
            });

            if (!isMatchesListCorrect) {
                showErrorWrongMatchesSelected();

                return;
            }

            if (0 === this.state.selectedElements.length) {
                showErrorNoMatchesSelected();

                return;
            }

            this.props.history.push(getLink('events.add.multiple', {
                matchIds: matchesIds.join('+'),
            }));

            return;
        }

        if ('eventGroupNotification' === e.currentTarget.dataset.idkey) {
            const eventsIds = [],
                isEventsListCorrect = !this.state.selectedElements.some((eventString) => {
                    let eventSplitted = eventString.split('_');

                    eventsIds.push(eventSplitted[1]);

                    return 0 === convertToInt(eventSplitted[2]);
                });

            const eventDescription = this.getEventDescription(
                _get(this.props.DataEventSchedule,
                    'eventSchedule.events',
                    []),
                eventsIds
            );

            if (!isEventsListCorrect) {
                this.props.Modal.setModalError({
                    header: 'Send notification to clients',
                    text: 'Event group notifications can be sent only for Sportradar events. ' +
                        'Please correct your selection.',
                    isVisible: true,
                    size: 'large',
                });

                return;
            }

            if (0 === eventsIds.length) {
                this.props.Modal.setModalError({
                    header: 'Send notification to clients',
                    text: 'There are no events selected.',
                    isVisible: true,
                    size: 'large',
                });

                return;
            }

            const selectedEventsData = this.state.eventSchedule.events.filter(event => eventsIds.includes(String(event.id)));

            if (!allEventsHasLcoDistribution(selectedEventsData)) {
                this.props.Modal.setModalError({
                    header: 'Send notification to clients',
                    text: 'All selected events must have at least one LCO distribution.',
                    isVisible: true,
                    size: 'large',
                });

                return;
            }

            let Form = createForm(
                EventGroupNotificationModel,
                EventGroupNotificationForm,
                {
                    optionsVariables: {
                        eventsIds: eventsIds,
                    },
                }
            );

            this.props.Modal.setModal({
                content: <div className='ClientsAlertModal'>
                    <Header content='Send notification to clients'/>
                    <Form
                        eventDescription={eventDescription}
                    />
                </div>,
                isVisible: true,
                size: 'large',
            });

            return;
        }

        if ('reportMultipleAsWorkingAndInvoiceable'=== e.currentTarget.dataset.idkey) {
            const eventIds = [];
            const isEventsListCorrect = !this.state.selectedElements.some((eventString) => {
                const eventSplitted = eventString.split('_');

                // event scheduling grid ids are built this way:
                // [event|match]_{eventid/matchId}_{isSrEvent?1:0}
                if ('event' !== eventSplitted[0]) {
                    return true;
                }

                eventIds.push(convertToInt(eventSplitted[1]));
            });

            if (!isEventsListCorrect || (1 > eventIds.length)) {
                showErrorModal({
                    header: 'Invalid selection to report as working and invoiceable',
                    text: 'Reporting as working and invoiceable is possible only for a selection of events. ' +
                        'Please correct your selection.',
                });

                return;
            }

            eventIds && this.props.history.push(
                getLink('events.reportMultipleAsWorkingAndInvoiceable' , {eventIds: eventIds.join('+')})
            );

            return;

        }

        this.props.history.push(e.currentTarget.dataset.key);
    };

    getEventDescription = (events, selectedEventsIds) => {
        const selectedEvents = this.filterSelectedEvents(events, selectedEventsIds);
        const eventDescription = this.prepareEventDescription(selectedEvents);
        const tournamentIds = this.getTournamentIds(selectedEvents);

        return this.formatEventDescription(eventDescription, tournamentIds);
    };

    filterSelectedEvents = (events, selectedEventIds) => {
        return events.filter(event => selectedEventIds.includes(String(event.id)));
    };

    prepareEventDescription = (selectedEvents) => {
        let description = {};

        selectedEvents.forEach(event => {
            const match = event.sr_event?.match;
            const tournament = match ? match.tournament : event.sr_event?.tournament;

            if (tournament) {
                Object.assign(description, {
                    sport: tournament.tournament_category.sport.name,
                    category: tournament.tournament_category.name,
                    tournament: tournament.name,
                });
            }
        });

        return description;
    };

    getTournamentIds = (selectedEvents) => {
        return selectedEvents.map(event => {
            const match = event.sr_event?.match;

            return match ? match.tournament.id : event.sr_event?.tournament.id;
        });
    };

    formatEventDescription = (eventDescription, tournamentIds) => {
        const allSameTournament = 1 === tournamentIds.length
            || tournamentIds.every(tid => tid === tournamentIds[0]);

        if (!allSameTournament) {
            return '';
        }

        return `${eventDescription.sport} > ${eventDescription.category} > ${eventDescription.tournament}`;
    };

    prepareEventScheduleTableData = (eventSchedule) => {
        let events = eventSchedule.events,
            matches = eventSchedule.matches,
            eventScheduleTableData = [];

        events.forEach((event) => {
            let matchesBeforeCurrentEvent = [],
                matchesAfterCurrentEvent = [];

            if (0 < matches.length) {
                matches.forEach((match) => {
                    if (match.start_datetime < event.start_datetime) {
                        matchesBeforeCurrentEvent.push(match);
                    } else {
                        matchesAfterCurrentEvent.push(match);
                    }
                });

                matches = matchesAfterCurrentEvent;
            }

            if (0 < matchesBeforeCurrentEvent.length) {
                matchesBeforeCurrentEvent.forEach((match) => {
                    eventScheduleTableData.push(this.createEventScheduleTableRowData(match));
                });
            }

            eventScheduleTableData.push(this.createEventScheduleTableRowData(event));
        });

        matches.forEach((match) => {
            eventScheduleTableData.push(this.createEventScheduleTableRowData(match));
        });

        return eventScheduleTableData;
    };

    getSelectedElements = (selectedElements) => {
        this.setState(() => ({selectedElements: selectedElements}));
    };

    render() {
        const loading = (
                _get(this.props.DataEventSchedule,'loading')
                || this.props.DataEventScheduleFilters.loading
                || this.props.loadingRefetch
            ),
            filtersProps = {
                data: {
                    clipProviders: this.state.clipProviders,
                    contributionTypes: this.state.contributionTypes,
                    encodingDatacenters: this.state.encodingDatacenters,
                    eventStatuses: this.state.eventStatuses,
                    eventTypes: this.state.eventTypes,
                    eventContentTypes: this.state.eventContentTypes,
                    eventContentVariants: this.state.eventContentVariants,
                    hasEventReport: this.state.hasEventReport,
                    hasMatchId: this.state.hasMatchId,
                    matchProperties: matchProperties,
                    products: this.state.products,
                    coverage: this.state.coverage,
                    productStatuses: this.state.productStatuses,
                    propertyLicences: this.state.propertyLicences,
                    scheduledOrNot: this.state.scheduledOrNot,
                    venues: this.state.venues,
                },
                loading: loading,
                url: routes.events.index.path,
            };

        return (
            <div>
                <HeaderRenderer
                    buttons={EventsIndexButtons}
                    buttonsProps={{
                        addEventHandler: this.addEventHandler,
                        defaultProps: EventsIndex.defaultProps.filters,
                        excelExport: eventsIndexExportExcel(this.state.eventScheduleTableData, this.state.invoiceableProductsForExcelExport),
                        loading: loading,
                    }}
                    defaultProps={EventsIndex.defaultProps.filters}
                    filters={EventScheduleFilters}
                    filtersButtonName='EventSchedule'
                    filtersProps={filtersProps}
                    messagesBoxNames='eventsIndex'
                    pageTitle='Event scheduling'
                />
                <div className='table-container'>
                    <EventScheduleTable
                        eventSchedule={this.state.eventScheduleTableData}
                        getSelectedElements={this.getSelectedElements}
                        loading={loading}
                    />
                </div>
            </div>
        );
    }
}

export const EventScheduleFiltersQuery = gql(eventGraphQl.eventScheduleFiltersQuery);
export const EventScheduleQuery = gql(eventGraphQl.eventScheduleQuery);

const EventScheduleWithQuery = compose(
    graphql(EventScheduleFiltersQuery, {
        name: 'DataEventScheduleFilters',
        options: () => {
            return {
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
            };
        },
    }),
    graphql(EventScheduleQuery, {
        name: 'DataEventSchedule',
        skip: (props) => skipSearch(props),
        options: (props) => {
            let defaultFilters = EventsIndex.defaultProps.filters,
                filters = props.filters;

            let awayCompetitor = null,
                hasLiveScout = null,
                hasLiveOdds = null,
                homeCompetitor = null,
                hasUnderageCompetitors = null,
                isCancelled = null,
                isPostponed = null,
                isWalkover = null,
                isInterrupted = null,
                isSuspended = null,
                isAbandoned = null,
                isRetired = null,
                rtsCoverage = null,
                isTvStreaming = null,
                hasBlacklistedCompetitor = null;

            const getMatchPropertiesFilterByName = (name, matchProperties) => {
                let properties = matchProperties.filter((property) => (name === property.split('_')[0]));

                return (1 === properties.length) ? convertToInt(properties[0].split('_')[1]) : null;
            };

            if (props.filters.matchProperties) {
                awayCompetitor = getMatchPropertiesFilterByName('awayCompetitor', props.filters.matchProperties);
                hasLiveScout = getMatchPropertiesFilterByName('hasLiveScout', props.filters.matchProperties);
                hasLiveOdds = getMatchPropertiesFilterByName('hasLiveOdds', props.filters.matchProperties);
                homeCompetitor = getMatchPropertiesFilterByName('homeCompetitor', props.filters.matchProperties);
                hasUnderageCompetitors = getMatchPropertiesFilterByName('hasUnderageCompetitors', props.filters.matchProperties);
                isCancelled = getMatchPropertiesFilterByName('isCancelled', props.filters.matchProperties);
                isPostponed = getMatchPropertiesFilterByName('isPostponed', props.filters.matchProperties);
                isWalkover = getMatchPropertiesFilterByName('isWalkover', props.filters.matchProperties);
                isInterrupted = getMatchPropertiesFilterByName('isInterrupted', props.filters.matchProperties);
                isSuspended = getMatchPropertiesFilterByName('isSuspended', props.filters.matchProperties);
                isAbandoned = getMatchPropertiesFilterByName('isAbandoned', props.filters.matchProperties);
                isRetired = getMatchPropertiesFilterByName('isRetired', props.filters.matchProperties);
                rtsCoverage = getMatchPropertiesFilterByName('rtsCoverage', props.filters.matchProperties);
                isTvStreaming = getMatchPropertiesFilterByName('isTvStreaming', props.filters.matchProperties);
                hasBlacklistedCompetitor = getMatchPropertiesFilterByName('hasBlacklistedCompetitor', props.filters.matchProperties);
            }

            const returnBoolOrDefaultValue = (variable, defaultValue = null) => {
                return (null !== variable && undefined !== variable)
                    ? !!variable
                    : defaultValue;
            };

            const returnCustomOrDefaultFilters = (filter) => {
                return filters[filter] || defaultFilters[filter];
            };

            return {
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                variables: {
                    awayCompetitor: returnBoolOrDefaultValue(awayCompetitor),
                    category: returnCustomOrDefaultFilters('category'),
                    clipProvider: _isEmpty(returnCustomOrDefaultFilters('coverage'))
                        ? [] : returnCustomOrDefaultFilters('clipProvider'),
                    contentCategoryLevel1: returnCustomOrDefaultFilters('contentCategoryLevel1'),
                    contentCategoryLevel2: returnCustomOrDefaultFilters('contentCategoryLevel2'),
                    contentCategoryLevel3: returnCustomOrDefaultFilters('contentCategoryLevel3'),
                    contributionType: returnCustomOrDefaultFilters('contributionType'),
                    encodingDatacenter: returnCustomOrDefaultFilters('encodingDatacenter'),
                    eventContentTypes: returnCustomOrDefaultFilters('eventContentTypes'),
                    eventContentVariants: returnCustomOrDefaultFilters('eventContentVariants'),
                    eventPreset: returnCustomOrDefaultFilters('eventPreset'),
                    eventStatus: returnCustomOrDefaultFilters('eventStatus'),
                    eventType: returnCustomOrDefaultFilters('eventType'),
                    from: filters.from ? `${filters.from}:00` : `${defaultFilters.from}:00`,
                    hasBlacklistedCompetitor: returnBoolOrDefaultValue(
                        hasBlacklistedCompetitor,
                        defaultFilters.hasBlacklistedCompetitor
                    ),
                    hasEventReport: returnBoolOrDefaultValue(filters.hasEventReport, defaultFilters.hasEventReport),
                    hasLiveScout: returnBoolOrDefaultValue(hasLiveScout),
                    hasLiveOdds: returnBoolOrDefaultValue(hasLiveOdds),
                    hasMatchId: returnBoolOrDefaultValue(filters.hasMatchId, defaultFilters.hasMatchId),
                    homeCompetitor: returnBoolOrDefaultValue(homeCompetitor),
                    hasUnderageCompetitors: returnBoolOrDefaultValue(hasUnderageCompetitors),
                    isCancelled: returnBoolOrDefaultValue(isCancelled),
                    isPostponed: returnBoolOrDefaultValue(isPostponed),
                    isWalkover: returnBoolOrDefaultValue(isWalkover),
                    isInterrupted: returnBoolOrDefaultValue(isInterrupted),
                    isSuspended: returnBoolOrDefaultValue(isSuspended),
                    isAbandoned: returnBoolOrDefaultValue(isAbandoned),
                    isRetired: returnBoolOrDefaultValue(isRetired),
                    product: returnCustomOrDefaultFilters('product'),
                    coverage: returnCustomOrDefaultFilters('coverage'),
                    productStatus: returnCustomOrDefaultFilters('productStatus'),
                    propertyLicence: returnCustomOrDefaultFilters('propertyLicence'),
                    rtsCoverage: returnBoolOrDefaultValue(rtsCoverage),
                    isTvStreaming: returnBoolOrDefaultValue(isTvStreaming),
                    scheduled: returnBoolOrDefaultValue(filters.scheduled, defaultFilters.scheduled),
                    search: returnCustomOrDefaultFilters('search'),
                    sport: returnCustomOrDefaultFilters('sport'),
                    tvChannels: returnCustomOrDefaultFilters('tvChannels'),
                    stadium: returnCustomOrDefaultFilters('stadium'),
                    to: filters.to ? `${filters.to}:00` : `${defaultFilters.to}:00`,
                    tournament: returnCustomOrDefaultFilters('tournament'),
                    venue: returnCustomOrDefaultFilters('venue'),
                },
            };
        },
    })
)(FilterQueryWrapper(EventsIndex, {queryForRefresh: 'DataEventSchedule', filterUrls: ['events.index']}));

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'EventSchedule', EventsIndex.defaultProps.filters),
    modal: state.modal,
});

const mapDispatchToProps = mapModulesToProps(['Menu', 'MessageBox', 'Modal', 'Filters']);

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(EventScheduleWithQuery, EventsIndex.defaultProps.filters, 'events.index')
)));
