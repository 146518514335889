import React from 'react';
import {Icon} from 'semantic-ui-react';

import * as RESOURCES from '@constants/resources';

import {DEBUG_PLATFORM_LINK, VIDEO_MANAGEMENT_PLATFORM_LINK} from './variables';

const navigation = {
    liveControl: {
        title: 'Live Control',
        icon: 'sliders',
        key: 'liveControl',
        path: '/live-control',
        resource: RESOURCES.LIVE_CONTROL,
    },
    events_scheduling: {
        title: 'Event scheduling',
        icon: 'calendar alternate outline',
        key: 'events_scheduling',
        path: '/events',
        resource: RESOURCES.EVENT,
    },
    lcrChannels: {
        title: 'LCR',
        icon: <Icon className='navigation__icon custom-icon-lcr' />,
        key: 'lcrChannels',
        resources: [
            RESOURCES.LCR_CHANNEL,
            RESOURCES.WNS_STREAM,
        ],
        submenu: {
            channels: {
                path: '/lcr/channels',
                title: 'Channels',
                resource: RESOURCES.LCR_CHANNEL,
            },
            wnsStreams: {
                path: '/wns-streams',
                title: 'World Number Service streams',
                resource: RESOURCES.WNS_STREAM,
            },
        },
    },
    mediaRights: {
        title: 'Media rights',
        icon: 'briefcase',
        key: 'mediaRights',
        resources: [
            RESOURCES.PROPERTY_LICENCE,
            RESOURCES.LICENSOR,
            RESOURCES.LICENSEE,
            RESOURCES.CONTENT_CATEGORY,
            RESOURCES.BLACKOUT_ZONE,
            RESOURCES.COUNTRY,
        ],
        submenu: {
            property_licences: {
                path: '/property-licences',
                title: 'Property licences',
                resource: RESOURCES.PROPERTY_LICENCE,
            },
            licensors: {
                path: '/licensors',
                title: 'Licensors',
                resource: RESOURCES.LICENSOR,
            },
            licensees: {
                path: '/licensees',
                title: 'Licensees',
                resource: RESOURCES.LICENSEE,
            },
            content_categories: {
                path: '/content-categories',
                title: 'Content categories',
                resource: RESOURCES.CONTENT_CATEGORY,
            },
            blackoutZones: {
                path: '/blackout-zones',
                title: 'Blackout Zones',
                resource: RESOURCES.BLACKOUT_ZONE,
            },
            geoData: {
                path: '/countries',
                title: 'Geo data',
                resource: RESOURCES.COUNTRY,
            },
        },
    },
    clients: {
        title: 'Clients',
        icon: 'users',
        key: 'clients',
        path: '/clients',
        resource: RESOURCES.CLIENT,
    },
    eventAdministration: {
        title: 'Event administration',
        icon: 'list alternate outline',
        key: 'eventAdministration',
        resources: [
            RESOURCES.PRODUCTION_COMPANY,
            RESOURCES.AV_COMPETITOR,
            RESOURCES.VENUE,
            RESOURCES.EVENT_CONTENT_TYPE,
            RESOURCES.PRODUCT,
        ],
        submenu: {
            production_companies: {
                path: '/production-companies',
                title: 'Production companies',
                resource: RESOURCES.PRODUCTION_COMPANY,
            },
            competitors: {
                path: '/competitors',
                title: 'Competitors',
                resource: RESOURCES.AV_COMPETITOR,
            },
            venues: {
                path: '/venues',
                title: 'Venues',
                resource: RESOURCES.VENUE,
            },
            event_content_types: {
                path: '/event-content-types',
                title: 'Event content types',
                resource: RESOURCES.EVENT_CONTENT_TYPE,
            },
            clip_configuration: {
                path: '/clip-configuration',
                title: 'Clip configuration',
                resource: RESOURCES.CLIP_CONFIGURATION,
            },
            products: {
                path: '/products',
                title: 'Products',
                resource: RESOURCES.PRODUCT,
            },
        },
    },
    infrastructure: {
        title: 'Infrastructure',
        icon: 'sitemap',
        key: 'infrastructure',
        resources: [
            RESOURCES.RTMP_ORIGIN_ENTRY_POINT,
            RESOURCES.SDI_ORIGIN_ENTRY_POINTS,
            RESOURCES.UDP_ORIGIN_ENTRY_POINT,
            RESOURCES.SRT_ORIGIN_ENTRY_POINT,
            RESOURCES.MEDIA_CONNECT_ORIGIN_ENTRY_POINT,
            RESOURCES.AD_SPLICE_CONFIG,
            RESOURCES.ENCODING_JOB_PROFILE,
        ],
        submenu: {
            rtmp_origin_entry_point: {
                path: '/rtmp-origin-entry-points',
                title: 'RTMP origin entry points',
                resource: RESOURCES.RTMP_ORIGIN_ENTRY_POINT,
            },
            sdi_origin_entry_points: {
                path: '/sdi-origin-entry-points',
                title: 'SDI origin entry points',
                resource: RESOURCES.SDI_ORIGIN_ENTRY_POINTS,
            },
            udp_origin_entry_points: {
                path: '/udp-origin-entry-points',
                title: 'UDP origin entry points',
                resource: RESOURCES.UDP_ORIGIN_ENTRY_POINT,
            },
            srt_origin_entry_points: {
                path: '/srt-origin-entry-points',
                title: 'SRT origin entry points',
                resource: RESOURCES.SRT_ORIGIN_ENTRY_POINT,
            },
            mediaconnect_origin_entry_point: {
                path: '/mediaconnect-origin-entry-points',
                title: 'MediaConnect origin entry points',
                resource: RESOURCES.MEDIA_CONNECT_ORIGIN_ENTRY_POINT,
            },
            ad_splice_config: {
                path: '/ad-splice-configs',
                title: 'Ad splice configs',
                resource: RESOURCES.AD_SPLICE_CONFIG,
            },
            encodingJobProfiles: {
                path: '/encoding-job-profiles',
                title: 'Encoding job profiles',
                resource: RESOURCES.ENCODING_JOB_PROFILE,
            },
        },
    },
    reportingCenter: {
        title: 'Reporting center',
        icon: 'file text',
        key: 'reportingCenter',
        resources: [
            RESOURCES.CHANGELOG,
        ],
        submenu: {
            changelog: {
                path: '/changelog',
                title: 'Changelog',
                resource: RESOURCES.CHANGELOG,
            },
        },
    },
    tools: {
        title: 'Tools',
        icon: 'wrench',
        key: 'tools',
        resources: [
            RESOURCES.BLACKLISTED_IP,
        ],
        submenu: {
            ipBlacklisting: {
                path: '/ip-blacklisting',
                title: 'IP blacklisting',
                resource: RESOURCES.BLACKLISTED_IP,
            },
        },
    },
    settings: {
        title: 'Settings',
        icon: 'cog',
        key: 'settings',
        resources: [
            RESOURCES.USER,
            RESOURCES.ROLE,
        ],
        submenu: {
            users: {
                title: 'Users',
                path: '/users',
                resource: RESOURCES.USER,
            },
            roles: {
                title: 'Roles',
                path: '/roles',
                resource: RESOURCES.ROLE,
            },
        },
    },
    externalLinks: {
        title: 'External links',
        icon: 'external',
        key: 'externalLinks',
        submenu: {
            live_data_client: {
                path: 'https://livedataclient.betradar.com/smp/bc',
                title: 'Live Data Client',
                external: true,
            },
            smp: {
                path: 'https://www.sportsdata.ag',
                title: 'SMP',
                external: true,
            },
            video_management_platform: {
                path: VIDEO_MANAGEMENT_PLATFORM_LINK,
                title: 'Video Management Platform',
                external: true,
            },
            stream_debug_platform: {
                path: DEBUG_PLATFORM_LINK,
                title: 'Stream Debug Platform',
                external: true,
            },
            debug_player: {
                path: process.env.REACT_APP_DEBUG_PLAYER_PATH,
                title: 'Debug Player',
                external: true,
            },
            av_analytics: {
                path: 'https://ava.sportradar.com',
                title: 'AV-Analytics',
                external: true,
            },
            spott: {
                path: 'https://spott2.sportradar.com',
                title: 'SpOTT',
                external: true,
            },
            bcms: {
                path: 'https://lbc.betradar.com',
                title: 'BCMS',
                external: true,
            },
            grafana_av_cmp: {
                path: 'https://grafana.sportradar.ag/d/Nf-W6mnZk/av-cmp-general',
                title: 'Grafana AV-CMP',
                external: true,
            },
            grafana_av_api: {
                path: 'https://grafana.sportradar.ag/d/U2KJunnZz/av-api-general',
                title: 'Grafana AV-API',
                external: true,
            },
            prod: {
                path: 'https://avcmp.sportradar.com',
                title: 'AV-CMP Production',
                external: true,
            },
            stg: {
                path: 'https://stg.avcmp.sportradar.online',
                title: 'AV-CMP Staging',
                external: true,
            },
            dev: {
                path: 'https://dev.avcmp.sportradar.online',
                title: 'AV-CMP Dev',
                external: true,
            },
        },
    },
};

export default navigation;
