import React from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
    get as _get, intersectionBy as _intersectionBy,
    sortBy as _sortBy,
    isEqual as _isEqual,
} from 'lodash';

import {convertToInt} from '@utils/helpers';
import Link from '@appComponents/Link';
import Form from '@appComponents/ReduxFormControls';

export class renderPropertyLicenceField extends React.Component{
    static propTypes = {
        input: PropTypes.object,
        defaultValue: PropTypes.string,
        options: PropTypes.array,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        let value = props.input.value ? props.input.value : props.defaultValue;

        this.state = {
            value: value
        }
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.options.length === 1 && !nextProps.input.value) {
            let value = nextProps.options[0].value,
                onChangeCallback = _get(nextProps, "onChangeSelect", () => {});

            !nextProps.disabled && onChangeCallback(null, {name: nextProps.input.name, value: value});

            return {
                value: value,
            };

        } else if (nextProps.defaultValue !== nextProps.input.value) {
            return {
                value: nextProps.defaultValue
            }
        }

        return null;
    }

    render() {
        let value = (this.props.input.value || this.state.value);

        return  <div className="formRow --propertyLicenceRowInput">
            <Form.SemanticSelect {...this.props} defaultValue={value} type="select"/>
            { value && <Popup
                content="See the property licence"
                position="right center"
                trigger={(
                    <span className="link">
                        <Link name="propertyLicences.edit" newWindow={true} params={{id: value}}>
                            <Icon name="external"/>
                        </Link>
                    </span>
                )}
            />
            }
        </div>
    }
}

export function renderEventPresetDropdown(data) {
    let selectProps = Object.assign({}, data);

    selectProps.defaultValue = null;

    let options = _sortBy(data.options, (item) => item.text.toLocaleLowerCase());

    return (
        <div className="formRow --propertyLicenceRowInput">
            <Form.SemanticSelect {...selectProps} options={options} type="select"/>
            { data.options.length > 0 && <Popup
                content="See the list of event presets for the selected property licence"
                position="right center"
                trigger={(
                    <span className="link">
                        <Link name="propertyLicences.eventPresets.index" newWindow={true} params={{
                            id: _get(data, "formValues.property_licence", "")
                        }}>
                            <Icon name="external"/>
                        </Link>
                    </span>
                )}
            />
            }
        </div>
    )
}

export function prefillCoverage(isCoverageFieldHidden, presetProducts, Model, setField, state, updateState) {
    if (!isCoverageFieldHidden) {
        const availableCoverageProducts = Model.fields.coverage.props.options;
        let commonProducts = _intersectionBy(availableCoverageProducts, presetProducts, product => convertToInt(product.id));

        if (JSON.stringify(commonProducts) !== JSON.stringify(state.commonProducts)) {
            updateState({ commonProducts: commonProducts });

            setField('coverage', {
                defaultValue: 0 < commonProducts.length ? commonProducts.map(product => convertToInt(product.value)) : [],
                timestamp: Date.now(),
            });
        }
    }
}

export const transformProductsForCoverageDropdown = (products) => {
    return products.map(product => ({
        key: product.id,
        value: product.id,
        text: product.short_name,
        id: product.id,
        name: product.short_name,
    }));
};

export const getClipProviderFromPropertyLicence = (rightScopes, products) => {
    for (const event of rightScopes) {
        const scopes = event.right_scopes_for_event || event.right_scopes_for_match;

        if (scopes) {
            for (const scope of scopes) {
                if (scope.clip_provider) {
                    const hasMatchingProduct = scope.usages.some(usage =>
                        products.some(product => product.id === usage.product.id)
                    );

                    if (hasMatchingProduct) {
                        return scope.clip_provider.id;
                    }
                }
            }
        }
    }

    return null;
};

export function updateClipProviderFieldBasedOnPreset(props, prevFormValues, setField, state, presetsListObject = {}) {
    const {coverage, event_preset, clip_provider} = props.formValues;
    const shouldClipProviderBeHidden = 0 === coverage.length;

    if (!_isEqual(props.formValues.coverage, prevFormValues.coverage)) {
        setField('clip_provider', {hidden: shouldClipProviderBeHidden});

        if (shouldClipProviderBeHidden) {
            setField('clip_provider', { defaultValue: null });
        }
    }

    if (event_preset !== prevFormValues.event_preset) {
        const clipProviderFromGraphQL =
            props.GraphQLOptionsData?.eventPresets?.find(preset => preset.id === event_preset)?.event?.clip_provider?.id;
        const clipProviderFromState =
            Object.values(presetsListObject)?.find(preset => preset.id === event_preset)?.event?.clip_provider?.id;
        let clipProviderFormPropertyLicence = null;

        if (!props.formValues.id) {
            if (state.rightsScopes && state.coverageProducts) {
                clipProviderFormPropertyLicence = getClipProviderFromPropertyLicence(state.rightsScopes, state.coverageProducts);
            }

            if (state?.coverageOptions) {
                clipProviderFormPropertyLicence = state?.coverageOptions?.[0]?.providers?.[0]?.toString()
            }
        }

        const clipProviderId = clipProviderFromGraphQL || clipProviderFromState || clipProviderFormPropertyLicence || null;

        if (clipProviderId && clipProviderId !== clip_provider) {
            setField('clip_provider', {
                defaultValue: clipProviderId,
            });
        }
    }
}

/**
 * Hide all information about match details
 * and hide checkbox
 * @param setField
 * @param setState
 * @param message
 * @param isLookingForMatch
 */

export const clearMatchDetailsAndDisableLinking = (setField, setState, message, isLookingForMatch = false) => {
    const matchNotFound = {isMatchFound: false};

    setField(['start_time', 'competitor', 'tournament', 'court_venue', 'tv_channels'], {
        values: matchNotFound,
    });

    setField('synchronise_times', {hidden: true});

    setField('message', {
        values: {
            ...matchNotFound,
            message: message,
            loading: isLookingForMatch,
        },
    });

    setState(() => ({
        isLinkingDisabled: true,
    }));
};
