import {isEqual as _isEqual, uniq as _uniq, uniqWith as _uniqWith} from 'lodash';

import {
    COMPETITOR_NOT_DEFINED,
    COMPETITOR_NOT_DEFINED_BOTH,
    EVENT_NON_SPORTRADAR_TYPE,
    EVENT_SPORTRADAR_TYPE,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {mapPropertyLicencesForDropdown} from '@utils/propertyLicence';

export const getCompetitors = (data) => {
    let competitors = [],
        defaultCompetitors = COMPETITOR_NOT_DEFINED_BOTH;

    if (data.match_id) {
        competitors = getCompetitorsByMatch(data);
    } else if (
        EVENT_SPORTRADAR_TYPE === data.event_type
        && (null !== data.sr_event_home_competitor || null !== data.sr_event_away_competitor)
    ) {
        competitors.push(getCompetitorsByField(data, 'sr_event_home_competitor'));
        competitors.push(getCompetitorsByField(data, 'sr_event_away_competitor'));
    } else if (
        EVENT_NON_SPORTRADAR_TYPE === data.event_type
        && (null !== data.av_event_home_competitor || null !== data.av_event_away_competitor)
    ) {
        competitors.push(getCompetitorsByField(data, 'av_event_home_competitor'));
        competitors.push(getCompetitorsByField(data, 'av_event_away_competitor'));
    }

    if (1 === _uniqWith(competitors, _isEqual).length && COMPETITOR_NOT_DEFINED === competitors[0]) {
        competitors = [];
    }

    return (0 < competitors.length) ? (competitors.join(' : ')) : defaultCompetitors;
};

export const getCompetitorsByMatch = (data) => {
    let competitors = [];

    competitors.push(getCompetitorsByField(data, 'match_home_competitor'));
    competitors.push(getCompetitorsByField(data, 'match_away_competitor'));

    return competitors;
};

export const  getCompetitorsByField = (data, competitorField) => {
    if (null !== competitorField && data[competitorField]) {
        return data[competitorField];
    } else {
        return COMPETITOR_NOT_DEFINED;
    }
};

export const getContentTierName = (offering) => {
    if (null !== offering.av_event_content_tier_name) {
        return offering.av_event_content_tier_name;
    } else if (null !== offering.sr_event_content_tier_name) {
        return offering.sr_event_content_tier_name;
    } else if (null !== offering.match_content_tier_name) {
        return offering.match_content_tier_name;
    }

    return null;
};

export const createOfferingForTable = (
    index, offering, geoRestrictions = [], hasMultipleGeoRestrictions = false
) => ({
    ...offering,
    id: index,
    content_category_level_1_name: offering.content_category_level_1_name,
    content_category_level_2_name: offering.content_category_level_2_name,
    content_category_level_3_name: offering.content_category_level_3_name,
    content_tier_name: getContentTierName(offering),
    competitors: getCompetitors(offering),
    offering_geo_restrictions: geoRestrictions,
    has_multiple_geo_restrictions: hasMultipleGeoRestrictions,
});

export const updatePropertyLicences = (propertyLicences, prevState) => {
    if (propertyLicences && prevState.propertyLicencesOriginal !== propertyLicences) {
        return {
            propertyLicencesOriginal: propertyLicences,
            propertyLicences: mapPropertyLicencesForDropdown(propertyLicences),
        };
    }

    return {};
};

export const updateEventTypes = (eventTypes, prevState) => {
    if (eventTypes && prevState.eventTypesOriginal !== eventTypes) {
        return {
            eventTypesOriginal: eventTypes,
            eventTypes: eventTypes.map((eventType) => ({
                key: eventType.key,
                text: eventType.text,
                value: convertToInt(eventType.value),
            })),
        };
    }

    return {};
};

export const updateClientOfferings = (clientOfferings, prevState) => {
    if (clientOfferings && prevState.clientOfferingsOriginal !== clientOfferings) {
        let resultOfferings = [];

        clientOfferings.forEach((offering, indexOffering) => {
            if (1 === offering.offering_geo_restrictions.length
                && null !== offering.offering_geo_restrictions[0].country_iso_alpha2_codes
            ) {
                resultOfferings.push(createOfferingForTable(
                    indexOffering,
                    offering,
                    [offering.offering_geo_restrictions[0]]
                ));
            } else if (
                0 === offering.offering_geo_restrictions.length
                || null === offering.offering_geo_restrictions[0].country_iso_alpha2_codes
            ) {
                resultOfferings.push(createOfferingForTable(indexOffering, offering));
            } else {
                const allCountries = offering.offering_geo_restrictions.map(el =>
                        el.country_iso_alpha2_codes
                    ).reduce((prev, curr) => (prev.concat(curr))).sort(),
                    allBlackoutZones = offering.offering_geo_restrictions
                        .flatMap(el => el.blackout_zones)
                        .map(bz => bz.id);

                const uniqueCountries = _uniq(allCountries),
                    uniqueBlackoutZones = _uniq(allBlackoutZones),
                    deviceCategoriesCount = offering.offering_geo_restrictions.length;

                if (uniqueCountries.length === (allCountries.length / deviceCategoriesCount)
                    && uniqueBlackoutZones.length === (allBlackoutZones.length / deviceCategoriesCount)
                ) {
                    const deviceCategoryName = offering.offering_geo_restrictions.map(
                        (geoRestriction) => geoRestriction.device_category.name
                    ).join(', ');

                    let geoRestriction = {
                        ...offering.offering_geo_restrictions[0],
                        device_category: {name: deviceCategoryName},
                    };

                    if (null !== offering.offering_geo_restrictions[0].country_iso_alpha2_codes) {
                        resultOfferings.push(createOfferingForTable(
                            indexOffering,
                            offering,
                            [geoRestriction]
                        ));
                    } else {
                        resultOfferings.push(createOfferingForTable(indexOffering, offering));
                    }
                } else {
                    let geoRestrictions = [];

                    offering.offering_geo_restrictions.forEach((geoRestriction) => {
                        geoRestrictions.push(geoRestriction);
                    });

                    resultOfferings.push(createOfferingForTable(
                        indexOffering,
                        offering,
                        geoRestrictions,
                        true
                    ));
                }
            }
        });

        return {
            clientOfferingsOriginal: clientOfferings,
            clientOfferings: resultOfferings,
        };
    }

    return {};
};
