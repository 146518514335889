import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Header, Segment} from 'semantic-ui-react';
import {gql} from '@apollo/client';

import {graphql} from 'react-apollo';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getLink} from '@appComponents/Link';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import StepsContent from '@appComponents/StepsContent';
import {routes} from '@constants/routes';
import mapModulesToProps from '@utils/mapModulesToProps';
import {convertToInt,isUrlParamValid, digitsConnectedByPlus} from '@utils/helpers';
import {createForm, renderModalError} from '@utils/forms';
import {convertBooleanValueForFilters, getSearchFilters} from '@utils/filters';
import {clientBookingsForTable} from '@graphql/clients/query';
import {bulkChangeClientBookings} from '@graphql/clients/mutation';
import {dropDownListClientBookingsQuery} from '@graphql/clients/clientBookings';
import EventBookingGeoRestrictionsBlackoutZonesEditModel
    from '@modules/events/forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsBlackoutZonesEditModel';
import EventBookingGeoRestrictionsBlackoutZonesForm
    from '@modules/events/forms/EventBookingGeoRestrictions/EventBookingGeoRestrictionsBlackoutZonesForm';
import {getBookingFormParams} from '@utils/booking';
import {showConstraintsModal, skipSearch} from '@modules/client/utils/constraintsTables';
import {getTodayDate, getTomorrowDate} from '@utils/date';
import {showModal} from '@utils/modal';
import {
    showErrorDuringBulkCancel,
    showErrorDuringBulkChange,
    showErrorSomeBookingIdsAreInvalid,
    showErrorNoBookingsSelected,
} from '@modules/client/utils/bookingsBulkChange/errorModals';
import * as MESSAGES from '@constants/messages';
import {updateStateFromProps as updateStateFromPropsUtil} from '@modules/client/utils/clientBookingsHelper';
import {adjustHeight} from '@modules/client/utils/adjustHeightHelper';

import ClientBookingGeoRestrictionsGeneralInformationEditModel
    from '../forms/ClientBookingGeoRestrictions/ClientBookingGeoRestrictionsGeneralInformationEditModel';
import ClientBookingGeoRestrictionsGeneralInformationForm
    from '../forms/ClientBookingGeoRestrictions/ClientBookingGeoRestrictionsGeneralInformationForm';
import EventBookingGeoRestrictionsStep from '../../events/views/EventBookingGeoRestrictionsStep';
import ClientBookingsTable from '../components/ClientBookingsTable';
import {ClientBookingsFiltersComponent} from '../components/ClientBookingsFilters';
import ClientBookingsButtons from '../components/ClientBookingsButtons';
import BookingBulkChangeModalContent from './Bookings/BookingBulkChangeModalContent';
import {exportExcel as clientBookingsExportExcel} from '../utils/export/clientBookingsExcel';
import client from '../../../apolloClient';

const clientBookingsIndexRoute = 'clients.bookings.index';

export class ClientBookings extends Component {
    static propTypes = {
        dataDropDown: PropTypes.object.isRequired,
        DataClientBookings: PropTypes.object,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.object.isRequired,
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
            setModalConfirmation: PropTypes.func.isRequired,
        }),
        MessageBox: PropTypes.shape({
            addMessage: PropTypes.func.isRequired,
        }),
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
        filters: PropTypes.shape({
            bookingTypes: PropTypes.arrayOf(PropTypes.number),
            clientPackages: PropTypes.arrayOf(PropTypes.number),
            isHq: PropTypes.number,
            contentCategories: PropTypes.arrayOf(PropTypes.number),
            contentTiers: PropTypes.arrayOf(PropTypes.number),
            countries: PropTypes.arrayOf(PropTypes.string),
            deviceCategories: PropTypes.arrayOf(PropTypes.number),
            distributionTypes: PropTypes.arrayOf(PropTypes.number),
            eventTypes: PropTypes.number,
            invoiceStatuses: PropTypes.arrayOf(PropTypes.number),
            products: PropTypes.arrayOf(PropTypes.number),
            propertyLicences: PropTypes.arrayOf(PropTypes.number),
            sports: PropTypes.arrayOf(PropTypes.number),
            tournamentCategories: PropTypes.arrayOf(PropTypes.number),
            tournaments: PropTypes.arrayOf(PropTypes.number),
            sport: PropTypes.arrayOf(PropTypes.number),
            eventContentTypes: PropTypes.arrayOf(PropTypes.number),
            eventContentVariants: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel1: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel2: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel3: PropTypes.arrayOf(PropTypes.number),
            from: PropTypes.string,
            to: PropTypes.string,
            isCancelled: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        }),
        loadingRefetch: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        filters: {
            bookingTypes: [],
            clientPackages: [],
            isHq: null,
            contentTiers: [],
            countries: [],
            deviceCategories: [],
            distributionTypes: [],
            eventTypes: null,
            invoiceStatuses: [],
            products: [],
            propertyLicences: [],
            sports: [],
            tournamentCategories: [],
            tournaments: [],
            eventContentTypes: [],
            eventContentVariants: [],
            contentCategoryLevel1: [],
            contentCategoryLevel2: [],
            contentCategoryLevel3: [],
            from: getTodayDate(),
            to: getTomorrowDate(),
            isCancelled: 0,
        },
    };

    constructor() {
        super();

        this.state = {
            bookingTypes: [],
            clientPackages: [],
            clientBookings: [],
            contentCategoriesLevel1: [],
            contentTiers: [],
            countries: [],
            deviceCategories: [],
            distributionTypes: [],
            eventTypes: null,
            invoiceStatuses: [],
            heightAdjusted: false,
            products: [],
            propertyLicences: [],
            propertyLicencesOriginal: [],
            sports: [],
            selectedElements: [],
            eventContentTypes: [],
            eventContentVariants: [],
        };

        this.cellRefs = [];
    }

    updateStateFromProps = (nextProps) => {
        const newState = updateStateFromPropsUtil(nextProps, this.state);
        this.setState(() => newState);
    };

    componentDidMount() {
        this.loadModal(this.props.match, this.props.match.params);

        if (skipSearch(this.props)) {
            showConstraintsModal(this.props.filters);
        }

        if (!digitsConnectedByPlus(this.props.match.params.bookingsIds)
            && this.props.match.path === routes.clients.bookings.bulkChange.path) {
            showErrorDuringBulkChange();
        }

        if (!digitsConnectedByPlus(this.props.match.params.bookingsIds)
            && this.props.match.path === routes.clients.bookings.bulkCancel.path) {
            showErrorDuringBulkCancel();
        }
    }

    componentDidUpdate(prevProps) {
        const urlChanged = this.props.match.path !== prevProps.match.path,
            urlIsNotIndex = this.props.match.path !== routes.clients.bookings.index.path,
            modalChanged = this.props.modal.isVisible !== prevProps.modal.isVisible,
            modalIsNotVisible = !this.props.modal.isVisible,
            bulkCancelPath = routes.clients.bookings.bulkCancel.path;

        if (urlChanged && urlIsNotIndex) {
            this.loadModal(this.props.match, this.props.match.params);
        }

        if (modalChanged && modalIsNotVisible) {
            this.props.history.push(
                getLink(clientBookingsIndexRoute, {
                    id: this.props.match.params.id,
                })
            );
        }

        if (!this.state.heightAdjusted) {
            let res = adjustHeight(this.cellRefs);

            if (res) {
                this.setState(() => ({heightAdjusted: true}));
            }
        }

        if (this.props.match.path === bulkCancelPath
            && this.props.DataClientBookings.clientBookings !== prevProps.DataClientBookings.clientBookings) {
            this.loadModal(this.props.match, this.props.match.params);
        }

        if (this.props.dataDropDown !== prevProps.dataDropDown
            || this.props.DataClientBookings !== prevProps.DataClientBookings) {
            this.updateStateFromProps(this.props);
        }
    }

    setCellRef = (el) => {
        if (null !== el) {
            this.cellRefs.push(el.firstChild);
        }
    };

    loadModal(match, params = {}) {
        const bookingsPath = routes.clients.bookings,
            path = match.path;

        if (path === bookingsPath.edit.path
            || path === bookingsPath.editGeoRestrictions.path
            || path === bookingsPath.blackoutZones.path
        ) {
            this.showBookingEdit(params);
        }

        if (path === bookingsPath.bulkChange.path) {
            const bookingsIds = match.params.bookingsIds.split('+')
                .map((bookingId) => convertToInt(bookingId));

            this.showBulkChange(bookingsIds);
        }

        if (path === bookingsPath.bulkCancel.path && 0 < this.state.clientBookings.length) {
            const bookingsIds = match.params.bookingsIds.split('+')
                .map((bookingId) => convertToInt(bookingId));

            this.showBulkCancelModal(bookingsIds);
        }
    }

    showBookingEdit = (params) => {
        const [formParamsObject, linkParams] = getBookingFormParams(
            this.props.match.params.id,
            this.props.match.params.bookingId,
            params
        );

        return this.loadEditModalForm(
            {
                tabs: [
                    {
                        key: 'step1',
                        menuItem: {content: 'General information', disabled: false},
                        pane: {
                            content: createForm(
                                ClientBookingGeoRestrictionsGeneralInformationEditModel,
                                ClientBookingGeoRestrictionsGeneralInformationForm,
                                formParamsObject
                            ),
                        },
                        url: getLink('clients.bookings.edit', linkParams),
                    },
                    {
                        key: 'step2',
                        menuItem: {content: 'Geo restrictions', disabled: false},
                        pane: {
                            content: <EventBookingGeoRestrictionsStep
                                bookingId={convertToInt(this.props.match.params.bookingId)}
                            />,
                        },
                        url: getLink('clients.bookings.editGeoRestrictions', linkParams),
                    },
                    {
                        key: 'step3',
                        menuItem: {content: 'Blackout Zones', disabled: false },
                        pane: {
                            content: createForm(
                                EventBookingGeoRestrictionsBlackoutZonesEditModel,
                                EventBookingGeoRestrictionsBlackoutZonesForm,
                                formParamsObject
                            ),
                        },
                        url: getLink('clients.bookings.blackoutZones', linkParams),
                    },
                ],
            },
            formParamsObject
        );
    };

    loadEditModalForm = (Forms) => {
        if (!isUrlParamValid(this.props.match.params.bookingId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Booking', getLink('clients.bookings.index', {
                    id: this.props.match.params.id,
                })),
            });
        }

        this.props.Modal.setModal({
            content: <StepsContent
                title={'Edit booking'}
                activeStep={Forms.active}
                tabs={Forms.tabs}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    showBulkChange = (bookingsIds = []) => {
        if (0 === bookingsIds.length) {
            showErrorNoBookingsSelected();

            return;
        }

        this.props.Modal.setModal({
            content: <BookingBulkChangeModalContent
                clientId={convertToInt(this.props.match.params.id)}
                bookingsIds={bookingsIds}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    showBulkCancelModal = (bookingsIds = []) => {
        const allClientBookingsIds = this.state.clientBookings.map(booking => convertToInt(booking.booking_id));

        if (0 === bookingsIds.length) {
            showErrorNoBookingsSelected(true);

            return;
        }

        if (!bookingsIds.every(id => allClientBookingsIds.includes(id))) {
            showErrorSomeBookingIdsAreInvalid();

            return;
        }

        this.props.Modal.setModalConfirmation({
            header: <Header content='Cancel bookings'/>,
            text: MESSAGES.CANCEL_BULK_CLIENT_BOOKINGS(bookingsIds.length),
            size: 'tiny',
            onYes: () => {
                client.mutate({
                    mutation: bulkChangeClientBookings,
                    variables: {
                        bookings: bookingsIds,
                    },
                }).then(() => {
                    this.props.MessageBox.addMessage(
                        'clientMessage',
                        'The selected bookings have been cancelled successfully.',
                        null,
                        'success'
                    );
                    this.props.DataClientBookings.refetch();
                }).catch((error) => {
                    if (504 === error.networkError.statusCode) {
                        this.props.MessageBox.addMessage(
                            'clientMessage',
                            'Due to big data set processing might take more time.\n' +
                            'Please wait couple minutes and refresh to check if cancellation succeeded..',
                            null,
                            'info',
                            true
                        );
                    } else {
                        this.props.MessageBox.addMessage(
                            'clientMessage',
                            'One or more bookings could not be cancelled.',
                            null,
                            'error'
                        );
                    }
                }).finally(() => this.props.Modal.setModal({isVisible: false}));
            },
        });
    };

    onClickHandler = (e) => {
        if ('bulkChange' === e.currentTarget.dataset.idkey) {
            if (0 === this.state.selectedElements.length) {
                showErrorNoBookingsSelected();

                return;
            }

            this.props.history.push(getLink('clients.bookings.bulkChange', {
                id: this.props.match.params.id,
                bookingsIds: this.state.selectedElements.join('+'),
            }));
        }

        if ('bulkCancel' === e.currentTarget.dataset.idkey) {
            if (0 === this.state.selectedElements.length) {
                showErrorNoBookingsSelected(true);

                return;
            }

            this.props.history.push(getLink('clients.bookings.bulkCancel', {
                id: this.props.match.params.id,
                bookingsIds: this.state.selectedElements.join('+'),
            }));
        }
    };

    getSelectedElements = (selectedElements) => {
        this.setState(() => ({selectedElements: selectedElements}));
    };

    render() {
        const clientId = convertToInt(this.props.match.params.id),
            loading = (_get(this.props, 'DataClientBookings.loading') || this.props.loadingRefetch),
            filtersProps = {
                additionalFilterId: this.props.match.params.id,
                data: {
                    bookingTypes: this.state.bookingTypes,
                    clientPackages: this.state.clientPackages,
                    contentCategoriesLevel1: this.state.contentCategoriesLevel1,
                    contentTiers: this.state.contentTiers,
                    countries: this.state.countries,
                    deviceCategories: this.state.deviceCategories,
                    distributionTypes: this.state.distributionTypes,
                    eventTypes: this.state.eventTypes,
                    invoiceStatuses: this.state.invoiceStatuses,
                    products: this.state.products,
                    propertyLicences: this.state.propertyLicences,
                    sports: this.state.sports,
                    eventContentTypes: this.state.eventContentTypes,
                    eventContentVariants: this.state.eventContentVariants,
                },
                loading: this.props.dataDropDown.loading,
                url: routes.clients.bookings.index.path,
            };

        return (
            <div>
                <HeaderRenderer
                    buttons={ClientBookingsButtons}
                    buttonsProps={{
                        onClickHandler: this.onClickHandler,
                        excelExport: clientBookingsExportExcel(this.state.clientBookings, clientId),
                        loading: loading,
                    }}
                    filters={ClientBookingsFiltersComponent}
                    filtersProps={filtersProps}
                />
                <Segment basic className='--table'>
                    <ClientBookingsTable
                        cellRefs={this.cellRefs}
                        clientBookings={this.state.clientBookings}
                        clientId={clientId}
                        loading={loading}
                        refProperty={this.setCellRef}
                        getSelectedElements={this.getSelectedElements}
                        url={routes.clients.bookings.index.path}
                    />
                </Segment>
            </div>
        );
    }
}

const ClientBookingsWithQuery = compose(
    graphql(clientBookingsForTable, {
        skip: (props) => {
            const route = routes.clients.bookings,
                modalPaths = [route.edit.path, route.editGeoRestrictions.path, route.bulkChange.path, route.blackoutZones.path];

            if (modalPaths.includes(props.match.path)) {
                return true;
            }

            return skipSearch(props);
        },
        options: (props) => {
            const defaultFilters = ClientBookings.defaultProps.filters;

            return {
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
                variables: {
                    client: convertToInt(props.match.params.id),
                    bookingTypes: props.filters.bookingTypes || defaultFilters.bookingTypes,
                    clientPackages: props.filters.clientPackages || defaultFilters.clientPackages,
                    isHq: convertBooleanValueForFilters(props.filters.isHq, null),
                    contentTiers: props.filters.contentTiers || defaultFilters.contentTiers,
                    countries: props.filters.countries || defaultFilters.countries,
                    deviceCategories: props.filters.deviceCategories || defaultFilters.deviceCategories,
                    distributionTypes: props.filters.distributionTypes || defaultFilters.distributionTypes,
                    eventTypes: props.filters.eventTypes || defaultFilters.eventTypes,
                    invoiceStatuses: props.filters.invoiceStatuses || defaultFilters.invoiceStatuses,
                    products: props.filters.products || defaultFilters.products,
                    propertyLicences: props.filters.propertyLicences || defaultFilters.propertyLicences,
                    sports: props.filters.sports || defaultFilters.sports,
                    startDate: props.filters.from ? `${props.filters.from}:00` : `${defaultFilters.from}:00`,
                    endDate: props.filters.to ? `${props.filters.to}:00` : defaultFilters.to,
                    tournamentCategories: props.filters.tournamentCategories || defaultFilters.tournamentCategories,
                    tournaments: props.filters.tournaments || defaultFilters.tournaments,
                    contentCategoryLevel1: props.filters.contentCategoryLevel1 || defaultFilters.contentCategoryLevel1,
                    contentCategoryLevel2: props.filters.contentCategoryLevel2 || defaultFilters.contentCategoryLevel2,
                    contentCategoryLevel3: props.filters.contentCategoryLevel3 || defaultFilters.contentCategoryLevel3,
                    isCancelled: convertBooleanValueForFilters(props.filters.isCancelled, null),
                    eventContentTypes: props.filters.eventContentTypes || defaultFilters.eventContentTypes,
                    eventContentVariants: props.filters.eventContentVariants || defaultFilters.eventContentVariants,
                },
            };
        },
        name: 'DataClientBookings',
    }),
    graphql(gql(dropDownListClientBookingsQuery), {
        options: (props) => {
            return {
                fetchPolicy: 'cache-and-network',
                notifyOnNetworkStatusChange: true,
                variables: {
                    client: [parseInt(props.match.params.id, 10)],
                },
            };
        },
        name: 'dataDropDown',
    })
)(FilterQueryWrapper(ClientBookings, {
    queryForRefresh: 'DataClientBookings',
    filterUrls: [clientBookingsIndexRoute],
}));

const mapStateToProps = (state, ownProps) => ({
    filters: getSearchFilters(state, `ClientBookings${ownProps.match.params.id}`, ClientBookings.defaultProps.filters),
    modal: state.modal,
});

const mapDispatchToProps = mapModulesToProps(['Modal', 'MessageBox']);

export default withRouter((connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(ClientBookingsWithQuery, ClientBookings.defaultProps.filters, clientBookingsIndexRoute)
)));
