import PropTypes from 'prop-types';
import React from 'react';
import {Grid, Header, Popup} from 'semantic-ui-react';

import Authorization from '@appComponents/Authorization';
import {CircleButtonAdd} from '@appComponents/ButtonCollection';
import {convertToInt} from '@utils/helpers';
import {getSuffixWithNumber} from '@utils/ordinalNumberSuffix';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';

import ContentCategoryTable from './ContentCategoryTable';

const createReadAuthorizationObject = {
    [RESOURCES.CONTENT_CATEGORY]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
};

export class ContentCategoryLevel extends React.Component {
    componentDidMount() {
        this.indexData(this.props.data);
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.indexData(this.props.data);
        }
    }

    indexData = (data) => {
        let dataValues = {};
        data.forEach((data)=> {
            dataValues[data.id] = data;
        });

        this.setState(() => ({
            dataIndexed: dataValues,
        }));
    };

    createCategory = (event) => {
        event.stopPropagation();

        let url = null;

        switch (this.props.level) {
            case 1:
                url = '/content-categories/add';
                break;
            case 2:
                url = `/content-categories/${this.props.selected.cat1}/add`;
                break;
            case 3:
                url = `/content-categories/${this.props.selected.cat1}/${this.props.selected.cat2}/add`;
                break;
        }

        this.props.editCategory({level: convertToInt(event.currentTarget.dataset.level)});
        window.history.pushState({}, '', url);
    };

    editCategory = (row) => {
        let url = null;

        switch (this.props.level) {
            case 1:
                url = `/content-categories/${row.id}/edit`;
                break;
            case 2:
                url = `/content-categories/${this.props.selected.cat1}/${row.id}/edit`;
                break;
            case 3:
                url = `/content-categories/${this.props.selected.cat1}/${this.props.selected.cat2}/${row.id}/edit`;
                break;
        }

        this.props.editCategory(this.state.dataIndexed[convertToInt(row.id)]);
        window.history.pushState({}, '', url);
    };

    render() {
        let props = {rowOnClick : this.props.rowOnClick};

        if (3 === this.props.level) {
            props = {};
        }

        return (
            <Grid.Column>
                <div className='table-header-container'>
                    <Header as='h3' className='table-header-container__header'>
                        {`${getSuffixWithNumber(this.props.level)} level categories`}
                    </Header>
                    <Authorization authorization={createReadAuthorizationObject}>
                        <Popup
                            content={`Add ${getSuffixWithNumber(this.props.level)} level category`}
                            position='bottom right'
                            trigger={
                                <CircleButtonAdd
                                    data-id={null}
                                    data-level={this.props.level}
                                    onClick={this.createCategory}
                                    className='table-header-container__button plus circle'
                                    size='large'
                                />
                            }
                        />
                    </Authorization>

                    <div className='clearfix'/>
                </div>
                <ContentCategoryTable
                    selected={3 === this.props.level ? [] : [this.props.selected[`cat${this.props.level}`]]}
                    data={this.props.data}
                    level={this.props.level}
                    loading={this.props.loading}
                    rowRendererProps={{
                        editCallback: this.editCategory,
                        deleteCallback: this.props.deleteCategory,
                    }}
                    {...props}
                />
            </Grid.Column>
        );
    }
}

ContentCategoryLevel.propTypes = {
    editCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    data: PropTypes.array,
    loading: PropTypes.bool,
    rowOnClick: PropTypes.func,
    level: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    selected: PropTypes.object,
    parent: PropTypes.number,
};

export default ContentCategoryLevel;
