import React from 'react';
import {isEmpty as _isEmpty, isUndefined as _isUndefined} from 'lodash';

import {routes} from '@constants/routes';
import {EXPORT_EXCEL_URL_FRAGMENT, PRODUCT_THIRD_PARTY} from '@constants/variables';
import {getLink} from '@appComponents/Link';
import {showErrorModal} from '@utils/modal';
import {convertToInt} from '@utils/helpers';

import * as CLIENT_TYPES from '../constants/thirdPartyClientTypes';
import ProductAdd from '../views/ProductAdd';
import ProductEdit from '../views/ProductEdit';
import ProductThirdPartyGeoRestrictionsEdit from '../views/ProductThirdPartyGeoRestrictionsEdit';
import ProductGeolocations from '../components/ProductGeolocations';
import ThirdPartyCdnsIndex from '../views/ThirdPartyCdnsIndex';
import ThirdPartyPullOriginsIndex from '../views/ThirdPartyPullOriginsIndex';
import ProductThirdPartyStreamNamesPushIndex from '../views/ProductThirdPartyStreamNamesPushIndex';
import ProductThirdPartyStreamNamesPullIndex from '../views/ProductThirdPartyStreamNamesPullIndex';
import ThirdPartyVodStoragesIndex from '../views/ThirdPartyVodStoragesIndex';
import ClientProductThirdPartyForm from '../forms/ClientProductThirdPartyForm';
import ClientProductThirdPartyModel from '../forms/ClientProductThirdPartyModel';

export const checkRouteIsAllowed = (activeRoute, props, showCdnTab, showPullOriginTab, showVodStorageTab) => {
    if (props.hasCdns === undefined || props.hasPullOrigins === undefined || undefined === props.hasVodStorages) {
        return true;
    }

    switch (activeRoute) {
        case routes.clients.products.thirdParty.cdns.index.path:
            return showCdnTab;
        case routes.clients.products.thirdParty.pullOrigins.index.path:
            return showPullOriginTab;
        case routes.clients.products.thirdParty.streamNamesPush.index.path:
            return showCdnTab && props.hasCdns;
        case routes.clients.products.thirdParty.streamNamesPull.index.path:
            return showPullOriginTab && props.hasPullOrigins;
        case routes.clients.products.thirdParty.vodStorages.index.path:
            return showVodStorageTab;
        default:
            return true;
    }
};

const createTabs = (
    props,
    showCdnTab,
    showPullOriginTab,
    showVodStorageTab,
    prepareThirdPartyClientCdnsForStore,
    prepareThirdPartyClientPullOriginsForStore,
    prepareThirdPartyClientVodStoragesForStore
) => {
    let tabs;

    if (_isEmpty(props.clientProductThirdParty)) {
        tabs = {
            [routes.clients.products.thirdParty.add.path]: {
                key: routes.clients.products.thirdParty.add.path,
                url: 'clients.products.thirdParty.add',
                urlParams: {id: props.match.params.id},
                text: 'Settings',
                render: <ProductAdd
                    form={ClientProductThirdPartyForm}
                    hasTabs={false}
                    model={ClientProductThirdPartyModel}
                    productId={PRODUCT_THIRD_PARTY}
                />,
            },
        };
    } else {
        tabs = {
            [routes.clients.products.thirdParty.edit.path]: {
                key: routes.clients.products.thirdParty.edit.path,
                url: 'clients.products.thirdParty.edit',
                urlParams: {id: props.match.params.id},
                text: 'Settings',
                render: <ProductEdit
                    form={ClientProductThirdPartyForm}
                    model={ClientProductThirdPartyModel}
                    productId={PRODUCT_THIRD_PARTY}
                />,
            },
            [routes.clients.products.thirdParty.editGeoRestrictions.path]: {
                key: routes.clients.products.thirdParty.editGeoRestrictions.path,
                url: 'clients.products.thirdParty.editGeoRestrictions',
                urlParams: {id: props.match.params.id},
                text: 'Geo restrictions',
                render: <ProductThirdPartyGeoRestrictionsEdit
                    clientProductId={convertToInt(props.clientProductThirdParty.id)}
                />,
            },
            [routes.clients.products.thirdParty.geolocation.index.path]: {
                key: routes.clients.products.thirdParty.geolocation.index.path,
                hidden: !props.clientProductThirdParty.product.has_geolocation_support,
                url: 'clients.products.thirdParty.geolocation.index',
                urlParams: {id: props.match.params.id},
                text: 'Geolocation',
                render: <ProductGeolocations
                    clientProductId={convertToInt(props.clientProductThirdParty.id)}
                    productShortName={'thirdParty'}
                />,
            },
        };

        if (showCdnTab) {
            tabs = Object.assign(tabs, {
                [routes.clients.products.thirdParty.cdns.index.path]: {
                    key: routes.clients.products.thirdParty.cdns.index.path,
                    url: 'clients.products.thirdParty.cdns.index',
                    urlParams: {id: props.match.params.id},
                    text: 'CDNs',
                    render: <ThirdPartyCdnsIndex
                        prepareThirdPartyClientCdnsForStore={prepareThirdPartyClientCdnsForStore}
                    />,
                },
            });
        }

        if (showPullOriginTab) {
            tabs = Object.assign(tabs, {
                [routes.clients.products.thirdParty.pullOrigins.index.path]: {
                    key: routes.clients.products.thirdParty.pullOrigins.index.path,
                    url: 'clients.products.thirdParty.pullOrigins.index',
                    urlParams: {id: props.match.params.id},
                    text: 'Pull origins',
                    render: <ThirdPartyPullOriginsIndex
                        clientId={convertToInt(props.match.params.id)}
                        prepareThirdPartyClientPullOriginsForStore={prepareThirdPartyClientPullOriginsForStore}
                    />,
                },
            });
        }

        if (showCdnTab && props.hasCdns) {
            tabs = Object.assign(tabs, {
                [routes.clients.products.thirdParty.streamNamesPush.index.path]: {
                    key: routes.clients.products.thirdParty.streamNamesPush.index.path,
                    url: 'clients.products.thirdParty.streamNamesPush.index',
                    urlParams: {id: props.match.params.id},
                    text: 'Stream names (push)',
                    render: <ProductThirdPartyStreamNamesPushIndex/>,
                },
            });
        }

        if (showPullOriginTab && props.hasPullOrigins) {
            tabs = Object.assign(tabs, {
                [routes.clients.products.thirdParty.streamNamesPull.index.path]: {
                    key: routes.clients.products.thirdParty.streamNamesPull.index.path,
                    url: 'clients.products.thirdParty.streamNamesPull.index',
                    urlParams: {id: props.match.params.id},
                    text: 'Stream names (pull)',
                    render: <ProductThirdPartyStreamNamesPullIndex/>,
                },
            });
        }

        if (showVodStorageTab) {
            tabs = Object.assign(tabs, {
                [routes.clients.products.thirdParty.vodStorages.index.path]: {
                    key: routes.clients.products.thirdParty.vodStorages.index.path,
                    url: 'clients.products.thirdParty.vodStorages.index',
                    urlParams: {id: props.match.params.id},
                    text: 'VOD storages',
                    render: <ThirdPartyVodStoragesIndex
                        clientId={convertToInt(props.match.params.id)}
                        prepareThirdPartyClientVodStoragesForStore={prepareThirdPartyClientVodStoragesForStore}
                    />,
                },
            });
        }
    }

    return tabs;
};

const activeRoute = (props) => {
    let active = props.match.path;
    const productsThirdPartyRoutes = routes.clients.products.thirdParty;

    if (
        active === productsThirdPartyRoutes.cdns.index.path
        || active === productsThirdPartyRoutes.cdns.add.path
        || active === productsThirdPartyRoutes.cdns.edit.path
        || active === `${productsThirdPartyRoutes.cdns.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
    ) {
        active = productsThirdPartyRoutes.cdns.index.path;
    }

    if (active === productsThirdPartyRoutes.pullOrigins.index.path
        || active === productsThirdPartyRoutes.pullOrigins.add.path
        || active === productsThirdPartyRoutes.pullOrigins.edit.path
        || active === `${productsThirdPartyRoutes.pullOrigins.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
    ) {
        active = productsThirdPartyRoutes.pullOrigins.index.path;
    }

    if (active === productsThirdPartyRoutes.streamNamesPush.index.path
        || active === `${productsThirdPartyRoutes.streamNamesPush.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        || active === productsThirdPartyRoutes.streamNamesPush.add.path
        || active === productsThirdPartyRoutes.streamNamesPush.edit.path
    ) {
        active = productsThirdPartyRoutes.streamNamesPush.index.path;
    }

    if (active === productsThirdPartyRoutes.streamNamesPull.index.path
        || active === `${productsThirdPartyRoutes.streamNamesPull.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        || active === productsThirdPartyRoutes.streamNamesPull.add.path
        || active === productsThirdPartyRoutes.streamNamesPull.edit.path
    ) {
        active = productsThirdPartyRoutes.streamNamesPull.index.path;
    }

    if (active === productsThirdPartyRoutes.vodStorages.index.path
        || active === `${productsThirdPartyRoutes.vodStorages.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        || active === productsThirdPartyRoutes.vodStorages.add.path
        || active === productsThirdPartyRoutes.vodStorages.edit.path
    ) {
        active = productsThirdPartyRoutes.vodStorages.index.path;
    }

    return active;
};

const showTabs = (props) => {
    let showCdnTab = false,
        showPullOriginTab = false,
        showVodStorageTab = false;

    if (!_isEmpty(props.clientProductThirdParty)) {
        const types = props.clientProductThirdParty.third_party_client_product.third_party_client_types;

        showCdnTab = types.find((type) => (CLIENT_TYPES.CLIENT_TYPE_PUSH === convertToInt(type.id)));
        showPullOriginTab = types.find((type) => (CLIENT_TYPES.CLIENT_TYPE_PULL === convertToInt(type.id)));
        showVodStorageTab = types.find((type) => (CLIENT_TYPES.CLIENT_TYPE_VOD === convertToInt(type.id)));
    }

    return {showCdnTab, showPullOriginTab, showVodStorageTab};
};

export const productThirdPartyTabsState = (
    props,
    prepareThirdPartyClientCdnsForStore,
    prepareThirdPartyClientPullOriginsForStore,
    prepareThirdPartyClientVodStoragesForStore
) => {
    if (_isUndefined(props)) {
        props = this.props;
    }

    let active = activeRoute(props);
    const {showCdnTab, showPullOriginTab, showVodStorageTab} = showTabs(props);

    if (
        !checkRouteIsAllowed(active, props, showCdnTab, showPullOriginTab, showVodStorageTab)
        && null !== checkRouteIsAllowed(active, props, showCdnTab, showPullOriginTab, showVodStorageTab)
    ) {
        const returnLink = getLink('clients.edit', {id: props.match.params.id});

        showErrorModal({
            header: 'Can\'t access the given URL',
            text: 'Can\'t access the given URL',
            errorButtonText: 'Back to client',
            hideButtons: false,
            redirectLink: returnLink,
            errorButtonIcon: 'arrow circle left',
        });
    }

    if ('geolocation' === props.match.path.split('/')[5]) {
        active = routes.clients.products.thirdParty.geolocation.index.path;

        if (!props.clientProductThirdParty.product.has_geolocation_support) {
            const returnLink = getLink('clients.products.thirdParty.edit', {id: props.match.params.id});

            showErrorModal({
                header: 'This product does not have geolocation support',
                errorButtonText: 'Back to settings',
                hideButtons: false,
                redirectLink: returnLink,
                errorButtonIcon: 'arrow circle left',
            });
        }
    }

    const tabs = createTabs(
        props,
        showCdnTab,
        showPullOriginTab,
        showVodStorageTab,
        prepareThirdPartyClientCdnsForStore,
        prepareThirdPartyClientPullOriginsForStore,
        prepareThirdPartyClientVodStoragesForStore
    );

    return {
        active: active,
        tabs: tabs,
    };
};
