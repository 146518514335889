import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {routes} from '@constants/routes';
import {EXPORT_EXCEL_URL_FRAGMENT} from '@constants/variables';

export const createExportExcelProps = (exportParams) => {
    if ({} === exportParams) {
        return {};
    }

    const {filename, titles, parseData, path, dataExport} = exportParams;

    return {
        dataExport,
        dataSet: [{
            columns: titles ? titles.map(title => ({title: title})) : [],
            data: (dataExport && 0 < dataExport.length)
                ? dataExport.map(row => parseData(row))
                : [titles.map((() => ''))],
        }],
        filename,
        url: `${_get(routes, path)}${EXPORT_EXCEL_URL_FRAGMENT}`,
    };
};

export const getContent = (originEntryPoint) => {
    if (!originEntryPoint.tournaments && !originEntryPoint.content_categories) {
        return '';
    }

    const separator = ' > ';

    const tournaments = originEntryPoint.tournaments.map(tournament => ([
        tournament.tournament_category.sport?.name,
        tournament.tournament_category?.name,
        tournament?.name,
    ].join(separator)));

    const contentCategories = originEntryPoint.content_categories.map(contentCategory => ([
        contentCategory.parent.parent?.name,
        contentCategory.parent?.name,
        contentCategory?.name,
    ].join(separator)));

    return [...tournaments, ...contentCategories].sort((a, b) => (
        a.toLowerCase() > b.toLowerCase() ? 1 : -1
    )).join('; ');
};

createExportExcelProps.propTypes = {
    exportParams: PropTypes.shape({
        dataExport: PropTypes.array.isRequired,
        filename: PropTypes.string.isRequired,
        parseData: PropTypes.func.isRequired,
        path: PropTypes.string.isRequired,
        titles: PropTypes.array.isRequired,
    }).isRequired,
    routes: PropTypes.object.isRequired,
};
