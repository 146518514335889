import {get as _get, isEmpty as _isEmpty} from 'lodash';
import moment from 'moment';

import {showErrorModal} from '@utils/modal';
import {dateEmpty} from '@utils/date';

const ALLOWED_DAYS = 7;

const noRequiredFilters = (from, to, filters) => {
    const fromDate = moment(from, 'YYYY-MM-DD');
    const toDate = moment(to, 'YYYY-MM-DD'),
        differenceDays = moment.duration(toDate.diff(fromDate)).asDays(),
        propertyLicences = _get(filters, 'propertyLicence', []),
        tournamentCategories = _get(filters, 'category', []),
        contentCategoryLevel2 = _get(filters, 'contentCategoryLevel2', []),
        eventReport = _get(filters, 'hasEventReport', []);

    return differenceDays > ALLOWED_DAYS
        && _isEmpty(propertyLicences)
        && _isEmpty(tournamentCategories)
        && _isEmpty(contentCategoryLevel2)
        && 0 !== eventReport;
};

export const skipSearch = (props) => {
    const from = _get(props, 'filters.from', null),
        to = _get(props, 'filters.to', null);

    return dateEmpty(from, to) || noRequiredFilters(from, to, props.filters);
};

export const showConstraintsModal = (data) => {
    const headerText = 'Error with filters';

    if (dateEmpty(data.from, data.to)) {
        showErrorModal({
            header: headerText,
            text: 'You must enter a start time and an end time.',
        });
    }

    if (noRequiredFilters(data.from, data.to, data)) {
        showErrorModal({
            header: headerText,
            text: 'If you use a time range larger than ' + ALLOWED_DAYS + ' days, you also have to select an option '
                + 'in at least one of the following filters: "Property licence", "Category", "2nd level category", "Has no report".',
        });
    }
};

export const isValidDate = (date) => {
    if (!date) {
        return false;
    }

    return moment(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm'], true).isValid();
};
