import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {graphql} from '@apollo/client/react/hoc';

import Table from '@appComponents/Table';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {getTableRowIsDisabledClassName} from '@modules/booking/utils/bookingsTable';
import {adjustHeight} from '@modules/client/utils/adjustHeightHelper';

import {
    clientBookingRowRenderer,
    getBasicClientBookingsColumnsForBookingsTable,
} from '../utils/clientBookingTable';

export const columns = {
    ...getBasicClientBookingsColumnsForBookingsTable('booking'),
    'booking_type_name': {
        label: 'Type',
        sorting: true,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'price': {
        label: 'Price',
        sorting: row => row.price ? row.price : 0,
        header: {
            align: 'right',
        },
        content: {
            align: 'right',
        },
    },
    'event_invoice_status_name': {
        label: 'Invoice status',
        sorting: row => row.invoice_status,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'booking_creation_datetime': {
        label: 'Time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

const ClientBookingsTable = (props) => {
    const {cellRefs, clientBookings, clientId, data, getSelectedElements, loading} = props;

    useEffect(() => {
        adjustHeight(cellRefs);
    }, [cellRefs]);

    return (
        <div className='clientBookings__table'>
            <Table
                adjustHeight={adjustHeight}
                className='mini'
                columns={columns}
                data={clientBookings}
                defaultSort={'start_datetime'}
                showLimitWarning={true}
                dataLimitWarning={10000}
                loading={loading}
                name='clientBookingsList'
                noDataText='No bookings found'
                padded={false}
                rowRenderer={clientBookingRowRenderer}
                rowRendererProps={{mappedIsoCodes: data.mappedIsoCodes, type: 'booking', clientId: clientId}}
                rowClassname={getTableRowIsDisabledClassName}
                multiple={true}
                selectable={true}
                getSelectedElements={getSelectedElements}
                url={props.url}
            />
        </div>
    );
};

ClientBookingsTable.propTypes = {
    cellRefs: PropTypes.array,
    clientBookings: PropTypes.array,
    clientId: PropTypes.number.isRequired,
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    loading: PropTypes.bool.isRequired,
    refProperty: PropTypes.func,
    getSelectedElements: PropTypes.func.isRequired,
    url: PropTypes.string,
};
ClientBookingsTable.defaultProps = {
    clientBookings: [],
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
};
ClientBookingsTable.displayName = 'ClientBookingsTable';

export default graphql(GetMappedIsoCodes)(ClientBookingsTable);
