import React from 'react';

import {routes} from '@constants/routes';
import {EXPORT_EXCEL_URL_FRAGMENT} from '@constants/variables';

import ClientAnalyticsSetupIndex from '../views/ClientAnalyticsSetupsIndex';
import ClientAnalyticsSetupLabelsIndex from '../views/ClientAnalyticsSetupLabelsIndex';

export const getClientTabsState = (props) => {
    const clientId = parseInt(props.match.params.id, 10),
        clientAnalyticsSetupRoutes = routes.clients.clientAnalyticsSetup,
        clientAnalyticsSetupLabelRoutes = clientAnalyticsSetupRoutes.labels;

    let active = props.match.path;

    if (
        active === clientAnalyticsSetupLabelRoutes.add.path
        || active === clientAnalyticsSetupLabelRoutes.edit.path
        || active === `${clientAnalyticsSetupLabelRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
    ) {
        active = clientAnalyticsSetupLabelRoutes.index.path;
    }

    let tabs = {
        [clientAnalyticsSetupRoutes.index.path]: {
            key: clientAnalyticsSetupRoutes.index.path,
            render: <ClientAnalyticsSetupIndex clientId={clientId}/>,
            text: 'General settings',
            url: 'clients.clientAnalyticsSetup.index',
            urlParams: {id: props.match.params.id},
        },
        [clientAnalyticsSetupLabelRoutes.index.path]: {
            key: clientAnalyticsSetupLabelRoutes.index.path,
            render: <ClientAnalyticsSetupLabelsIndex
                clientId={clientId}
            />,
            text: 'Labels',
            url: 'clients.clientAnalyticsSetup.labels.index',
            urlParams: {id: props.match.params.id},
        },
    };

    return {
        active: active,
        tabs: tabs,
    };
};
