import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Dimmer, Grid, Header, Loader, Segment} from 'semantic-ui-react';

import {graphql, withApollo} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import mapModulesToProps from '@utils/mapModulesToProps';
import {convertToInt, digitsOnly} from '@utils/helpers';
import {getSuffixWithNumber} from '@utils/ordinalNumberSuffix';
import {renderModalError} from '@utils/forms';
import navigation from '@constants/navigation';
import {DeleteContentCategory as DeleteContentCategoryMutation} from '@graphql/contentCategory/mutation';
import {GetContentCategoriesForView} from '@graphql/contentCategory/query';
import {routes} from '@constants/routes';
import {showModal, showModalConfirmation, hideModal} from '@utils/modal';
import {showMessageBox} from '@utils/messageBox';

import ContentCategoryLevel from '../components/ContentCategoryLevel';
import ContentCategoryEdit from './ContentCategoryEdit';

export class ContentCategoriesIndex extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        data: PropTypes.object,
        DeleteContentCategory: PropTypes.func,
        Menu: PropTypes.object,
        match: PropTypes.object,
        ModalObject: PropTypes.object,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const path = this.props.match.path;
        const route = routes.content_categories;

        let selected = {
            cat1: digitsOnly(props.match.params.cat1) ? props.match.params.cat1 : null,
            cat2: digitsOnly(props.match.params.cat2) ? props.match.params.cat2 : null,
            cat3: digitsOnly(props.match.params.cat3) ? props.match.params.cat3 : null,
        };

        if (
            (selected.cat2 && !selected.cat1)
            || (selected.cat3 && (!selected.cat2 || !selected.cat1))
        ) {
            selected = this.resetToIndex(selected, route);
        }

        let isAdd = path === route.add.cat1.path  || path === route.add.cat2.path  || path === route.add.cat3.path;
        let isEdit = path === route.edit.cat1.path || path === route.edit.cat2.path || path === route.edit.cat3.path;

        if ((props.match.params.cat1 && props.match.params.cat1 !== selected.cat1
            || props.match.params.cat2 && props.match.params.cat2 !== selected.cat2
            || props.match.params.cat3 && props.match.params.cat3 !== selected.cat3)
        ) {
            isAdd = false;
            isEdit = false;
            this.resetToIndex(selected, route);
        }

        this.state = {
            cat1: {
                loading: true,
                content: null,
                selected: selected.cat1,
            },
            cat2: {
                loading: false,
                content: null,
                selected: selected.cat2,
            },
            cat3: {
                loading: false,
                content: null,
                selected: selected.cat3,
            },
            action: {
                add: isAdd,
                edit: isEdit,
            },
        };

        if (this.state.cat1.selected) {
            this.getData(2, convertToInt(this.state.cat1.selected));
        }

        if (this.state.cat2.selected) {
            this.getData(3, convertToInt(this.state.cat2.selected));
        }

        this.props.Menu.storeMenu(navigation.mediaRights.key);
    }

    resetToIndex = (selected, route) => {
        selected.cat3 = null;
        selected.cat2 = null;
        selected.cat1 = null;
        this.props.history.push(route.index.path);

        return selected;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {contentCategories} = nextProps.data;
        let nextState = {};

        if (contentCategories && contentCategories !== prevState.cat1.content && null === prevState.cat1.content) {
            nextState = { cat1: {...prevState.cat1, ...{ content: contentCategories, loading: false}}};
        }

        return nextState;
    }

    componentDidMount() {
        this.manageModal();
    }

    componentDidUpdate(prevProps) {
        const modalIsClosed =
            prevProps.ModalObject.isVisible !== this.props.ModalObject.isVisible && !this.props.ModalObject.isVisible;

        if (modalIsClosed) {
            if (this.state.cat2.selected) {
                window.history.pushState({}, '', `/content-categories/${this.state.cat1.selected}/${this.state.cat2.selected}`);
            } else if (this.state.cat1.selected) {
                window.history.pushState({}, '', `/content-categories/${this.state.cat1.selected}`);
            } else {
                window.history.pushState({}, '', '/content-categories');
            }
        }
    }

    getData(level, parentId) {
        this.props.client.query({
            query: GetContentCategoriesForView,
            fetchPolicy: 'network-only',
            variables: {
                level: level,
                parent: [parentId],
            },
        }).then((result) => {
            this.setState(() => ({
                [`cat${level}`]: {...this.state[`cat${level}`], ...{loading: false, content: result.data.contentCategories}},
            }
            ));
        });
    }

    selectCategory = (row) => {
        const data = row.data;
        let applyState = {};

        applyState = {...applyState, ...{
            [`cat${data.level}`]: {...this.state[`cat${data.level}`], ...{selected: data.id}},
        }};

        switch (data.level) {
            case 1:
                applyState = {...applyState, ...{
                    cat3: {...this.state.cat3, ...{ content: null, selected: null}},
                    cat2: {...this.state.cat2, ...{ content: null, selected: null}},
                }};

                window.history.pushState({}, '',
                    `/content-categories/${data.id}`);
                break;
            case 2:
                applyState = {...applyState, ...{
                    cat3: {...this.state.cat3, ...{content: null, selected: null}},
                }};
                window.history.pushState({}, '',
                    `/content-categories/${applyState.cat1?.selected || this.state.cat1.selected}/${data.id}`);
                break;
            case 3:
                window.history.pushState({}, '',
                    `/content-categories/${applyState.cat1?.selected || this.state.cat1.selected}/${applyState.cat2?.selected || this.state.cat2.selected}/${data.id}`);
                break;
        }

        if (3 > data.level) {
            applyState = {...applyState, ...{
                [`cat${data.level + 1}`]: {loading: true, content: null, selected: null},
            }};
            this.getData(data.level + 1, convertToInt(data.id));
        }

        this.setState(applyState);
    };

    manageModal = () => {
        const {action} = this.state;

        if (action.add) {
            const data = {
                level: this.state.cat2.selected ? 3 : this.state.cat1.selected ? 2 : 1,
                parentId: this.state.cat1.selected || this.state.cat2.selected || this.state.cat3.selected,
            };

            this.openCategoryModal(data);
        }

        if (action.edit) {
            const targetCategoryId = this.props.match.params.cat3 || this.props.match.params.cat2 || this.props.match.params.cat1;

            if (digitsOnly(targetCategoryId.toString())) {
                this.openCategoryModal({
                    level: this.props.match.params.cat3 ? 3 : this.props.match.params.cat2 ? 2 : 1,
                    parentId: this.state.cat1.selected || this.state.cat2.selected || this.state.cat3.selected,
                    id: targetCategoryId,
                });
            } else {
                this.showModalError();
            }
        }
    };

    openCategoryModal = (data) => {
        if (this.state[`cat${data.level}`].selected !== data.id && data.id) {
            this.selectCategory({data: {level: data.level, id: data.id}});
        }

        showModal({
            isVisible: true,
            content: <ContentCategoryEdit
                id={convertToInt(data.id)}
                level={convertToInt(data.level)}
                parentId={convertToInt(this.state[`cat${data.level-1}`]?.selected) ?? null}
                refreshData={this.updateDataFromResponse}
            />,
        });
    };

    showModalError = () => (
        showModal({
            isVisible: true,
            content: renderModalError('Content Categories', routes.content_categories.index.path),
        })
    );

    deleteCategoryClick = (row) => {
        showModalConfirmation({
            header: <Header icon='trash' content={`Delete ${row.level} level category`}/>,
            text: `Are you sure you want to delete the ${row.level} level category "${row.name}"?`,
            onYes: () => (this.deleteCategory(row)),
        });
    };

    deleteCategory = (data) => {
        const {DeleteContentCategory} = this.props;
        showModalConfirmation({text: `The ${getSuffixWithNumber(data.level)} level category is being deleted...`});

        return DeleteContentCategory({ variables: { id: data.id}})
            .then(() => {
                this.setState(() => ({
                    [`cat${data.level}`]: {
                        ...this.state[`cat${data.level}`],
                        ...{
                            selected: this.state[`cat${data.level}`].selected !== data.id
                                ? this.state[`cat${data.level}`].selected
                                : null,
                            content: this.state[`cat${data.level}`].content
                                .filter((category) => category.id !== data.id)},
                    },
                }));

                for (let i = data.level + 1; 3 >= i; i++) {
                    this.setState(() => ({
                        [`cat${i}`]: {...this.state[`cat${i}`], ...{content: null, selected: null}},
                    }));
                }

                this.updateParentChildrenCount(data, -1);

                hideModal();
                showMessageBox('contentCategoriesMessage',
                    `The ${getSuffixWithNumber(data.level)} level category "${data.name}" has been deleted successfully.`,
                    null, 'success');
            }).catch((error) => {
                hideModal();
                showMessageBox('contentCategoriesMessage', 'The content category could not be deleted.',
                    `${error}`, 'error');
            });
    };

    updateParentChildrenCount(data, addition) {
        if (1 === data.level) {
            return;
        }

        const index = `cat${data.level - 1}`;

        if (this.state[index].content) {
            this.setState(() => ({
                [index]: {...this.state[index], ...{ content: this.state[index].content.map((category) =>
                { return (category.id === data.parent.id
                    ? {...category, children_count: category.children_count + addition}
                    : category );})}},
            }));
        }
    }

    updateDataFromResponse = (data) => {
        const dataCategory = data.updateContentCategory || data.createContentCategory;
        const index = `cat${dataCategory.level}`;

        if (data.updateContentCategory) {
            this.setState(() => ({
                [index]: {...this.state[index], ...{ content: this.state[index].content
                    .map((category) => category.id === dataCategory.id ? dataCategory : category)}},
            }));
        }

        if (data.createContentCategory) {
            this.setState(() => ({
                [index]: {...this.state[index], ...{ content: [...this.state[index].content, dataCategory]}},
            }));
            this.updateParentChildrenCount(dataCategory, 1);
        }
        hideModal();
    };

    isLoading = () => {
        return this.state.cat1.loading || this.state.cat2.loading || this.state.cat3.loading;
    };

    renderCategories() {
        let content = [];

        for(let i = 1; 3 >= i; i++) {
            const index = `cat${i}`;

            if (this.state[index].content) {
                const parent = this.state[`cat${i-1}`] ? convertToInt(this.state[`cat${i-1}`].selected) : null;

                content.push(
                    <ContentCategoryLevel key={`category_column_${i}`}
                        data={this.state[index].content}
                        deleteCategory={this.deleteCategoryClick}
                        editCategory={this.openCategoryModal}
                        level={i}
                        loading={this.state[index].loading}
                        parent={parent}
                        rowOnClick={this.selectCategory}
                        selected={{
                            cat1: this.state.cat1.selected,
                            cat2: this.state.cat2.selected,
                            cat3: this.state.cat3.selected,
                        }}
                    />
                );
            }
        }

        return content;
    }

    render() {
        return (
            <div>
                <HeaderRenderer
                    messagesBoxNames='contentCategoriesMessage'
                    pageTitle={'Content categories'}
                />
                <Segment basic>
                    <Grid columns={3} className='helper__removeTopMargin'>
                        <Grid.Row>
                            <Dimmer inverted active={this.isLoading()}>
                                <Loader/>
                            </Dimmer>
                            {this.renderCategories()}
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Menu']),
    ContentCategoriesWithData = compose(
        graphql(GetContentCategoriesForView,{
            options: () => {
                return {
                    notifyOnNetworkStatusChange: true,
                    fetchPolicy: 'network-only',
                    variables: {
                        level: 1,
                        parent: [],
                    },
                };
            },
        }),
        graphql(DeleteContentCategoryMutation, {name: 'DeleteContentCategory'})
    )(ContentCategoriesIndex);

const mapStateToProps = (state) => {
    return {
        ModalObject: state.modal,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ContentCategoriesWithData)));
