import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';

import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import mapModulesToProps from '@utils/mapModulesToProps';
import {getSearchFilters} from '@utils/filters';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import navigation from '@constants/navigation';
import SrtOriginEntryPointsIndexButtons from '@modules/srtOriginEntryPoint/components/SrtOriginEntryPointsIndexButtons';
import useQuery from '@utils/hooks/useQuery';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';
import {routes} from '@constants/routes';
import {exportExcel} from '@modules/srtOriginEntryPoint/utils/export/srtOriginEntryPointIndexExcel';
import {GetSrtOriginEntryPointsData} from '@graphql/srtOriginEntryPoints/query';
import SrtOriginEntryPointsFilters from '@modules/srtOriginEntryPoint/components/SrtOriginEntryPointsFilters';
import SrtOriginEntryPointTable from '@modules/srtOriginEntryPoint/components/SrtOriginEntryPointTable';

export const DEFAULT_FILTERS_VALUES = {
    search: '',
    tournaments: [],
    contentCategories: [],
};

export const SrtOriginEntryPointIndex = ({Menu, filters = {}}) => {
    const {data: {srtOriginEntryPoints = []} = {}, loading: isDataLoading = true, error: dataError = {}} = useQuery({
        query: GetSrtOriginEntryPointsData,
        options: {
            variables: {
                search: filters.search || DEFAULT_FILTERS_VALUES.search,
                tournaments: filters.tournaments || DEFAULT_FILTERS_VALUES.tournaments,
                contentCategories: filters.contentCategories || DEFAULT_FILTERS_VALUES.contentCategories,
            },
            refetch: filters.lastClickSubmit,
        },
    });

    const loading = isDataLoading && _isEmpty(dataError);

    useEffect(() => {
        if (!_isEmpty(dataError)) {
            showErrorModal({
                header: 'Error',
                redirectLink: getLink(`${routes}.srtOriginEntryPoints.index`),
                text: 'There was an error while fetching the data.',
                errorButtonIcon: 'arrow circle left',
                errorButtonText: 'Back to list',
            });
        }
    }, [dataError]);

    useEffect(() => {
        Menu.storeMenu(navigation.infrastructure.key);
    }, []);

    return (
        <>
            <HeaderRenderer
                pageTitle='SRT origin entry points'
                buttons={SrtOriginEntryPointsIndexButtons}
                exportExcelParams={exportExcel(srtOriginEntryPoints)}
                filters={SrtOriginEntryPointsFilters}
                filtersProps={{loading}}
                filtersButtonName='srtOriginEntryPoints'
            />

            <SrtOriginEntryPointTable
                loading={loading}
                srtOriginEntryPoints={srtOriginEntryPoints}
            />
        </>
    );
};

SrtOriginEntryPointIndex.propTypes = {
    Menu: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
};

const SrtOriginEntryPointIndexWrapped = FilterQueryWrapper(SrtOriginEntryPointIndex, {
    queryForRefresh: 'GetSrtOriginEntryPointsData',
    filterUrls: ['srtOriginEntryPoints.index'],
});

const mapDispatchToProps = mapModulesToProps(['Menu']);
const mapStateToProps = (state) => {
    return {
        filters: getSearchFilters(state, 'srtOriginEntryPoints', {...DEFAULT_FILTERS_VALUES}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(SrtOriginEntryPointIndexWrapped, {...DEFAULT_FILTERS_VALUES})
);
