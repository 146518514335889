import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Header} from 'semantic-ui-react';

import {gql, graphql} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import * as ClientGraphQl from '@graphql/clients/index';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import navigation from '@constants/navigation';
import {convertToInt} from '@utils/helpers';

import * as MESSAGES from '../constants/messages';
import {exportExcel as clientIndexExportExcel} from '../utils/export/clientIndexExcel';
import ClientTable from '../components/ClientTable';
import ClientIndexButtons from '../components/ClientIndexButtons';
import ClientFilters from '../components/ClientFilters';

export class ClientIndex extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        DataClient: PropTypes.object.isRequired,
        DataClientFilter: PropTypes.object.isRequired,
        DeleteClient: PropTypes.func.isRequired,
        filters: PropTypes.shape({
            countries: PropTypes.arrayOf(PropTypes.number),
            clientTypes: PropTypes.arrayOf(PropTypes.number),
            isDisabled: PropTypes.number,
            isInternal: PropTypes.number,
            products: PropTypes.arrayOf(PropTypes.number),
            search: PropTypes.string,
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        MessageBox: PropTypes.object.isRequired,
        Modal: PropTypes.object.isRequired,
        Menu: PropTypes.object,
    };

    static defaultProps = {
        loading: false,
        filters: {
            search: null,
            countries: [],
            clientTypes: [],
            isDisabled: null,
            isInternal: null,
            products: [],
        },
    };

    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        this.props.Menu.storeMenu(navigation.clients.key);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            DataClient: {clients},
            DataClientFilter: {countries, clientTypes, products},
        } = nextProps;

        const nextState = {};

        if (countries && !prevState.countries) {
            nextState.countries = countries.map((country) => ({
                key: country.value,
                text: country.text.capitalizeFirstLetter(),
                value: convertToInt(country.value),
            }));
        }

        if (clientTypes && !prevState.clientTypes) {
            nextState.clientTypes = clientTypes.map((clientType) => ({
                key: clientType.value,
                text: clientType.text,
                value: convertToInt(clientType.value),
            }));
        }

        if (products && !prevState.products) {
            nextState.products = products.map((product) => ({
                key: product.value,
                text: product.text,
                value: convertToInt(product.value),
            }));
        }

        if (clients) {
            nextState.clients = clients;
        }

        return 0 < Object.keys(nextState).length ? nextState : null;
    }

    deleteClient(clientId) {
        const {
            DataClient,
            DeleteClient,
            MessageBox: {addMessage},
            Modal: {setModal},
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETE_MODAL_PROGRESS,
        });

        return DeleteClient({
            variables: {
                id: clientId,
            },
        }).then(() => {
            addMessage('clientMessage', MESSAGES.DELETE_MODAL_SUCCESS, null, 'success');
            setModal({isVisible: false});
            DataClient.refetch();
        }).catch((error) => {
            setModal({isVisible: false});
            addMessage('clientMessage', MESSAGES.DELETE_MODAL_ERROR, `${error}`, 'error');
        });
    }

    handleClientDelete = ({id, name}) => {
        this.props.Modal.setModalConfirmation({
            header: <Header icon='trash' content={MESSAGES.DELETE_MODAL_HEADER}/>,
            onYes: () => (this.deleteClient(id)),
            text: MESSAGES.DELETE_MODAL_TEXT(name),
        });
    };

    render() {
        const loading = (
            this.props.DataClient.loading
            || this.props.DataClientFilter.loading
            || this.props.loadingRefetch
        );

        return (
            <div>
                <HeaderRenderer
                    buttons={ClientIndexButtons}
                    exportExcelParams={clientIndexExportExcel(this.state.clients)}
                    filters={ClientFilters}
                    filtersButtonName='Clients'
                    filtersProps={{
                        data: {
                            countries: this.state.countries,
                            clientTypes: this.state.clientTypes,
                            loading: loading,
                            products: this.state.products,
                        },
                        loading: this.props.DataClient.loading,
                    }}
                    loading={loading}
                    messagesBoxNames='clientMessageIndex'
                    pageTitle={'Clients'}
                />
                <div className='clearfix'/>
                <ClientTable
                    clients={this.state.clients}
                    loading={loading}
                    onClientDelete={this.handleClientDelete}
                />
            </div>
        );
    }
}

export const ClientFilterQuery = gql(ClientGraphQl.clientFilterQuery);
export const ClientSearchQuery = gql(ClientGraphQl.clientSearchQuery);
export const DeleteClientMutation = gql(ClientGraphQl.clientDeleteMutation);

const ClientWithQuery = compose(
    graphql(ClientSearchQuery, {
        options: (props) => {
            let isDisabled = null,
                isInternal = null;

            if (props.filters.isDisabled !== undefined && null !== props.filters.isDisabled) {
                isDisabled = !!(props.filters.isDisabled);
            }

            if (undefined !== props.filters.isInternal && null !== props.filters.isInternal) {
                isInternal = !!(props.filters.isInternal);
            }

            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    clientTypes: props.filters.clientTypes || ClientIndex.defaultProps.filters.clientTypes,
                    countries: props.filters.countries || ClientIndex.defaultProps.filters.countries,
                    isDisabled: isDisabled,
                    isInternal: isInternal,
                    products: props.filters.products || ClientIndex.defaultProps.filters.products,
                    search: props.filters.search || ClientIndex.defaultProps.filters.search,
                },
            };
        },
        name: 'DataClient',
    }),
    graphql(ClientFilterQuery, {
        options: () => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
            };
        },
        name: 'DataClientFilter',
    }),
    graphql(DeleteClientMutation, {name: 'DeleteClient'})
)(FilterQueryWrapper(ClientIndex, {
    queryForRefresh: 'DataClient',
    filterUrls: ['clients.index'],
}));

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal', 'Menu']);

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'Clients', ClientIndex.defaultProps.filters),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(ClientWithQuery, ClientIndex.defaultProps.filters)
);
