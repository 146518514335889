import {withApollo} from 'react-apollo';
import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';

class ClientAnalyticsSetupLabelForm extends DefaultForm {
    componentDidMount() {
        super.componentDidMount();

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();
        super.receivedPropsValidator(prevProps);

        if (
            prevProps.formParams.id !== this.props.formParams.id &&
            null !== this.props.formParams.id &&
            this.props.GraphQLEntityData.clientLabel
        ) {
            const clientLabel = this.props.GraphQLEntityData.clientLabel;

            this.setField('id', {value: this.props.formParams.id});
            this.setField('code', {value: clientLabel.code});
            this.setField('name', {value: clientLabel.name});
        }
    }

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});

    prepareDataForSubmit = (data) => {
        let dataToSave = {
            client: this.props.formParams.optionsVariables.id,
            code: data.code,
            name: data.name,
        };

        if (data.id) {
            dataToSave.id = parseInt(data.id, 10);
        }

        return dataToSave;
    };

    renderErrors(errorData) {
        const link = getLink('clients.clientAnalyticsSetup.labels.index', {
            id: this.props.formParams.optionsVariables.id,
        });

        super.renderErrors(errorData, this.props.Model.entityLabel.capitalizeFirstLetter(), link);
    }
}

export default withApollo(ClientAnalyticsSetupLabelForm);
