import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';
import {Columns} from '@modules/srtOriginEntryPoint/components/Table/Columns';
import {RowRenderer} from '@modules/srtOriginEntryPoint/components/Table/RowRenderer';

const SrtOriginEntryPointTable = ({loading, srtOriginEntryPoints = []}) => {
    return (
        <Table
            name='srtOriginEntryPointsList'
            columns={Columns}
            defaultSort='name'
            loading={loading}
            data={srtOriginEntryPoints}
            rowRenderer={RowRenderer}
            noDataText={'No SRT origin entry points found'}
        />
    );
};

SrtOriginEntryPointTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    srtOriginEntryPoints: PropTypes.array.isRequired,
};

export default SrtOriginEntryPointTable;
