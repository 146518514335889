import PropTypes from 'prop-types';
import React from 'react';
import {get as _get} from 'lodash';
import {Popup} from 'semantic-ui-react';
import {graphql} from '@apollo/client/react/hoc';

import Authorization from '@appComponents/Authorization';
import {IconCheckmark, IconEdit, IconGlobe, IconShield} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Table from '@appComponents/Table';
import {
    getTerritoriesWithTooltipForDeviceCategories,
    parseDeviceCategories,
    renderDeviceCategories,
    renderNumberOfCountries,
} from '@utils/countryHelpers';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import {GetMappedIsoCodes} from '@graphql/country/query';
import {renderBlackoutZonesTableRowCell} from '@utils/blackoutZones';
import {
    getBasicEventBookingsColumnsForBookingsTable,
} from '@modules/client/utils/clientBookingTable';
import {routes} from '@constants/routes';
import {ToggleIsCancelledBooking} from '@graphql/booking/mutation';
import {refetchQueryByName} from '@utils/apollo';
import {EventContentVariantPopupWithName} from '@utils/eventContentVariant/EventContentVariantPopupWithName';
import {formattedPriceOrEmptyValue} from '@utils/helpers';
import {EMPTY_VALUE_DASH} from '@constants/variables';
import {getTableRowIsDisabledClassName} from '@modules/booking/utils/bookingsTable';
import EventBookingCancellationIcon from '@modules/booking/views/BookingCancellationIcon';

export const columns = {
    ...getBasicEventBookingsColumnsForBookingsTable('booking'),
    'booking_type': {
        label: 'Type',
        sorting: true,
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        }},
    'price': {
        label: 'Price',
        sorting: true,
        header: {
            align: 'right',
        },
        content: {
            align: 'right',
        }},
    'booking_invoice_status': {
        label: 'Invoice status',
        sorting: row => _get(row, 'booking_invoice_status.name', _get(row, 'event_invoice_status.name', null)),
        header: {
            align: 'left',
        },
        content: {
            align: 'left',
        },
    },
    'time': {
        label: 'Time',
        sorting: row => row.creation_datetime,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        }},
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

const renderInvoiceStatus = (row) => {
    if (_get(row, 'booking_invoice_status.id', null) && row.distribution.product.is_invoiceable) {
        return (
            <Popup
                content={'The invoice status has been set specifically for this booking.'}
                trigger={<b><i>{row.booking_invoice_status.name}</i></b>}
            />
        );
    } else if (_get(row, 'event_invoice_status.id', null) && row.distribution.product.is_invoiceable) {
        return row.event_invoice_status.name;
    } else {
        return null;
    }
};

const EventsBookingsTable = (props) => {
    const {data, eventBookings, eventId, loading} = props;

    const rowRenderer = (column, row) => {
        const bookingGeoRestrictions = _get(row, 'booking_geo_restrictions', []),
            deviceCategoriesData = parseDeviceCategories(data.mappedIsoCodes, bookingGeoRestrictions);

        switch (column) {
            case 'bookingId': {
                return(
                    <LinkWithAuthorization
                        resources={RESOURCES.BOOKING}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'events.bookings.edit',
                            params: {id : eventId, bookingId: row.id},
                        }}
                    >
                        {row.id}
                    </LinkWithAuthorization>
                );
            }
            case 'deviceCategories':
                return renderDeviceCategories(deviceCategoriesData);
            case 'countries':
                return getTerritoriesWithTooltipForDeviceCategories(deviceCategoriesData, data.mappedIsoCodes);
            case 'numberOfCountries':
                return renderNumberOfCountries(deviceCategoriesData);
            case 'blackoutZones':
                return renderBlackoutZonesTableRowCell(deviceCategoriesData, false);
            case 'client': {
                return(
                    <LinkWithAuthorization
                        resources={RESOURCES.CLIENT}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'clients.edit',
                            params: {id : row.client.id},
                        }}
                    >
                        {row.client.name}
                    </LinkWithAuthorization>
                );
            }
            case 'price': {
                return formattedPriceOrEmptyValue(row.price, EMPTY_VALUE_DASH);
            }
            case 'booking_invoice_status': {
                return renderInvoiceStatus(row);
            }
            case 'time': {
                return row.creation_datetime;
            }
            case 'distributionType': {
                return row.distribution.distribution_type.name;
            }
            case 'content': {
                return <EventContentVariantPopupWithName eventContent={row.distribution.event_content}/>
            }
            case 'client_package': {
                if(row.client_package){
                    return(
                        <LinkWithAuthorization
                            resources={RESOURCES.CLIENT_PACKAGE}
                            privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                            link={{
                                name: 'clients.packages.edit',
                                params: {id : row.client.id, packagesId: row.client_package.id},
                            }}
                        >
                            {row.client_package.name}
                        </LinkWithAuthorization>
                    );
                } else {
                    return '-';
                }
            }
            case 'is_hq':
                return row.is_hq ? <IconCheckmark/> : null;
            case 'product': {
                return row.distribution.product.short_name;
            }
            case 'actions': {
                return (
                    <Authorization
                        resources={RESOURCES.BOOKING}
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                    >
                        <div className='actions-list-icon'>
                            <IconEdit size='large' link={
                                getLink('events.bookings.edit', {id : eventId, bookingId: row.id})
                            } />
                            <IconGlobe size='large' link={
                                getLink(
                                    'events.bookings.editGeoRestrictions',
                                    {id : eventId, bookingId: row.id}
                                )}
                            />
                            <IconShield
                                link={getLink(
                                    'events.bookings.blackoutZones',
                                    {id : eventId, bookingId: row.id}
                                )}
                                size='large'
                            />
                            <EventBookingCancellationIcon
                                bookingId={row.id}
                                isCancelled={row.is_cancelled}
                                entityName={'booking'}
                                messageBoxName='eventLayout'
                                successCallback={() => {
                                    refetchQueryByName('GetEventBookingsForTable');
                                }}
                                mutationChangeCancellationStatus={ToggleIsCancelledBooking}
                            />
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className='eventBookings__table'>
            <Table
                className='mini'
                name='eventBookingsList'
                columns={columns}
                data={eventBookings}
                loading={loading}
                pagination={false}
                noDataText='No bookings found'
                rowRenderer={rowRenderer}
                rowClassname={getTableRowIsDisabledClassName}
                url={routes.events.bookings.index.path}
            />
        </div>
    );
};

EventsBookingsTable.propTypes = {
    data: PropTypes.shape({
        mappedIsoCodes: PropTypes.shape({
            countries: PropTypes.object,
            subdivisions: PropTypes.object,
        }),
    }),
    eventBookings: PropTypes.array,
    eventId: PropTypes.number.isRequired,
    loading: PropTypes.bool,
};
EventsBookingsTable.defaultProps = {
    data: {
        mappedIsoCodes: {
            countries: {},
            subdivisions: {},
        },
    },
    eventBookings: [],
    loading: false,
};
EventsBookingsTable.displayName = 'EventsBookingsTable';

export default graphql(GetMappedIsoCodes)(EventsBookingsTable);
