import classnames from 'classnames';
import {
    get as _get,
    has as _has,
    isEmpty as _isEmpty,
    pickBy as _pickBy,
} from 'lodash';
import React from 'react';
import {Grid, Header, Icon, Button} from 'semantic-ui-react';

import * as formUtils from '@utils/forms';
import {readNested} from '@utils/helpers';
import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import MessageBox from '@appComponents/MessageBox';
import Form from '@appComponents/ReduxFormControls';
import Steps from '@appComponents/Steps';

class DefaultWizard extends DefaultForm {
    constructor(props) {
        super(props);

        let fieldsWithRef = _pickBy(props.Model.fields, (field) => (field.props.withRef)),
            refs = {};

        Object.values(fieldsWithRef).forEach((fieldWithRef) => {
            refs[fieldWithRef.props.name] = React.createRef();
        });

        this.state = {
            ...this.state,
            currentStep: props.initalStep || 0,
            refs: refs,
        };
    }
    componentDidMount() {
        super.componentDidMount();
    }

    wizardProps = (field) => {
        const wizardKeys = Object.keys(this.props.Model.wizard);

        let currentWizardKey = wizardKeys[this.state.currentStep];

        if (!currentWizardKey || !field.wizardKey || field.wizardKey === currentWizardKey) {
            return;
        } else {
            return {hidden: true};
        }
    };

    renderSaveButton(props = {}) {
        const defaultProps = {
                color: 'blue',
                disabled: this.props.submitting,
                loading: this.props.submitting,
                type: 'submit',
            },
            maxSteps = Object.keys(this.props.Model.wizard).length - 1;

        let content;

        if (this.state.defaultForm_data && _has(this.state.defaultForm_data, 'variables.id')) {
            content = [<Icon name={'save'} key={1}/>, 'Save'];
        } else {
            if (maxSteps === this.state.currentStep) {
                content = [<Icon name={'save'} key={1}/>, 'Add Content'];
            } else {
                content = ['Next', <Icon name={'chevron right'} key={1}/>];
            }
        }

        return this.checkAuthorization(
            <Button
                {...defaultProps}
                {...props}
            >
                {content}
            </Button>, this.state.defaultForm_formPrivileges);
    }

    changeTabs = (e, data) => {
        window.history.pushState(null, null, getLink(
            data['data-path'], {id: this.props.formParams.event.id, contentId: this.props.formParams.id}
        ));

        this.setState(() => ({
            currentStep: data.id,
        }), () => {
            if ('function' === typeof this.additionalChangeTabsCallback) {
                this.additionalChangeTabsCallback(this.state.currentStep);
            }
        });
    };

    onFormSubmit = (data) => {
        const maxSteps = Object.keys(this.props.Model.wizard).length - 1;

        if (this.state.defaultForm_data && _has(this.state.defaultForm_data, 'variables.id')) {
            return this.submitData(data);
        }

        if (maxSteps !== this.state.currentStep) {

            this.setState(() => ({
                currentStep: this.state.currentStep + 1,
            }));

            if ('function' === typeof this.stepChangedCallback) {
                this.stepChangedCallback(this.state.currentStep + 1);
            }

            return false;
        } else {
            return this.submitData(data);
        }
    };

    submitData = (data) => {
        const dataToSave = this.prepareDataForSubmit(data);

        this.setState(() => ({
            defaultForm_errorShowed: false,
        }));

        const callbacks = {
            created: (data) => {
                this.state.defaultForm_callback.created?.(data);
                this.props.onSaveCallback?.(data);
            },
            updated: (data) => {
                this.state.defaultForm_callback.updated?.(data);
                this.props.onSaveCallback?.(data);
            },
            notCreated: this.state.defaultForm_callback.notCreated ? (data) => {
                this.state.defaultForm_callback.notCreated(data);
                this.props.onNotCreatedCallback?.(data);
            } : null,
            notUpdated: this.state.defaultForm_callback.notUpdated ? (data) => {
                this.state.defaultForm_callback.notUpdated(data);
                this.props.onNotUpdatedCallback?.(data);
            } : null,
        };

        return formUtils.onSubmit({
            dataToSave,
            actions: {
                create: {
                    mutation: this.props.CreateEntity,
                },
                update: {
                    mutation: this.props.UpdateEntity,
                    options: _get(this.props, 'Model.mutationOptions.update', {}),
                },
            },
            message: {
                box: this.props.MessageBox,
                boxName: this.props.Model.messages.boxName,
                text: this.props.Model.messages.text,
                entityName: dataToSave.name || '',
                entityLabel: this.props.Model.entityLabel,
                description: this.props.Model.messages.description,
            },
            callback: {
                created: callbacks.created,
                updated: callbacks.updated,
                notCreated: callbacks.notCreated,
                notUpdated: callbacks.notUpdated,
            },
            apolloClient: this.props.client,
        });
    };

    gatherGroupFields = (field, groupFields) => {
        if (!groupFields[field.groupKey]) {
            groupFields[field.groupKey] = [];
        }

        groupFields[field.groupKey].push(field);

        return groupFields;
    };

    renderGroupFields(groupFields, field, iterator, entityData) {
        if (groupFields[field.groupKey].length < field.groupSize) {
            return;
        }

        let name = field.props.name.split('_');

        if (this.state[`product_${name[0]}_distribution_type_${name[1]}_always_hidden`]) {
            return;
        }

        const gridRow2ClassNames = [
            'grid-row-2',
            0 < groupFields[field.groupKey].filter((field) => (2 === field.rowNumber && !field.props.hidden)).length
                ? ''
                : 'all-columns-hidden',
        ];

        let component = (
            <Grid key={iterator} className={this.wizardProps(groupFields[field.groupKey][0]) ? 'hidden' : null}>
                <Grid.Row key='row1' className='gridRow1'>
                    {this.renderGridColumns(
                        groupFields[field.groupKey].filter(field => !field.rowNumber),
                        entityData
                    )}
                </Grid.Row>
                <Grid.Row key='row2' className={classnames(gridRow2ClassNames)}>
                    {this.renderGridColumns(
                        groupFields[field.groupKey].filter(field => 2 === field.rowNumber),
                        entityData
                    )}
                </Grid.Row>
            </Grid>
        );

        return (
            <div className='formRow' key={`groupField_${iterator}`}>
                {component}
            </div>
        );
    }

    renderGridColumns = (fields, entityData) => (
        fields.map((field, i) => {
            const GroupField = field.component || Form.FormRow,
                productId = field.props.name[0],
                isProductChecked = this.props.formValues[`product_${productId}`],
                groupOptions = field.props.options ? {options: field.props.options} : {options: []},
                groupFieldSpecificProps = this.getFieldCallback(field),
                groupWizardSpecificProps = this.wizardProps(field),
                columnClassName = `column_${field.props.className} `,
                checkedClassName = (isProductChecked && !field.props.checked ? 'distributionMinHeight' : '');

            let className = '';

            if (field.props.name.endsWith('_synchronise_times')) {
                className += 'synchroniseTimes';
            }

            return (
                <Grid.Column
                    key={i}
                    width={field.groupWidth}
                    textAlign={'center'}
                    className={columnClassName + checkedClassName}
                >
                    <GroupField
                        className={className}
                        data={entityData}
                        {...field.props}
                        {...groupOptions}
                        {...groupFieldSpecificProps}
                        {...groupWizardSpecificProps}
                    />
                </Grid.Column>
            );
        })
    );

    /**
     * Check if exist any pending query in DefaultWizard defined by model
     * or received from props
     * @returns {boolean}
     */
    getIsLoading = () => {
        return (
            (undefined !== this.props.data && this.props.data.loading)
            || (undefined !== this.props.GraphQLEntityData && this.props.GraphQLEntityData.loading)
            || (undefined !== this.props.GraphQLOptionsData && this.props.GraphQLOptionsData.loading)
            || (undefined === this.state.processingDistributions || this.state.processingDistributions)
            || !this.state.queryRightScopesOfPropertyLicenceLoaded
            || this.state.queryPropertyLicenceTechSetupProductsLoading
            || this.state.queryOptionsOriginEntryPointsLoading
        );
    };

    /**
     * @ignore
     */
    render() {
        const isFormLoading = this.getIsFormLoading();

        let entityData = this.getData(),
            groupFields = [];

        return (
            <div>
                {null !== this.state.defaultForm_formTitle ? <Header>{this.state.defaultForm_formTitle}</Header> : null}
                <MessageBox name='formInnerErrorMessage' className='--formInnerError'/>

                <Form.Create
                    onSubmit={this.props.handleSubmit(this.onFormSubmit)}
                    loading={isFormLoading}
                    className='eventContent'
                >
                    <Steps
                        editMode={!_isEmpty(this.state.defaultForm_data)}
                        currentStep={this.state.currentStep}
                        steps={Object.values(this.props.Model.wizard)}
                        onClick={this.changeTabs}
                    />

                    {Object.values(this.state.defaultForm_fields).map((field, i) => {
                        const Field = field.component || Form.FormRow;

                        let value = field.defaultValue;

                        if (field.dataMapKey) {
                            value = entityData
                                ? readNested(entityData, field.dataMapKey)
                                : readNested(this.props.Model.dataMap, field.dataMapKey);
                        }

                        if (this.state.defaultForm_valueParsers[field.props.name]) {
                            value = this.state.defaultForm_valueParsers[field.props.name](value);
                        }

                        const options = field.props.options ? {options: field.props.options} : {options: []},
                            fieldSpecificProps = this.getFieldCallback(field),
                            wizardSpecificProps = this.wizardProps(field);

                        if (field.groupKey) {
                            groupFields = this.gatherGroupFields(field, groupFields);

                            return this.renderGroupFields(groupFields, field, i, entityData);
                        }

                        let hasValidDistribution = true;

                        if (field.props.component && field.props.name.match('^product_[0-9]+$')) {
                            let regex = new RegExp(`^${field.props.name}_.*_always_hidden$`);

                            hasValidDistribution = 0 < Object.entries(this.state).filter((state) => (
                                regex.exec(state[0]) && !state[1]
                            )).length;
                        }

                        let refProps = {};

                        if (field.props.withRef) {
                            refProps = {customRef: this.state.refs[field.props.name]};
                        }

                        return (
                            <Field
                                data={entityData}
                                key={i}
                                defaultValue={value}
                                {...field.props}
                                {...options}
                                {...fieldSpecificProps}
                                {...wizardSpecificProps}
                                renderDisabledHeader={!hasValidDistribution}
                                {...refProps}
                            />
                        );
                    })}
                    {(false !== this.props.Model.showChangeLog && entityData?.[this.props.Model.entityDataMapKey])
                    && <Form.FormRowChangelog
                        resources={this.props.Model.resources}
                        data={entityData[this.props.Model.entityDataMapKey]}/>}
                    <div className='formRow form__footer'>
                        <label/>
                        {this.renderSaveButton()}
                        {this.renderDeleteButton()}
                        {this.renderCancelButton()}
                    </div>
                </Form.Create>
            </div>
        );
    }
}

export default DefaultWizard;
