import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Icon, Popup} from 'semantic-ui-react';

export function CopyTextIcon(props) {
    const {
        defaultCopyText,
        disabled,
        iconTitle,
        position,
        size,
        value,
        triggerCopy,
        className,
    } = props;
    const [copyText, setCopyText] = useState(defaultCopyText);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopyText('Copied');
        });
    };

    const resetCopyText = () => (
        (copyText !== defaultCopyText)
            ? setTimeout(() => setCopyText(defaultCopyText), 100)
            : null
    );

    useEffect(() => {
        if (triggerCopy) {
            copyToClipboard(value);
        }
    }, [triggerCopy]);

    return (
        <Popup
            hoverable
            disabled={disabled}
            content={copyText}
            on={['hover']}
            position={position}
            size={size}
            trigger={
                <Icon
                    className={className}
                    name='copy outline'
                    onBlur={resetCopyText}
                    onClick={() => copyToClipboard(value)}
                    onMouseOut={resetCopyText}
                    title={iconTitle}
                />
            }
        />
    );
}

CopyTextIcon.propTypes = {
    defaultCopyText: PropTypes.string,
    disabled: PropTypes.bool,
    iconTitle: PropTypes.string,
    position: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.string.isRequired,
    triggerCopy: PropTypes.string,
    className: PropTypes.string,
};

CopyTextIcon.defaultProps = {
    defaultCopyText: 'Copy ID',
    disabled: false,
    iconTitle: null,
    position: 'bottom center',
    size: 'tiny',
    className: '--withPointer --marginLeft-0-5-em',
};
