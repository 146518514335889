import {isEmpty as _isEmpty} from 'lodash';
import React from 'react';

import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import OriginEntryPointContentsRow from '@modules/udpOriginEntryPoint/components/OriginEntryPointContentsRow';
import {IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import {authorizationObject} from '@modules/udpOriginEntryPoint/components/Table/authorizationObject';
import {Authorization} from '@appComponents/Authorization';

export const RowRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: 'udpOriginEntryPoint.edit',
                        params: {id: row.id},
                    }}
                >
                    {row.id}
                </LinkWithAuthorization>
            );
        case 'name':
            return (
                <div>
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'udpOriginEntryPoint.edit',
                            params: {id: row.id},
                        }}
                    >
                        {row.name}
                    </LinkWithAuthorization>
                    {_isEmpty(row.description) ? null : <p className='muted'>{row.description}</p>}
                </div>
            );
        case 'encoding_datacenter' :
            return (
                <div>
                    {_isEmpty(row.encoding_datacenter) ? '' : row.encoding_datacenter.name}
                </div>
            );
        case 'failover_udp_origin_entry_point' :
            return (
                <div>
                    {_isEmpty(row.failover_udp_origin_entry_point) ? '' : row.failover_udp_origin_entry_point.name}
                </div>
            );
        case 'content' :
            return OriginEntryPointContentsRow(row, 'UdpOriginEntryPoint');
        case 'actions' :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit size='large' link={
                            getLink('udpOriginEntryPoint.edit', {id: row.id})
                        } resource={RESOURCES.UDP_ORIGIN_ENTRY_POINT}/>
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};
