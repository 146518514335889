import {get as _get, groupBy as _groupBy, isEmpty as _isEmpty} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {Header, Segment} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import {gql, withApollo} from 'react-apollo';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import EventContentMultiAudioModal from '@modules/events/components/EventContentMultiAudioModal';
import {DeleteEncodingTargetMutation} from '@graphql/events/encodingTarget';
import {CircleButtonAdd} from '@appComponents/ButtonCollection';
import {getLink, Link} from '@appComponents/Link';
import {routes} from '@constants/routes';
import {createForm, renderModalError} from '@utils/forms';
import SegmentHeader from '@appComponents/SegmentHeader';
import MessageBox from '@appComponents/MessageBox';
import * as MESSAGES from '@constants/messages';
import EventContentSubtitleModal from '@modules/events/components/EventContentSubtitleModal';
import {ENCODING_TARGET} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE_READ} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';
import {showModal} from '@utils/modal';

import EventContentTargetForm from '../forms/EventContentTargetForm';
import EventContentTargetModel from '../forms/EventContentTargetModel';
import EventsConfigurationTargetsTable from '../components/EventsConfigurationTargetsTable';
import EventContentTargetEditModel from '../forms/EventContentTargetEditModel';

const authorizationEncodingTargetCreateReadObject = {
    [ENCODING_TARGET]: SECURITY_PRIVILEGES_CREATE_READ,
};

export class EventsConfigurationTargets extends React.Component {
    static propTypes = {
        content: PropTypes.object.isRequired,
        client: PropTypes.object.isRequired,
        encodingTargets: PropTypes.array,
        eventId: PropTypes.number.isRequired,
        history: PropTypes.object,
        loading: PropTypes.bool,
        match: PropTypes.object,
        MessageBox: PropTypes.object.isRequired,
        Modal: PropTypes.object,
        modal: PropTypes.shape({
            isVisible: PropTypes.bool,
        }),
        propertyLicenceId: PropTypes.number.isRequired,
    };

    componentDidMount() {
        isUrlParamValid(this.props.match.params.id)
            && isUrlParamValid(this.props.match.params.contentId)
            && this.loadModal(this.props.match.path);
    }

    constructor(props) {
        super(props);

        this.state = {
            match: this.props.match,
            modal: this.props.modal || {isVisible: false},
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const urlChanged = prevState.match.path !== nextProps.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.events.configuration.index.path,
            modalChanged = nextProps.modal?.isVisible !== prevState.modal?.isVisible,
            modalIsNotVisible = !nextProps.modal?.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible,
            shouldOpenModal = urlChanged && urlIsNotIndex;

        return {
            modal: nextProps.modal || prevState.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
            modalWasClosed: modalWasClosed,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shouldOpenModal && this.state.shouldOpenModal !== prevState.shouldOpenModal) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (this.state.modalWasClosed && this.state.modalWasClosed !== prevState.modalWasClosed) {
            this.props.history.push(
                getLink('events.configuration.index', {id: this.props.match.params.id})
            );
        }
    }

    parseEncodingTargets = (distributions, encodingTargets) => {
        if (_isEmpty(distributions) || _isEmpty(encodingTargets)) {
            return [];
        }

        encodingTargets = _groupBy(encodingTargets, (encodingTarget) => {
            return _get(encodingTarget, 'distribution.id', null);
        });

        const parsed = [];

        distributions.map((distribution) => {
            return encodingTargets[convertToInt(distribution.id)];
        }).map((targets) => {
            if (targets) {
                targets.map((item) => {
                    parsed.push({
                        audios: _get(item, 'audios', []),
                        subtitles: _get(item, 'subtitles', []),
                        cdn: _get(item, 'cdn', null),
                        akamai_cdn_ingest_method: _get(item, 'akamai_cdn_ingest_method', null),
                        ingest_method: _get(item, 'ingest_method', null),
                        delay: _get(item, 'delay', null),
                        distribution_type: item.distribution.distribution_type,
                        device_categories: item.device_categories,
                        encoder_type: item.encoder_type,
                        encoding_job_profile: _get(item, 'encoding_target_sr.encoding_job_profile', null),
                        has_multi_audio_support: _get(item, 'encoding_target_sr.encoding_job_profile.has_multi_audio_support', null),
                        has_scoreboard_overlay_support: _get(item, 'encoding_target_sr.has_scoreboard_overlay_support', null),
                        has_slate_support: _get(item, 'encoding_target_sr.has_slate_support', null),
                        has_overlay_support: _get(item, 'encoding_target_sr.has_overlay_support', null),
                        has_ad_support: _get(item, 'encoding_target_sr.has_ad_support', null),
                        has_drm_support: _get(item, 'encoding_target_sr.has_drm_support', null),
                        has_cc: _get(item, 'encoding_target_sr.has_cc', null),
                        id: item.id,
                        is_default: item.is_default,
                        is_computer_vision: item.is_computer_vision,
                        has_playback_authentication: item.has_playback_authentication,
                        token_ttl: _get(item, 'token_ttl', null),
                        product: item.distribution.product,
                        stream_delay_type: item.stream_delay_type,
                        stream_name_prefix: _get(item, 'encoding_target_sr.stream_name_prefix', null),
                        has_client_cdn_configurations: !!item.client_cdn_configurations.length,
                        has_third_party_client_vod_storages: !!item.third_party_client_vod_storages_count,
                    });
                });
            }
        });

        return parsed;
    };

    renderAddButton = () => {
        return (
            <Authorization authorization={authorizationEncodingTargetCreateReadObject}>
                <Link
                    name='events.configuration.content.target.add'
                    params={{
                        id: this.props.match.params.id,
                        contentId: convertToInt(this.props.content.id),
                    }}
                >
                    <CircleButtonAdd/>
                </Link>
            </Authorization>
        );
    };

    loadModal(path, params = {}) {
        if (!isUrlParamValid(this.props.match.params.targetId)
            || !isUrlParamValid(params.targetId)) {
            return showModal({
                isVisible: true,
                content: renderModalError('Encoding Target',
                    getLink('events.configuration.index',
                        {id: this.props.match.params.id})),
            });
        }

        switch (path) {
            case routes.events.configuration.content.target.add.path:
                return this.loadModalForm(createForm(
                    EventContentTargetModel,
                    EventContentTargetForm,
                    {
                        optionsVariables: {
                            eventId: convertToInt(this.props.match.params.id),
                            eventContent: (params.contentId)
                                ? params.contentId
                                : this.props.match.params.contentId,
                            eventContentId: (params.contentId)
                                ? convertToInt(params.contentId)
                                : convertToInt(this.props.match.params.contentId),
                            propertyLicenceId: this.props.propertyLicenceId,
                            hasDrmSupport: true,
                        },
                        isAddForm: true,
                    }
                ));

            case routes.events.configuration.content.target.edit.path:
                return this.loadModalForm(createForm(
                    EventContentTargetEditModel,
                    EventContentTargetForm,
                    {
                        optionsVariables: {
                            eventId: convertToInt(this.props.match.params.id),
                            eventContent: (params.contentId)
                                ? params.contentId
                                : this.props.match.params.contentId,
                            eventContentId: (params.contentId)
                                ? convertToInt(params.contentId)
                                : convertToInt(this.props.match.params.contentId),
                            propertyLicenceId: this.props.propertyLicenceId,
                            hasDrmSupport: true,
                        },
                        id: (params.targetId)
                            ? convertToInt(params.targetId)
                            : convertToInt(this.props.match.params.targetId),
                        isAddForm: false,
                    }
                ));

            case routes.events.configuration.content.target.audio.path:
                return this.loadAudioModal(
                    convertToInt(params.targetId || this.props.match.params.targetId)
                );

            case routes.events.configuration.content.target.subtitles.path:
                return this.loadSubtitleModal(
                    convertToInt(params.targetId || this.props.match.params.targetId)
                );
        }
    }

    loadModalForm = (Form, data) => {
        this.props.Modal.setModal({
            header: false,
            isVisible: true,
            content: <Form formData={data}/>,
            size: 'large',
        });
    };

    loadAudioModal = (data) => {
        this.props.Modal.setModal({
            header: true,
            isVisible: true,
            content: <EventContentMultiAudioModal encodingTargetId={data} />,
            size: 'small',
        });
    };

    loadSubtitleModal = (data) => {
        this.props.Modal.setModal({
            header: true,
            isVisible: true,
            content: <EventContentSubtitleModal encodingTargetId={data} />,
            size: 'tiny',
        });
    };

    handleDeleteEncodingTarget = (event) => {
        const id = event.currentTarget.dataset.id;

        this.props.Modal.setModalConfirmation({
            header: <Header icon='trash' content={MESSAGES.DELETE_HEADER('encoding target')}/>,
            text: MESSAGES.DELETE_CONFIRMATION('encoding target'),
            onYes: () => (this.deleteEncodingTarget(id)),
        });
    };

    deleteEncodingTarget = (encodingTargetId) => {
        const {
            Modal: {setModal},
            MessageBox: {addMessage},
        } = this.props;

        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETE_IN_PROGRESS('encoding target'),
        });

        this.props.client.mutate({
            mutation: gql(DeleteEncodingTargetMutation),
            variables: {
                id: convertToInt(encodingTargetId),
            },
            refetchQueries: ['GetEncodingTargetsForDistributions'],
        }).then(() => {
            setModal({isVisible: false});
            addMessage(
                'eventsConfiguration',
                MESSAGES.DELETED('encoding target'),
                null,
                'success'
            );
        }).catch((error) => {
            setModal({isVisible: false});
            addMessage(
                'eventsConfiguration',
                MESSAGES.NOT_DELETED('encoding target'),
                `${error}`,
                'error'
            );
        });
    };

    render() {
        return (
            <div>
                <SegmentHeader buttons={this.renderAddButton()}> Encoding targets </SegmentHeader>
                <MessageBox name='targets'/>
                <Segment basic className='--table'>
                    <EventsConfigurationTargetsTable
                        eventContentId={convertToInt(this.props.content.id)}
                        content={this.props.content}
                        eventId={this.props.eventId}
                        loading={this.props.loading}
                        targets={this.parseEncodingTargets(this.props.content.distributions, this.props.encodingTargets)}
                        onDelete={this.handleDeleteEncodingTarget}
                    />
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        propertyLicenceId: convertToInt(state.app.entities.event.property_licence.id),
    });
};

export default withRouter(connect(mapStateToProps)(withApollo(EventsConfigurationTargets)));
