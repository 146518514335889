import React from 'react';

import {SECURITY_PRIVILEGES_CREATE_READ} from '@constants/variables';
import {SRT_ORIGIN_ENTRY_POINT} from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';
import Link from '@appComponents/Link';
import {ButtonAdd} from '@appComponents/ButtonCollection';

const SrtOriginEntryPointsIndexButtons = () => {
    const authorizationObject = {
        [SRT_ORIGIN_ENTRY_POINT]: SECURITY_PRIVILEGES_CREATE_READ,
    };

    return (
        <div className='content__buttons'>
            <Authorization authorization={authorizationObject}>
                <Link name='srtOriginEntryPoints.add'>
                    <ButtonAdd>Add new SRT origin entry point</ButtonAdd>
                </Link>
            </Authorization>
        </div>
    );
};

export default SrtOriginEntryPointsIndexButtons;
