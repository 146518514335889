import PropTypes from 'prop-types';

import {getContent} from '@utils/export/excel';

export const exportExcel = (dataExport) => ({
    dataExport: dataExport,
    filename: 'srt_origin_entry_points',
    parseData: srtOriginEntryPoint => [
        srtOriginEntryPoint.id,
        srtOriginEntryPoint.name,
        srtOriginEntryPoint.description || '',
        srtOriginEntryPoint.url,
        getContent(srtOriginEntryPoint),
    ],
    path: 'srtOriginEntryPoints.index.path',
    titles: [
        'ID',
        'Name',
        'Description',
        'URL',
        'Content',
    ],
});

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
