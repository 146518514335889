import {columnObjectAlignCenter, columnObjectAlignLeft} from '@utils/tableHelpers';

export const Columns = {
    'id': {
        label: 'ID',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'name': {
        label: 'Name',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'url': {
        label: 'URL',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'content': {
        label: 'Content',
        ...columnObjectAlignLeft,
    },
    'actions': {
        label: 'Actions',
        ...columnObjectAlignCenter,
    },
};
