import {uniqBy as _uniqBy} from 'lodash';

export const adjustHeight = (cellRefs) => {
    let multiRows = cellRefs?.filter(el => el.className.includes('--multiRow')) || [];

    if (0 !== multiRows.length) {
        multiRows.forEach((multiRow) => {
            let dataId = multiRow.dataset.id.split('_');

            let rows = multiRows.filter((filterRow) => {
                let filterRowDataId = filterRow.dataset.id.split('_');

                return filterRowDataId[0] === dataId[0] && filterRowDataId[1] === dataId[1];
            });

            if (0 < rows.length && 1 < _uniqBy(rows, 'clientHeight').length) {
                let maxHeight = 0;

                rows.forEach((row) => {
                    if (row.clientHeight >= maxHeight) {
                        maxHeight = row.clientHeight;
                    }
                });

                rows.forEach((row) => {
                    if (row.clientHeight !== maxHeight) {
                        row.style.lineHeight = `${maxHeight}px`;
                    }

                    row.style.height = `${maxHeight}px`;
                });
            }
        });

        return true;
    }

    return false;
};
