import { isEmpty as _isEmpty } from 'lodash';
import React from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Form from '@appComponents/ReduxFormControls';
import {EVENT as RESOURCE_EVENT} from '@constants/resources';
import {convertToInt} from '@utils/helpers';
import {srEventUpdateMutation} from '@graphql/events/events';
import * as MESSAGES from '@constants/messages';
import {SPORTS_COURT_LIST} from '@constants/variables';

import validate from './validatorLinkEventToMatchID';

export default function LinkEventToMatchIDModel() {
    const renderRow = (params) => {
        if (!params.values.isMatchFound) {
            return null;
        }

        return (
            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label>{params.label}</label>
                {params.values.description}
            </div>
        );
    };

    /**
     * Render start time + difference between event time and match time as text
     */
    const renderStartTimeRow = (params) => {
        if (!params.values.isMatchFound) {
            return null;
        }

        let momentStartMatchTime = moment(params.values.startMatchTime, 'YYYY-MM-DD HH:mm'),
            momentStartEventTime = moment(params.values.startEventTime, 'YYYY-MM-DD HH:mm:ss'),
            momentDiff = moment.duration(momentStartMatchTime.diff(momentStartEventTime)),
            asMinutes = momentDiff.asMinutes(),
            timeMessage;

        if (0 === asMinutes) {
            timeMessage = '(same time as the event start time)';
        } else {
            let asHours = momentDiff.asHours(),
                asHoursMessage = (Math.trunc(asHours)) ? `${Math.trunc(Math.abs(asHours))} hours ` : '',
                asMinutesMessage = (Math.abs(asMinutes%60)) ? `${(Math.abs(asMinutes)%60)} minutes ` : '',
                beforeAfter = (0 < asMinutes) ? 'after' : 'before';

            timeMessage = `(${asHoursMessage}${asMinutesMessage}${beforeAfter} the event start time)`;
        }

        return (
            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label>{params.label}</label>
                {params.values.startMatchTime} {timeMessage}
            </div>
        );
    };

    const renderCourtVenue = (params) => {
        if (!params.values.isMatchFound) {
            return null;
        }

        let label = 'Venue';

        /**
         * Only for tennis and badminton we need to display "Court"
         */
        if (SPORTS_COURT_LIST.includes(convertToInt(params.values.sportId))) {
            label = 'Court';
        }

        return (
            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label>{label}</label>
                {params.values.text}
            </div>
        );
    };

    const renderLinkedEventTvChannels = (params) => {
        if (!params.values.isMatchFound || _isEmpty(params.values.text)) {
            return null;
        }

        return (
            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label>{params.label}</label>
                {params.values.text}
            </div>
        );
    };

    const renderMessage = (params) => {
        if (params.values?.loading) {
            return (
                <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                    <label/>
                    <div className={'--linkEventToMatchFormRow'}>
                        <Dimmer inverted active={true} className='--relative --noPadding'>
                            <Loader size='tiny' active inline/>
                            <span className='--darkText --marginLeft-0-5-em'>{params.values.message}</span>
                        </Dimmer>
                    </div>
                </div>
            );
        }

        if (params.values?.message) {
            return (
                <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                    <label/>
                    <div key='warning' className='error'>{params.values.message}</div>
                </div>
            );
        }

        return null;
    };

    return {
        entityDataMapKey: 'event',
        entityLabel: 'link',
        formName: 'LinkEventToMatchID',
        resources: RESOURCE_EVENT,
        showChangeLog: false,
        title: 'Link event to a match ID',
        validate: validate,
        dataMap: {
            event: {
                sr_event: {
                    id: '',
                    match: {
                        id: '',
                    },
                    synchronise_times: '',
                },
                match_description: '',
            },
        },
        messages: {
            boxName: {
                success: 'event',
                error: 'event',
            },
            text: {...MESSAGES, SAVED: () => 'The event has been linked to a Sportradar match successfully.'},
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'event.sr_event.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            match_id: {
                props: {
                    component: Form.SemanticInput,
                    name: 'match_id',
                    label: 'Match ID',
                    type: 'input',
                    required: true,
                    isValidateLive: true,
                    props: {
                        type: 'number',
                        placeholderText: 'Type at least 7 digits',
                        min: 0,
                        onlyDigitsAllowed: true,
                        autoFocus: true,
                    },
                },
            },
            startTime: {
                dataMapKey: 'start_time',
                props: {
                    component: renderStartTimeRow,
                    name: 'start_time',
                    label: 'Start time',
                    values: {
                        startMatchTime: null,
                    },
                },
            },
            competitor: {
                dataMapKey: 'competitor',
                props: {
                    component: renderRow,
                    name: 'competitor',
                    label: 'Competitors',
                    values: {
                        text: null,
                    },
                },
            },
            tournament: {
                dataMapKey: 'tournament',
                props: {
                    component: renderRow,
                    name: 'tournament',
                    label: 'Tournament',
                    values: {
                        text: null,
                    },
                },
            },
            court_venue: {
                dataMapKey: 'court_venue',
                props: {
                    component: renderCourtVenue,
                    name: 'court_venue',
                    label: null,
                    values: {
                        text: null,
                    },
                },
            },
            tv_channels: {
                dataMapKey: null,
                props: {
                    component: renderLinkedEventTvChannels,
                    name: 'tv_channels',
                    label: 'TV Channel',
                    values: {
                        text: null,
                    },
                },
            },
            message: {
                dataMapKey: 'message',
                props: {
                    component: renderMessage,
                    name: 'message',
                    label: null,
                    value: {
                        isMatchFound: false,
                    },
                },
            },
            synchronise_times: {
                dataMapKey: 'event.sr_event.synchronise_times',
                props: {
                    component: Form.SemanticInput,
                    description: null,
                    defaultValue: true,
                    label: 'Synchronise the event start time with the match start time?',
                    name: 'synchronise_times',
                    type: 'checkbox',
                    hidden: true,
                },
            },
        },
        mutation: {
            updateEntity: srEventUpdateMutation,
        },
        query: {
        },
    };
}

LinkEventToMatchIDModel.propTypes = {
    eventId: PropTypes.number.isRequired,
};
