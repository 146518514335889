import React from 'react';

import {IconDelete, IconEdit} from '@appComponents/IconCollection';
import Authorization from '@appComponents/Authorization';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';

const readAuthorizationObject = {
    [RESOURCES.CONTENT_CATEGORY]: CONST.SECURITY_PRIVILEGES_READ,
};

const readDeleteAuthorizationObject = {
    [RESOURCES.CONTENT_CATEGORY]: CONST.SECURITY_PRIVILEGES_READ_DELETE,
};

export const rowRenderer = (column, row, props) => {
    const {editCallback, deleteCallback} = props;

    const actions = [
        <Authorization key={`edit-${row.id}`} authorization={readAuthorizationObject}>
            <IconEdit
                key={`edit-${row.id}`}
                data-id={row.id}
                onClick={editCallback}
                onClickElement={row}
                resource={RESOURCES.CONTENT_CATEGORY}
                size='large'
            />
        </Authorization>,
    ];

    if (0 === row.children_count) {
        actions.push(
            <Authorization key={`delete-${row.id}`} authorization={readDeleteAuthorizationObject}>
                <IconDelete
                    key={`delete-${row.id}`}
                    data-id={row.id}
                    data-name={row.name}
                    data-level={row.level}
                    onClick={deleteCallback}
                    onClickElement={row}
                    className='trash large'
                />
            </Authorization>
        );
    }

    if ('actions' === column) {
        return (
            <div className='actions-list-icon'>
                {actions}
            </div>
        );
    }

    return null;
};
