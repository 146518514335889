import React from 'react';
import PropTypes from 'prop-types';
import {
    get as _get,
    isEmpty as _isEmpty,
    isEqual as _isEqual,
    join as _join,
} from 'lodash';
import {Header, Popup, TableCell} from 'semantic-ui-react';

import {
    DEVICE_CATEGORY_DESKTOP,
    DEVICE_CATEGORY_MOBILE,
    DEVICE_CATEGORY_SMART_TV,
    TERRITORY_SELECTION_TYPE_BLACKLIST,
    TERRITORY_SELECTION_TYPE_WHITELIST,
    TERRITORY_SELECTION_TYPE_WORLDWIDE,
} from '@constants/variables';

import {convertToInt} from './helpers';

/**
 * Parse countries from GraphQL query "countries"
 * to  dropdown/select list format
 * @param countriesList
 * @returns {*}
 */
export const getCountriesForDropdown = (countriesList) => (
    countriesList.map((country) => ({
        key: country.id,
        value: country.id,
        text: country.name.capitalizeFirstLetter(),
    }))
);

getCountriesForDropdown.propTypes = {
    countriesList: PropTypes.array.isRequired,
};

export const renderCountriesTableCell = (id, content) => (
    <TableCell
        as='div'
        className='cell'
        key={`type_countries_${id}`}
        content={content}
    />
);

export const renderDivWrappedCountriesTableCell = (id, content) => {
    return renderCountriesTableCell(id, (
        <div className={'countriesContent'}>
            <div>{content}</div>
        </div>
    ));
};

export const renderNumberOfCountries = (deviceCategoriesCountries) => {
    const numberOfCountriesCellContent = [];
    let numberOfCountriesContentToRender = null;

    deviceCategoriesCountries.forEach((deviceCategory) => {
        const numberOfCountries = deviceCategory.countryNames.length;

        numberOfCountriesContentToRender = (
            <TableCell
                as='div'
                className='cell'
                key={`number_of_countries_${deviceCategory.id}`}
                content={numberOfCountries}
            />
        );

        numberOfCountriesCellContent.push(numberOfCountriesContentToRender);
    });

    return numberOfCountriesCellContent;
};

export const renderDeviceCategories = (deviceCategoriesCountries) => {
    let deviceCategoriesCellContent = [];

    deviceCategoriesCountries.forEach((deviceCategory) => {
        deviceCategoriesCellContent.push(
            <TableCell
                as='div'
                className='cell'
                key={`type_${deviceCategory.id}`}
                content={deviceCategory.deviceCategoryName}
            />
        );
    });

    return deviceCategoriesCellContent;
};

export const prepareCountryNames = (countryCodes, mappedIsoCodes) => {
    let countryNames = [];

    countryCodes.forEach((countryCode) => {
        countryNames.push(mappedIsoCodes[countryCode.trim()] || '-');
    });

    return countryNames.sort();
};

export const prepareSubdivisionNames = (subdivisionCodes, mappedIsoCodes, checkIsoCode = false) => {
    const countriesCodes = [],
        sortedSubdivisions = [];

    subdivisionCodes.forEach((subdivision) => {
        const checkField = checkIsoCode ? subdivision.iso_code : subdivision,
            subdivisionCountryCode = checkField.split('-')[0],
            subdivisionName = mappedIsoCodes.subdivisions[checkField] || '-';

        if (undefined !== countriesCodes[subdivisionCountryCode]) {
            countriesCodes[subdivisionCountryCode].push(subdivisionName);
        } else {
            countriesCodes[subdivisionCountryCode] = [subdivisionName];
        }
    });

    Object.keys(countriesCodes).forEach((countryCode) => {
        countriesCodes[countryCode] = countriesCodes[countryCode].sort();
    });

    const countryNames = Object.keys(countriesCodes).map((code) => (mappedIsoCodes.countries[code])).sort();

    countryNames.forEach((countryName) => {
        const currentKey = Object.keys(mappedIsoCodes.countries).find((key) => (mappedIsoCodes.countries[key] === countryName));

        sortedSubdivisions[currentKey] = countriesCodes[currentKey];
    });

    return sortedSubdivisions;
};

export const parseDeviceCategories = (mappedIsoCodes, geoRestrictions) => {
    let deviceCategoriesCountries = [];

    if (!_isEmpty(mappedIsoCodes) && !_isEmpty(geoRestrictions)) {
        geoRestrictions.forEach((geoRestriction) => {
            deviceCategoriesCountries = mergeDeviceCategories(
                geoRestriction,
                mappedIsoCodes,
                deviceCategoriesCountries
            );
        });
    }

    return deviceCategoriesCountries;
};

export const mergeDeviceCategories = (geoRestriction, mappedIsoCodes, deviceCategories = []) => {
    const countryCodes = _get(geoRestriction, 'country_iso_alpha2_codes', []) || [],
        subdivisionCodes = _get(geoRestriction, 'country_subdivision_iso_codes', []) || [],
        countryNames = prepareCountryNames(countryCodes, mappedIsoCodes.countries),
        subdivisionNames = prepareSubdivisionNames(subdivisionCodes, mappedIsoCodes),
        deviceCategoryName = _get(geoRestriction, 'device_category.name', null),
        deviceCategoryId = _get(geoRestriction, 'device_category.id', null),
        blackoutZones = _get(geoRestriction, 'blackout_zones', []);

    for (let i = 0; i < deviceCategories.length; i++) {
        if (_isEqual(_get(deviceCategories[i], 'countryNames', []), countryNames)
            && _isEqual(_get(deviceCategories[i], 'subdivisionNames', []), subdivisionNames)
            && _isEqual(_get(deviceCategories[i], 'blackoutZones', []), blackoutZones)
        ) {
            deviceCategories[i]['deviceCategoryName'] += `, ${deviceCategoryName}`;

            return deviceCategories;
        }
    }

    deviceCategories.push({
        id: geoRestriction.id ? geoRestriction.id : deviceCategoryId,
        deviceCategoryName: deviceCategoryName,
        countryNames: countryNames,
        subdivisionNames: subdivisionNames,
        blackoutZones: blackoutZones,
    });

    return deviceCategories;
};

export const getCountriesWithSubdivisionsForTooltip = (countries) => {
    const countriesToRender = countries.map(function (country, index) {
        const countryPosition = country.split(':');

        return (
            <div key={`country_subdivisions_${index}`} className={`countryRow`}>
                <b>{countryPosition[0]}</b>: {countryPosition[1]}
            </div>
        );
    });

    return (
        <div className={`subdivisionsContent`}>
            <Header as='h5' dividing>Subdivisions</Header>
            {countriesToRender}
        </div>
    );
};

export const getListOfDeviceCategoriesForGeoRestrictions = (geoRestrictions = []) => {
    const categories = [];

    geoRestrictions.forEach((geoRestriction) => {
        if (-1 === categories.indexOf(geoRestriction.device_category.name)) {
            categories.push(geoRestriction.device_category.name);
        }
    });

    return categories;
};

export const getListOfCountryCodesForDeviceCategories = (deviceCategories = []) => {
    const countriesList = [];

    deviceCategories.forEach((deviceCategory) => {
        deviceCategory.country_iso_alpha2_codes.forEach((country) => {
            if (-1 === countriesList.indexOf(country)) {
                countriesList.push(country);
            }
        });
    });

    return countriesList;
};

export const getListOfCodesForDeviceCategories = (deviceCategories = []) => {
    const countriesList = [];

    deviceCategories.forEach((deviceCategory) => {
        const subdivisions = deviceCategory.country_subdivision_iso_codes;

        deviceCategory.country_iso_alpha2_codes.forEach((country) => {
            if (-1 === countriesList.indexOf(country)) {
                countriesList.push(country);
            }
        });

        if (!_isEmpty(subdivisions)) {
            subdivisions.forEach((subdivision) => {
                if (-1 === countriesList.indexOf(subdivision)) {
                    countriesList.push(subdivision);
                }
            });
        }
    });

    return countriesList.sort().join(', ').replace(
        /, [A-Z]{2}-[A-Z]{2,3}(, [A-Z]{2}-[A-Z]{2,3})*/g,
        function (subdivision) {
            return ` (${subdivision.substring(2)})`;
        });
};

export const getCountriesIdsFromContinent = (data) => {
    let response = [];

    if (_isEmpty(data)) {
        return response;
    }

    data.forEach((firstLevel) => (
        firstLevel.children.forEach((secondLevel) => {
            response.push(secondLevel.value);
        })
    ));

    return response;
};

export const getSubdivisionsIdsFromCountry = (data) => {
    let response = [];

    if (_isEmpty(data)) {
        return response;
    }

    data.forEach((firstLevel) => (
        firstLevel.children.forEach((secondLevel) => {
            response.push(secondLevel.value.replace(/^(.{2})/, '$1-'));
        })
    ));

    return response;
};

export const preparePopupWithCountriesAndSubdivisions = (
    mappedIsoCodes,
    subdivisions = [],
    countriesToRender = null
) => {
    const preparedSubdivisions = [];

    for (const key in subdivisions) {
        const countryName = mappedIsoCodes[key];

        if (undefined !== countryName) {
            preparedSubdivisions.push(`${countryName}: ${subdivisions[key].sort().join(', ')}`);
        }
    }

    return (
        <div className={'countrySubdivisionsTooltip'}>
            {countriesToRender}
            {getCountriesWithSubdivisionsForTooltip(preparedSubdivisions)}
        </div>
    );
};

export const renderCountriesContent = (
    data,
    subdivisionsExistence,
    mappedIsoCodes,
    maxCountries,
    blacklistPrefix = ''
) => {
    const sortedCountries = data.countries.map(country => country.name).sort();
    let countriesToRender = (
        <div className={'countriesContent'}>
            <Header as='h5' dividing>Countries</Header>
            <div>{sortedCountries.join(', ')}</div>
        </div>
    );

    if (subdivisionsExistence && !_isEmpty(mappedIsoCodes)) {
        const parsedSubdivisions = prepareSubdivisionNames(data.country_subdivisions, mappedIsoCodes, true);
        countriesToRender = preparePopupWithCountriesAndSubdivisions(
            mappedIsoCodes.countries,
            parsedSubdivisions,
            countriesToRender
        );
    }

    return <Popup
        key={`popup_type_countries_${data.id}`}
        trigger={renderCountriesTableCell(
            data.id,
            maxCountries < data.countries.length
                ? blacklistPrefix + sortedCountries.slice(0, maxCountries).join(', ').concat('...')
                : blacklistPrefix + sortedCountries.join(', ')
        )}
        position={'top center'}
        className='--veryWidePopup'
        hoverable
        wide='very'
        content={countriesToRender}
    />;
};

export const getTerritoriesWithTooltip = (data, mappedIsoCodes, maxCountries = 10) => {
    const territorySelectionType = data.territory_selection_type;

    if (null === territorySelectionType) {
        return renderDivWrappedCountriesTableCell(data.id, '-');
    }

    const subdivisionsExistence = 0 < data.country_subdivisions?.length;
    let territorySelectionTypeId = convertToInt(territorySelectionType.id);
    let blacklistPrefix = '',
        contentToRender = null;

    if (TERRITORY_SELECTION_TYPE_WORLDWIDE === territorySelectionTypeId) {
        return renderDivWrappedCountriesTableCell(data.id, 'Worldwide');
    } else if (TERRITORY_SELECTION_TYPE_BLACKLIST === territorySelectionTypeId) {
        blacklistPrefix = 'Worldwide except ';
    }

    if (maxCountries < data.countries?.length || subdivisionsExistence) {
        contentToRender = renderCountriesContent(
            data,
            subdivisionsExistence,
            mappedIsoCodes,
            maxCountries,
            blacklistPrefix
        );
    } else {
        data.countries && (contentToRender = renderDivWrappedCountriesTableCell(
            data.id,
            blacklistPrefix + data.countries.map(country => country.name).sort().join(', ')
        ));
    }

    return contentToRender;
};

export const getTerritoriesWithTooltipForDeviceCategories = (deviceCategories, mappedIsoCodes, maxCountries = 10) => {
    let countriesCellContent = [],
        contentToRender = null;

    deviceCategories.forEach((deviceCategory) => {
        const subdivisionsExistence = 0 < Object.keys(deviceCategory.subdivisionNames).length;

        if (maxCountries < deviceCategory.countryNames.length || subdivisionsExistence) {
            let countriesToRender = (
                <div className={`countriesContent`}>
                    <Header as='h5' dividing>Countries</Header>
                    <div>{deviceCategory.countryNames.sort().join(', ')}</div>
                </div>
            );

            if (subdivisionsExistence && !_isEmpty(mappedIsoCodes)) {
                countriesToRender = preparePopupWithCountriesAndSubdivisions(
                    mappedIsoCodes.countries,
                    deviceCategory.subdivisionNames,
                    countriesToRender
                );
            }

            contentToRender = <Popup
                key={`popup_type_countries_${deviceCategory.id}`}
                trigger={renderCountriesTableCell(
                    deviceCategory.id,
                    maxCountries < deviceCategory.countryNames.length
                        ? deviceCategory.countryNames.sort().slice(0, maxCountries).join(', ') + '...'
                        : deviceCategory.countryNames.sort().join(', ')
                )}
                position={'top center'}
                className='--veryWidePopup'
                hoverable
                wide='very'
                content={countriesToRender}
            />;
        } else {
            contentToRender = renderCountriesTableCell(deviceCategory.id, (
                <div className={`countriesContent`}>
                    <div>{deviceCategory.countryNames.sort().join(', ')}</div>
                </div>
            ));
        }

        countriesCellContent.push(contentToRender);
    });

    return countriesCellContent;
};

export const getCountriesAndSubdivisionsWithTooltip = (data, mappedIsoCodes, maxCountries = 10) => {
    const subdivisionsExistence = 0 < data.country_subdivisions.length;
    let contentToRender;

    if (maxCountries < data.countries.length || subdivisionsExistence) {
        contentToRender = renderCountriesContent(data, subdivisionsExistence, mappedIsoCodes, maxCountries);
    } else {
        contentToRender = renderDivWrappedCountriesTableCell(
            data.id,
            0 < data.countries.length ? data.countries.map(country => country.name).sort().join(', ') : '-'
        );
    }

    return contentToRender;
};

export const prepareGeoRestrictionsObjectForDeviceCategory = (
    deviceCategoryId,
    countries,
    subdivisions,
    blackoutZones = []
) => {
    if (!deviceCategoryId) {
        return {};
    }

    return {
        device_category: deviceCategoryId,
        country_iso_alpha2_codes: getCountriesIdsFromContinent(countries) || null,
        country_subdivision_iso_codes: getSubdivisionsIdsFromCountry(subdivisions) || null,
        blackout_zones: blackoutZones.map(Number),
    };
};

export const getGeoRestrictionsForDeviceCategories = (data) => {
    const geoRestrictions = [];

    if (_isEmpty(data)) {
        return geoRestrictions;
    }

    if (true === data.desktop_switch) {
        geoRestrictions.push(prepareGeoRestrictionsObjectForDeviceCategory(
            DEVICE_CATEGORY_DESKTOP,
            data.desktop_countries,
            data.desktop_subdivisions,
            data.desktop_blackoutZones
        ));
    }

    if (true === data.mobile_switch) {
        geoRestrictions.push(prepareGeoRestrictionsObjectForDeviceCategory(
            DEVICE_CATEGORY_MOBILE,
            data.mobile_countries,
            data.mobile_subdivisions,
            data.mobile_blackoutZones
        ));
    }

    if (true === data.smart_tv_switch) {
        geoRestrictions.push(prepareGeoRestrictionsObjectForDeviceCategory(
            DEVICE_CATEGORY_SMART_TV,
            data.smart_tv_countries,
            data.smart_tv_subdivisions,
            data.smart_tv_blackoutZones
        ));
    }

    return geoRestrictions;
};

export const getSortingTerritories = (data) => {
    if (!data || !_get(data, 'territory_selection_type.id', null)) {
        return '';
    }

    const territories = [];

    if (!_isEmpty(data.countries)) {
        data.countries.forEach((country) => {
            territories.push(country.name);
        });
    }

    switch (convertToInt(data.territory_selection_type.id)) {
        case TERRITORY_SELECTION_TYPE_WORLDWIDE:
            return 'Worldwide';
        case TERRITORY_SELECTION_TYPE_BLACKLIST:
            return 'Worldwide except ' + _join(territories.sort(), ', ');
        case TERRITORY_SELECTION_TYPE_WHITELIST:
            return _join(territories.sort(), ', ');
        default:
            return '';
    }
};
